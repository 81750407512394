// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WalkInSheetWarning__upcomingReservationBanner___S0F8W{border-radius:.5rem;background:var(--lightGrey200);color:var(--darkBlue);padding:.5rem;display:flex;flex-direction:column;row-gap:.25rem}.WalkInSheetWarning__upcomingReservationBanner___S0F8W p{padding:0;margin:0;display:flex;align-items:center}.WalkInSheetWarning__upcomingReservationBanner___S0F8W p svg{width:16px;stroke:var(--primary300);margin-right:4px}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/walkIns/WalkInSheetWarning.scss"],"names":[],"mappings":"AAAA,uDACE,mBAAA,CACA,8BAAA,CACA,qBAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA,CACA,cAAA,CAEA,yDACE,SAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CAEA,6DACE,UAAA,CACA,wBAAA,CACA,gBAAA","sourcesContent":[".upcomingReservationBanner {\n  border-radius: 0.5rem;\n  background: var(--lightGrey200);\n  color: var(--darkBlue);\n  padding: 0.5rem;\n  display: flex;\n  flex-direction: column;\n  row-gap: 0.25rem;\n\n  p {\n    padding: 0;\n    margin: 0;\n    display: flex;\n    align-items: center;\n\n    svg {\n      width: 16px;\n      stroke: var(--primary300);\n      margin-right: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upcomingReservationBanner": "WalkInSheetWarning__upcomingReservationBanner___S0F8W"
};
export default ___CSS_LOADER_EXPORT___;
