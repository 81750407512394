// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateListingModals__modalContent___jmiVJ{text-align:center}.CreateListingModals__modalContent___jmiVJ>p{color:var(--white70);max-width:30em}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/create/CreateListingModals.scss"],"names":[],"mappings":"AAAA,2CACE,iBAAA,CAEA,6CACE,oBAAA,CACA,cAAA","sourcesContent":[".modalContent {\n  text-align: center;\n\n  > p {\n    color: var(--white70);\n    max-width: 30em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": "CreateListingModals__modalContent___jmiVJ"
};
export default ___CSS_LOADER_EXPORT___;
