import { differenceBy } from 'lodash-es';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormDateRange } from '@components/formInputs/ControlledFormDateRange';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormTimeRange } from '@components/formInputs/ControlledFormTimeRange';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { reportAppError } from '@shared/reportAppError';
import { DESKTOP1280 } from '@shared/styles/breakpoints';
import { type UpdatedShift } from 'restaurantAdmin/operations/shifts/apiHelpers';
import { updateShifts } from 'restaurantAdmin/operations/shifts/apiHelpers';
import { ConfirmRemoveModal } from './ConfirmRemoveModal';
import styles from './ShiftsForm.scss';
import { UpdateShiftsWarningsModal } from './UpdateShiftsWarningsModal';

interface ShiftsFormData {
  shifts: {
    id?: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
}

export interface ShiftsFormProps {
  shifts: {
    id: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
  exitEditMode: () => void;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

const DEFAULT_SHIFT = {
  startDate: '',
  endDate: '',
  repeat: [],
  name: '',
  startTime: '',
  endTime: '',
};

export const ShiftsForm = ({
  shifts,
  exitEditMode,
  refreshRestaurant,
  restaurantId,
}: ShiftsFormProps) => {
  const { control, handleSubmit, watch } = useForm<ShiftsFormData>({
    defaultValues: {
      shifts,
    },
  });
  const {
    fields,
    append: addShift,
    remove: removeShift,
  } = useFieldArray({
    control,
    name: 'shifts',
    // this is needed bc the library maintainer decided it was a good idea to use the key name 'id' for this as a default
    keyName: 'key',
  });
  const {
    open: openConfirmRemoveModal,
    close: closeConfirmRemoveModal,
    isOpen: isConfirmRemoveModalOpen,
    data: confirmRemovalModalData,
  } = useIsOpenWithData<{ index: number }>();

  const {
    open: openUpdateWarningsModal,
    close: closeUpdateWarningsModal,
    isOpen: isUpdateWarningsModalOpen,
    data: warningModalData,
  } = useIsOpenWithData<{ warnings: string[] }>();

  const handleOnSubmit = ({ ignoreWarnings }: { ignoreWarnings: boolean }) =>
    handleSubmit(async (values) => {
      const { createdShifts, updatedShifts, deletedShiftIds } =
        buildShiftsPayload(shifts, values);

      try {
        const { warnings } = await updateShifts(restaurantId, {
          ignoreWarnings,
          createdShifts,
          updatedShifts,
          deletedShiftIds,
        });
        if (!ignoreWarnings && warnings.length) {
          openUpdateWarningsModal({ warnings });
          return;
        }
        await refreshRestaurant();
        successToast({ message: 'Shifts successfully updated' });
        exitEditMode();
      } catch (error) {
        errorToast({ message: 'Failed to update Shifts' });
        reportAppError(error);
      }
    });

  const handleOnConfirmRemove = () => {
    if (!confirmRemovalModalData) return;

    removeShift(confirmRemovalModalData.index);
    closeConfirmRemoveModal();
  };
  const handleClickOnRemove = (index: number) => (): void => {
    openConfirmRemoveModal({ index });
  };

  const layoutVariant = useMediaQuery({ minWidth: DESKTOP1280 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  return (
    <form
      onSubmit={handleOnSubmit({ ignoreWarnings: false })}
      className={styles.form}
    >
      {fields.map(({ key }, index) => (
        <div key={key} className={styles.panel}>
          <ControlledFormInput
            control={control}
            label="Name"
            name={`shifts.${index}.name`}
            rules={{
              required: true,
            }}
            type="text"
            variant={layoutVariant}
          />
          <ControlledFormDateRange
            control={control}
            fromProps={{
              label: 'Start Date',
              name: `shifts.${index}.startDate`,
              rules: { required: true },
            }}
            toProps={{
              label: 'End Date',
              name: `shifts.${index}.endDate`,
              rules: {
                min: {
                  value: watch(`shifts.${index}.startDate`),
                  message: 'End Date must be on or after the Start Date.',
                },
              },
            }}
            groupLabel="Start and End"
            variant={layoutVariant}
          />
          <ControlledFormTimeRange
            control={control}
            groupLabel="Time Frame"
            fromProps={{
              label: 'Start Time',
              name: `shifts.${index}.startTime`,
              rules: { required: true },
            }}
            toProps={{
              label: 'End Time',
              name: `shifts.${index}.endTime`,
              rules: {
                required: true,
                validate: {
                  isGreaterThanOrEqualToStartTime: (
                    endTime: string,
                  ): string | boolean =>
                    watch(`shifts.${index}.startTime`) &&
                    endTime >= watch(`shifts.${index}.startTime`)
                      ? true
                      : 'End Time must be on or after the Start Time.',
                },
              },
            }}
            variant={layoutVariant}
          />
          <ControlledFormDayRadio
            control={control}
            label="Repeats On"
            name={`shifts.${index}.repeat`}
            rules={{
              required: true,
            }}
          />
          <IconButton
            className={styles.removeIconButton}
            ariaLabel="Delete Shift"
            showLabel
            iconName="trash"
            onClick={handleClickOnRemove(index)}
          />
        </div>
      ))}
      <button
        className={styles.addButton}
        type="button"
        onClick={() => addShift(DEFAULT_SHIFT)}
      >
        Add Shift
        <Icon name="plus" />
      </button>
      <Button
        label="Save Changes"
        variant={ButtonVariants.Primary}
        type="submit"
      />
      <Button
        label="Discard Edits"
        variant={ButtonVariants.Tertiary}
        onClick={exitEditMode}
      />
      <UpdateShiftsWarningsModal
        closeModal={closeUpdateWarningsModal}
        handleOnConfirm={handleOnSubmit({ ignoreWarnings: true })}
        isOpen={isUpdateWarningsModalOpen}
        warnings={warningModalData?.warnings || []}
      />
      <ConfirmRemoveModal
        closeModal={closeConfirmRemoveModal}
        handleOnConfirmRemove={handleOnConfirmRemove}
        isOpen={isConfirmRemoveModalOpen}
      />
    </form>
  );
};

const buildShiftsPayload = (
  shifts: ShiftsFormProps['shifts'],
  formData: ShiftsFormData,
) => {
  const parsedShifts = formData.shifts.map((shift) => ({
    ...shift,
    endDate: shift.endDate || null,
  }));
  const createdShifts = parsedShifts.filter((shift) => !shift.id);
  const updatedShifts = parsedShifts.filter(
    (shift) => !!shift.id,
  ) as UpdatedShift[];
  const deletedShiftIds = differenceBy(shifts, updatedShifts, 'id').map(
    ({ id }) => id,
  );

  return {
    createdShifts,
    updatedShifts,
    deletedShiftIds,
  };
};
