// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UpdateGuestCountModal__formInput___NZ9gU{padding:3rem;border:1px solid var(--hairline)}.UpdateGuestCountModal__guestCount___tdPr7 h2{font-size:1rem;font-weight:400;line-height:1.25rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/reservationOptions/UpdateGuestCountModal.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,gCAAA,CAIA,8CACE,cAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":[".formInput {\n  padding: 3rem;\n  border: 1px solid var(--hairline);\n}\n\n.guestCount {\n  h2 {\n    font-size: 1rem;\n    font-weight: 400;\n    line-height: 1.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formInput": "UpdateGuestCountModal__formInput___NZ9gU",
	"guestCount": "UpdateGuestCountModal__guestCount___tdPr7"
};
export default ___CSS_LOADER_EXPORT___;
