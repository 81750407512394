// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MenuListbox__menuListbox___BkcBf{background-color:var(--darkGrey300);border-radius:.25rem;box-shadow:0 .25rem .5rem #000;padding:.5rem 0}", "",{"version":3,"sources":["webpack://./src/shared/components/menu/MenuListbox.scss"],"names":[],"mappings":"AAAA,kCACE,mCAAA,CACA,oBAAA,CACA,8BAAA,CACA,eAAA","sourcesContent":[".menuListbox {\n  background-color: var(--darkGrey300);\n  border-radius: 0.25rem;\n  box-shadow: 0 0.25rem 0.5rem black;\n  padding: 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuListbox": "MenuListbox__menuListbox___BkcBf"
};
export default ___CSS_LOADER_EXPORT___;
