import cx from 'classnames';
import { forwardRef } from 'react';
import FloorPlanTable from '@components/floorPlan/FloorPlanTable';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import { getAnonymousName } from '@shared/utils/formatName';
import type {
  AssignedServer,
  SeatedOccupant,
} from '../../../floorPlans/apiHelpers';
import { ServiceStatusIcon } from '../serviceStatus/ServiceStatusIcon';
import { ServerBadge } from '../sidePanel/servers/ServerBadge';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import styles from './SeatedFloorPlanTable.scss';

export interface SeatedFloorPlanTableProps extends TableIconCommonProps {
  assignedServer?: AssignedServer | null;
  seatedOccupant: SeatedOccupant;
  tableName: string;
  isDisabled: boolean;
  isHighlighted: boolean;
  isAnyTableHighlighted: boolean;
  handleTableOnClick?: () => void;
}

export const SeatedFloorPlanTable = forwardRef<
  HTMLDivElement,
  SeatedFloorPlanTableProps
>(
  (
    {
      assignedServer,
      handleTableOnClick,
      iconName,
      iconWidthScale,
      isAnyTableHighlighted,
      isDisabled,
      isHighlighted,
      left,
      orientation,
      seatedOccupant,
      tableIconScale,
      tableName,
      testId,
      top,
    },
    ref,
  ) => {
    const fullName = getAnonymousName(
      seatedOccupant.firstName,
      seatedOccupant.lastName,
    );
    const tooltip = `${fullName} seated at table ${tableName}, party of ${seatedOccupant.guestCount}`;

    const { shouldShowServerBadges, shouldShowServiceStatuses } =
      useReservationServiceContext();

    const iconClassNames = cx({
      [styles.serviceStatusIcon]: true,
      [styles.isHighlighted]: isHighlighted && isAnyTableHighlighted,
      [styles.isDimmed]: !isHighlighted && isAnyTableHighlighted,
    });

    return (
      <FloorPlanTable
        left={left}
        top={top}
        tableIconScale={tableIconScale}
        data-testid={testId}
        ref={ref}
        title={tooltip}
      >
        <button
          aria-label={`View details for table ${tableName}`}
          className={styles.button}
          disabled={isDisabled}
          onClick={handleTableOnClick}
          type="button"
        >
          {shouldShowServiceStatuses && (
            <ServiceStatusIcon
              serviceStatus={seatedOccupant.serviceStatus}
              className={iconClassNames}
              scale={tableIconScale * Number(iconWidthScale)}
            />
          )}

          {shouldShowServerBadges && assignedServer && (
            <ServerBadge
              displayMode="floorPlan"
              serverName={assignedServer.name}
              badgeColor={assignedServer.hexColor}
            />
          )}
          <FloorPlanTable.Icon
            iconName={iconName}
            iconWidthScale={iconWidthScale}
            isHighlighted={isHighlighted}
            orientation={orientation}
            tableIconScale={tableIconScale}
          />
        </button>
      </FloorPlanTable>
    );
  },
);

SeatedFloorPlanTable.displayName = 'SeatedFloorPlanTable';
