import cx from 'classnames';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { get, type SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormCheckbox } from '@components/formInputs/ControlledFormCheckbox';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { isEmailRule, isValidPasswordRule } from '@components/formInputs/rules';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useError } from '@shared/hooks/useError';
import { ROOT_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { registerAdmin } from './apiHelpers';
import styles from './CreateAccountPage.scss';

interface CreateAccountFormData {
  agreeToTerms: boolean;
  confirmPassword: string;
  email: string;
  fullName: string;
  password: string;
}

export const CreateAccountPage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const setError = useError();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
  } = useForm<CreateAccountFormData>({
    defaultValues: {
      agreeToTerms: false,
      confirmPassword: '',
      email: '',
      fullName: '',
      password: '',
    },
  });
  const values = getValues();
  const watch = useWatch({ control });

  const password = get(watch, 'password') as string;

  const onValidSubmit: SubmitHandler<CreateAccountFormData> = (data): void => {
    if (!token) return;

    setIsLoading(true);
    setErrorMessage('');

    void (async () => {
      try {
        await registerAdmin({
          email: data.email,
          fullName: data.fullName,
          isTosAccepted: data.agreeToTerms,
          password: data.password,
          token,
        });
        successToast({
          message: 'Account created successfully. Please log in to continue.',
        });
        navigate(ROOT_PATH);
      } catch (error) {
        if (error instanceof ApiError) {
          setErrorMessage(error.message);
        } else {
          setError(error);
        }
        setIsLoading(false);
      }
    })();
  };

  const someFieldsHaveErrors = !isEmpty(errors);
  const someFieldsAreEmpty = Object.keys(values).some(
    (key) => !!values[key as keyof CreateAccountFormData] === false,
  );

  return (
    <main className={styles.pageContainer}>
      <div className={styles.card}>
        <h1 className={typography.h4} id="form-title">
          Create Account
        </h1>
        <form
          aria-labelledby="form-title"
          onSubmit={handleSubmit(onValidSubmit)}
        >
          <ControlledFormInput
            control={control}
            disabled={false}
            label="Full Name"
            name="fullName"
            rules={{
              required: true,
              maxLength: {
                message: 'Name must be 255 characters or less',
                value: 255,
              },
            }}
            type="text"
            variant={LayoutVariant.Horizontal}
          />
          <ControlledFormInput
            control={control}
            disabled={false}
            label="Email"
            name="email"
            rules={{
              validate: { isEmail: isEmailRule('Email') },
              required: true,
            }}
            type="text"
            variant={LayoutVariant.Horizontal}
          />
          <ControlledFormInput
            control={control}
            disabled={false}
            label="Password"
            name="password"
            rules={{
              ...isValidPasswordRule,
              required: true,
            }}
            type="password"
            variant={LayoutVariant.Horizontal}
          />
          <ControlledFormInput
            control={control}
            disabled={false}
            label="Confirm Password"
            name="confirmPassword"
            rules={{
              validate: {
                matchesPassword: (value) =>
                  value === password || 'Passwords must match',
              },
              required: true,
            }}
            type="password"
            variant={LayoutVariant.Horizontal}
          />
          <div className={styles.tosContainer}>
            <ControlledFormCheckbox
              className={styles.tosCheckbox}
              control={control}
              label="Terms of service"
              name="agreeToTerms"
              rules={{
                required: {
                  message: 'You must agree to the terms of service',
                  value: true,
                },
              }}
            />
            <p className={typography.c2}>
              By checking this box, you are agreeing to Peak’s{' '}
              <Link
                rel="noopener noreferrer nofollow"
                target="_blank"
                to="https://www.peakreservations.com/terms-of-service"
              >
                Terms of&nbsp;Service
              </Link>
              .
            </p>
          </div>
          <p className={cx(typography.c2, styles.error)}>{errorMessage}</p>
          <Button
            isDisabled={
              !isDirty ||
              someFieldsAreEmpty ||
              someFieldsHaveErrors ||
              isLoading
            }
            label="Create Account"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <p className={typography.c2}>
            Already have a Peak Admin account?{' '}
            <Link to={ROOT_PATH}>Sign in.</Link>
          </p>
        </form>
      </div>
    </main>
  );
};
