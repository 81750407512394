import { type SubmitHandler, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { IPAD1024 } from '@shared/styles/breakpoints';
import type { CancellationPolicy } from '@shared/types/reservations';
import {
  createCancellationPolicy,
  editCancellationPolicy,
} from '../apiHelpers';
import styles from './CancellationPolicyForm.scss';
import { CancellationPolicyFormActions } from './CancellationPolicyFormActions';
import { VALID_HOURS } from './utils';

interface CancellationPolicyFormData {
  fee: number;
  hoursBeforeReservation: number;
  threshold: string;
}

export interface CancellationPolicyFormProps {
  cancellationPolicy: CancellationPolicy | null;
  exitEditMode: () => void;
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const CancellationPolicyForm = ({
  cancellationPolicy,
  exitEditMode,
  refreshRestaurant,
  restaurantId,
}: CancellationPolicyFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CancellationPolicyFormData>({
    defaultValues: {
      fee: cancellationPolicy?.fee ? cancellationPolicy.fee / 100 : undefined,
      hoursBeforeReservation: cancellationPolicy?.hoursBeforeReservation,
      threshold: cancellationPolicy
        ? (cancellationPolicy.threshold / 100).toString()
        : '',
    },
  });
  const layoutVariant = useMediaQuery({ minWidth: IPAD1024 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  const submit: SubmitHandler<CancellationPolicyFormData> = async (data) => {
    const { fee, hoursBeforeReservation, threshold } = data;

    if (cancellationPolicy?.id) {
      try {
        await editCancellationPolicy({
          fee: fee * 100,
          hoursBeforeReservation,
          id: cancellationPolicy.id,
          restaurantId,
          threshold: parseInt(threshold, 10) * 100,
        });
      } catch (e) {
        errorToast({ message: 'Failed to update Cancellation Policy' });
        reportAppError(e);
        return;
      }
      await refreshRestaurant();
      successToast({ message: 'Cancellation Policy successfully updated' });
      exitEditMode();
    } else {
      try {
        await createCancellationPolicy({
          fee: fee * 100,
          hoursBeforeReservation,
          restaurantId,
          threshold: parseInt(threshold, 10) * 100,
        });

        await refreshRestaurant();
        successToast({ message: 'Cancellation Policy successfully saved' });
        exitEditMode();
      } catch (e) {
        errorToast({ message: 'Failed to save Cancellation Policy' });
        reportAppError(e);
      }
    }
  };

  return (
    <form
      aria-labelledby="cancellation-policy"
      className={styles.cancellationForm}
      onSubmit={handleSubmit(submit)}
    >
      <ControlledFormCurrencyInput
        control={control}
        label="Fee"
        name="fee"
        placeholder="Enter fee"
        rules={{
          max: {
            value: 1000,
            message: 'Fee cannot exceed $1,000',
          },
          min: {
            value: 1,
            message: 'Fee must be at least $1',
          },
          required: true,
        }}
        variant={layoutVariant}
      />
      <ControlledFormSelect
        control={control}
        label="Time Before Fees Apply"
        name="hoursBeforeReservation"
        options={VALID_HOURS}
        rules={{
          required: true,
        }}
        variant={layoutVariant}
      />
      <ControlledFormCurrencyInput
        control={control}
        label="Price Threshold"
        name="threshold"
        rules={{
          max: {
            message: 'Price threshold cannot exceed $10,000',
            value: 10000,
          },
          required: true,
        }}
        tooltipText="Reservations sold at or below the threshold price are subject to the cancellation policy."
        variant={layoutVariant}
      />
      <CancellationPolicyFormActions
        cancellationPolicy={cancellationPolicy}
        exitEditMode={exitEditMode}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
        disabled={isSubmitting}
      />
    </form>
  );
};
