import * as icons from '@assets/icons';
import * as floorPlanIcons from '@assets/icons/floorPlan';
import * as serviceStatusIcons from '@assets/icons/serviceStatus';

type FloorPlanIconVariant = 'Error' | 'Selected';

type RemoveVariant<T extends string> =
  T extends `${infer Base}${FloorPlanIconVariant}` ? Base : T;

const ALL_ICONS = {
  ...icons,
  ...floorPlanIcons,
  ...serviceStatusIcons,
} as const;

export const IconComponents = Object.fromEntries(
  Object.entries(ALL_ICONS).map(([key, value]) => [key, value]),
) as {
  [K in keyof typeof ALL_ICONS]: (typeof ALL_ICONS)[K];
};

/**
 * The base names for all floor plan icons _without_ the variant suffix
 * ('Selected' or 'Error').
 *
 * Useful to provide a type that can be used to dynamically
 */
export type FloorPlanIconType = RemoveVariant<keyof typeof floorPlanIcons>;

/**
 * Better supports the dynamic way we construct floor plan icon names with their
 * variants. E.g. `${someIconName}Selected`.
 */
export type FloorPlanIconWithVariant =
  `${FloorPlanIconType}${FloorPlanIconVariant}`;
