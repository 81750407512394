import cx from 'classnames';
import { compact } from 'lodash-es';
import { useState } from 'react';
import { type Control, type UseFormSetValue, useWatch } from 'react-hook-form';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { parseToShortDateRange } from '@utils/dateFormatters';
import { ISOTimeTo12HourTime } from '@utils/time';
import { formatWeekDays } from '@utils/weekDayFormatters';
import { type Shift } from 'restaurantAdmin/operations/shifts/apiHelpers';
import { getShifts } from 'restaurantAdmin/operations/shifts/apiHelpers';
import { CreateShiftModal } from 'restaurantAdmin/operations/shifts/CreateShiftModal';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import type { ListingFormData } from '../types';
import { hasDateOverlap } from '../utils/listingUtils';
import { hasOverlappingDay, timeRangeOverlaps } from '../utils/timeRange';
import styles from './ReservableTimeAndDaysFieldSet.scss';

export interface ReservableTimeAndDaysFieldSetProps {
  control: Control<ListingFormData>;
  setValue: UseFormSetValue<ListingFormData>;
}

export const ReservableTimeAndDaysFieldSet = ({
  control,
  setValue,
}: ReservableTimeAndDaysFieldSetProps) => {
  const { id: restaurantId } = useRestaurant();
  const { data: shifts = [], rerun: refreshShifts } = useAbortEffect(
    (signal) => getShifts(restaurantId, signal),
    [restaurantId],
  );
  const shiftIds = useWatch({
    control,
    name: 'shiftIds',
  });
  const [overlappingShiftIds, setOverlappingShiftIds] = useState<string[]>([]);
  const {
    isOpen: isCreateShiftModalOpen,
    close: closeCreateShiftModal,
    open: openCreateShiftModal,
  } = useIsOpen();

  const validateOverlappingShifts = (selectedShiftIds: string[]): void => {
    if (!selectedShiftIds.length) return;

    const selectedShifts = compact(
      selectedShiftIds.map((shiftId) =>
        shifts.find((shift) => shift.id === shiftId),
      ),
    );

    const overlappingShifts = selectedShifts.filter((shiftA) =>
      selectedShifts.some((shiftB) => {
        if (shiftA.id === shiftB.id) return false;

        return (
          hasOverlappingDay(shiftA.repeat, shiftB.repeat) &&
          timeRangeOverlaps(
            {
              startTime: shiftA.startTime,
              endTime: shiftA.endTime,
            },
            {
              startTime: shiftB.startTime,
              endTime: shiftB.endTime,
            },
          ) &&
          hasDateOverlap(shiftA, shiftB)
        );
      }),
    );

    setOverlappingShiftIds(overlappingShifts.map((shift) => shift.id));
  };

  const options = shifts.map((shift) => ({
    label: <ShiftOption shift={shift} />,
    value: shift.id,
  }));

  const selectedShifts = compact(
    shiftIds.map((shiftId) => shifts.find((shift) => shift.id === shiftId)),
  );

  const handleOnCreateShift = (createdShiftIds: string[]) => {
    refreshShifts();
    setValue('shiftIds', [...shiftIds, ...createdShiftIds]);
  };

  const handleRemoveShift = (removedShiftId: string) => {
    setValue(
      'shiftIds',
      shiftIds.filter((id) => id !== removedShiftId),
    );
  };

  return (
    <fieldset
      className={styles.fieldset}
      data-testid="reservable-time-and-days-fieldset"
    >
      <h3 className={typography.t3}>reservable time & days</h3>
      <ControlledFormSelect
        className={styles.select}
        control={control}
        label="Shift"
        name="shiftIds"
        options={options}
        multiple
        renderValue={() => 'Select Shifts'}
        rules={{
          required: true,
        }}
        onSelect={validateOverlappingShifts}
      />
      {!!overlappingShiftIds.length && (
        <div className={styles.overlapMessage}>
          <Icon name="alertCircle" />
          <span className={typography.t1}>Overlapping Shifts</span>
          <p className={typography.t1}>
            Some shifts in this listing overlap with the other shifts.
          </p>
        </div>
      )}
      <ul aria-label="selected shifts">
        {selectedShifts.map((shift) => (
          <ShiftItem
            key={shift.id}
            hasOverlap={overlappingShiftIds.includes(shift.id)}
            shift={shift}
            handleRemoveShift={() => handleRemoveShift(shift.id)}
          />
        ))}
      </ul>
      <button
        className={styles.createShiftButton}
        type="button"
        onClick={openCreateShiftModal}
      >
        Create Shift
        <Icon name="plus" />
      </button>
      <CreateShiftModal
        isOpen={isCreateShiftModalOpen}
        closeModal={closeCreateShiftModal}
        handleOnCreateShift={handleOnCreateShift}
      />
    </fieldset>
  );
};

interface ShiftItemProps {
  hasOverlap: boolean;
  shift: Shift;
  handleRemoveShift: () => void;
}

const ShiftItem = ({
  hasOverlap,
  shift: { id, name, startDate, endDate, startTime, endTime, repeat },
  handleRemoveShift,
}: ShiftItemProps) => {
  const itemClassNames = cx({
    [styles.shiftItem]: true,
    [styles.hasOverlap]: hasOverlap,
  });

  return (
    <li className={itemClassNames} aria-labelledby={`shift-${id}-name`}>
      <IconButton
        ariaLabel="remove shift"
        iconName="close"
        onClick={handleRemoveShift}
      />
      <div className={cx(typography.c2, styles.name)} id={`shift-${id}-name`}>
        {name}
      </div>
      <div className={cx(styles.timeAndDays, typography.t3)}>
        <div className={styles.dates}>
          {parseToShortDateRange(startDate, endDate)}
        </div>
        <div className={styles.repeat}>{formatWeekDays(repeat)}</div>
        <div className={styles.times}>
          {ISOTimeTo12HourTime(startTime)}–{ISOTimeTo12HourTime(endTime)}
        </div>
      </div>
    </li>
  );
};

interface ShiftOptionProps {
  shift: Shift;
}

const ShiftOption = ({
  shift: { name, startDate, endDate, startTime, endTime, repeat },
}: ShiftOptionProps) => (
  <div className={styles.optionLabel}>
    <div className={cx(typography.c2, styles.name)}>{name}</div>
    <div className={cx(styles.timeAndDays, typography.t3)}>
      <div className={styles.dates}>
        {parseToShortDateRange(startDate, endDate)}
      </div>
      <div className={styles.repeat}>{formatWeekDays(repeat)}</div>
      <div className={styles.times}>
        {ISOTimeTo12HourTime(startTime)}–{ISOTimeTo12HourTime(endTime)}
      </div>
    </div>
  </div>
);
