// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConciergePage__pageContent___Upimt{display:flex;flex-direction:column;position:relative;min-height:0}.ConciergePage__sidePanel___YD7KR{display:flex;flex-direction:column;gap:1rem;width:34%}.ConciergePage__content___Jymhp{column-gap:3rem;display:flex;flex-direction:row;min-height:0;width:100%}.ConciergePage__floorPlanContainer___NzrT_{display:flex;flex-direction:column;align-items:flex-start;margin-top:1rem;min-width:var(--minFloorPlanTabletWidth);max-width:var(--maxFloorPlanDesktopWidth);padding:0;width:53vw}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/concierge/ConciergePage.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,YAAA,CAGF,kCACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAGF,gCACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA,CACA,UAAA,CAGF,2CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,eAAA,CACA,wCAAA,CACA,yCAAA,CACA,SAAA,CACA,UAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\n.pageContent {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  min-height: 0;\n}\n\n.sidePanel {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  width: 34%;\n}\n\n.content {\n  column-gap: 3rem;\n  display: flex;\n  flex-direction: row;\n  min-height: 0;\n  width: 100%;\n}\n\n.floorPlanContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-top: 1rem;\n  min-width: var(--minFloorPlanTabletWidth);\n  max-width: var(--maxFloorPlanDesktopWidth);\n  padding: 0;\n  width: 53vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "ConciergePage__pageContent___Upimt",
	"sidePanel": "ConciergePage__sidePanel___YD7KR",
	"content": "ConciergePage__content___Jymhp",
	"floorPlanContainer": "ConciergePage__floorPlanContainer___NzrT_"
};
export default ___CSS_LOADER_EXPORT___;
