import cx from 'classnames';
import { Controller, useWatch } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { ControlledFormCheckbox } from '@components/formInputs/ControlledFormCheckbox';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { DESKTOP1280 } from '@shared/styles/breakpoints';
import { HUMAN_READABLE_ICON_NAMES } from '@shared/types/floorPlans';
import { type GuestsApiSearch } from 'restaurantAdmin/components/guestAutocomplete/apiHelpers';
import { ControlledGuestAutocomplete } from 'restaurantAdmin/components/guestAutocomplete/ControlledGuestAutocomplete';
import { AssignedServer } from 'restaurantAdmin/components/server/AssignedServer';
import {
  ServicePageModalType,
  useServicePageModalContext,
} from 'restaurantAdmin/context/ServicePageModalContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  type AssignedServer as AssignedServerType,
  type HostFloorPlanTable,
} from 'restaurantAdmin/floorPlans/apiHelpers';
import typography from '~styles/typography.scss';
import {
  getWalkInAvailability,
  seatWalkIn,
  type WalkInAvailability,
} from '../../apiHelpers';
import { OccupantType } from '../apiHelpers';
import { useServersContext } from '../sidePanel/servers/ServersContext';
import styles from './WalkInForm.scss';
import { useWalkInFormContext, type WalkInFormData } from './WalkInFormContext';
import { WalkInSheetWarning } from './WalkInSheetWarning';

export interface WalkInFormProps {
  className?: string;
  selectedTable: HostFloorPlanTable;
  onClose: () => void;
  handleWalkInSeated: () => void;
}

export const WalkInForm = ({
  className,
  selectedTable,
  onClose,
  handleWalkInSeated,
}: WalkInFormProps) => {
  const { openModal } = useServicePageModalContext();

  const { id: restaurantId, maxReservationGuests } = useRestaurant();
  const layoutVariant = useMediaQuery({ minWidth: DESKTOP1280 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  const {
    form: {
      control,
      getValues,
      setValue,
      handleSubmit,
      formState: { isSubmitting },
      reset,
    },
  } = useWalkInFormContext();

  const { allServers } = useServersContext();

  const saveAsGuest = useWatch({ control, name: 'saveAsGuest' });
  const guestId = useWatch({ control, name: 'guestId' });
  const serverId = useWatch({ control, name: 'serverId' });

  const assignedServer =
    allServers.find((server) => server.id === serverId) || null;

  const createSeatWalkIn = async (
    data: WalkInFormData,
    availability: WalkInAvailability,
  ): Promise<void> => {
    try {
      await seatWalkIn({
        restaurantId,
        floorPlanTableId: selectedTable.id,
        guestCount: data.guestCount,
        ...(data.firstName && { firstName: data.firstName }),
        ...(data.lastName && { lastName: data.lastName }),
        saveAsGuest: data.saveAsGuest,
        guestId: data.guestId,
        turnTime: availability.turnTime,
        hasAvailability: availability.hasAvailability,
        serverId: data.serverId || null,
      });
      handleWalkInSeated();
      reset();
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: e.message });
      }
      reportAppError(e);
    }
  };

  const submitWalkIn = async (data: WalkInFormData) => {
    try {
      const response = await getWalkInAvailability({
        floorPlanTableId: selectedTable.id,
        guestCount: data.guestCount,
        restaurantId,
      });
      if (!response.hasAvailability) {
        openModal(ServicePageModalType.WalkInSeatingConflict, {
          handleConfirm: () => {
            void createSeatWalkIn(getValues(), response);
          },
        });
        return;
      }

      if (response.turnTime !== null) {
        openModal(ServicePageModalType.WalkInTurnTimeInformation, {
          handleConfirm: () => {
            void createSeatWalkIn(getValues(), response);
          },
          turnTime: response.turnTime,
        });
        return;
      }

      await createSeatWalkIn(data, response);
      reset();
    } catch (error) {
      handleOnClose();
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
      reportAppError(error);
    }
  };

  const handleAutocompleteSelect = (newGuest: GuestsApiSearch) => {
    setValue('firstName', newGuest.firstName);
    setValue('lastName', newGuest.lastName);
    setValue('saveAsGuest', false);
  };

  const handleAutocompleteClear = () => {
    setValue('firstName', '');
    setValue('lastName', '');
  };

  const saveAsGuestFieldsDisabled = !!guestId;

  const handleServerChange = (server: AssignedServerType | null) => {
    setValue('serverId', server?.id);
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Card bordered className={cx(styles.container, className)}>
      <header>
        <p className={cx(typography.h4)}>Seat Walk-In</p>
        <IconButton
          ariaLabel="close sheet"
          onClick={handleOnClose}
          iconName="close"
        />
      </header>
      <WalkInSheetWarning floorPlanTableId={selectedTable.id} />
      <Card className={cx(typography.t1, styles.tableCard)}>
        <Icon
          name={selectedTable.iconName}
          ariaLabel={HUMAN_READABLE_ICON_NAMES[selectedTable.iconName]}
        />
        {selectedTable.name}
      </Card>
      <AssignedServer
        occupant={{ occupantId: null, occupantType: OccupantType.WalkIn }}
        server={assignedServer}
        submitHandler={handleServerChange}
      />
      <form
        aria-label="Seat Walk-in Form"
        onSubmit={handleSubmit(submitWalkIn)}
      >
        <Controller
          control={control}
          name="guestCount"
          render={({ field: { onChange, value } }) => (
            <GuestCountPicker
              guestCount={value}
              max={maxReservationGuests}
              onChange={onChange}
            />
          )}
        />
        <ControlledGuestAutocomplete
          control={control}
          name="guestId"
          onSelect={handleAutocompleteSelect}
          onClear={handleAutocompleteClear}
        />
        <ControlledFormInput
          className={styles.nameInput}
          control={control}
          label="First Name"
          name="firstName"
          placeholder={saveAsGuest ? 'Required' : 'Optional'}
          rules={{
            required: saveAsGuest,
          }}
          type="text"
          variant={layoutVariant}
          disabled={saveAsGuestFieldsDisabled}
        />
        <ControlledFormInput
          className={styles.nameInput}
          control={control}
          label="Last Name"
          name="lastName"
          placeholder={saveAsGuest ? 'Required' : 'Optional'}
          rules={{
            required: saveAsGuest,
          }}
          type="text"
          variant={layoutVariant}
          disabled={saveAsGuestFieldsDisabled}
        />
        <ControlledFormCheckbox
          className={styles.nameInput}
          control={control}
          label="Save to Guest Book"
          name="saveAsGuest"
          disabled={saveAsGuestFieldsDisabled}
        />
        <div className={styles.callToActionSection}>
          <Button
            label="Seat Walk-in"
            type="submit"
            variant={ButtonVariants.Primary}
            isDisabled={isSubmitting}
          />
          <Button
            label="Cancel"
            onClick={handleOnClose}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </form>
    </Card>
  );
};
