// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicNameFieldSet__fieldset___kXbEb{position:relative;display:flex;flex-direction:column;width:100%;background-color:var(--panelBlue);padding:12px;row-gap:1rem;border-radius:8px}.PublicNameFieldSet__fieldset___kXbEb h3{text-transform:uppercase;letter-spacing:.0625rem;line-height:1rem;color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/PublicNameFieldSet.scss"],"names":[],"mappings":"AAAA,sCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,iCAAA,CACA,YAAA,CACA,YAAA,CACA,iBAAA,CAEA,yCACE,wBAAA,CACA,uBAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".fieldset {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  background-color: var(--panelBlue);\n  padding: 12px;\n  row-gap: 1rem;\n  border-radius: 8px;\n\n  h3 {\n    text-transform: uppercase;\n    letter-spacing: 0.0625rem;\n    line-height: 1rem;\n    color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldset": "PublicNameFieldSet__fieldset___kXbEb"
};
export default ___CSS_LOADER_EXPORT___;
