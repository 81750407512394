// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Kebab__menuWrapper___UTFX2{align-self:center;width:1rem}.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58{background-color:rgba(0,0,0,0);border-radius:.25rem;border:none;cursor:pointer;padding:2px}.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58:active,.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58:focus,.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58:hover{background-color:var(--gold);color:var(--black);border-radius:var(--radius_small)}.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58:active svg,.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58:focus svg,.Kebab__menuWrapper___UTFX2 .Kebab__menuButton___zvH58:hover svg{fill:var(--black)}.Kebab__menuWrapper___UTFX2 .Kebab__icon___lv5dM{fill:var(--primary200);margin-inline:-6px;height:24px}", "",{"version":3,"sources":["webpack://./src/shared/components/kebab/Kebab.scss"],"names":[],"mappings":"AAAA,4BACE,iBAAA,CACA,UAAA,CAEA,uDACE,8BAAA,CACA,oBAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CAEA,wLAGE,4BAAA,CACA,kBAAA,CACA,iCAAA,CAEA,oMACE,iBAAA,CAKN,iDACE,sBAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":[".menuWrapper {\n  align-self: center;\n  width: 1rem;\n\n  .menuButton {\n    background-color: transparent;\n    border-radius: 0.25rem;\n    border: none;\n    cursor: pointer;\n    padding: 2px;\n\n    &:active,\n    &:focus,\n    &:hover {\n      background-color: var(--gold);\n      color: var(--black);\n      border-radius: var(--radius_small);\n\n      svg {\n        fill: var(--black);\n      }\n    }\n  }\n\n  .icon {\n    fill: var(--primary200);\n    margin-inline: -6px;\n    height: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuWrapper": "Kebab__menuWrapper___UTFX2",
	"menuButton": "Kebab__menuButton___zvH58",
	"icon": "Kebab__icon___lv5dM"
};
export default ___CSS_LOADER_EXPORT___;
