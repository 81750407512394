import type { ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';
import {
  type Control,
  useForm,
  type UseFormHandleSubmit,
  type UseFormReset,
} from 'react-hook-form';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import {
  getWaitList,
  type WaitListEntry,
} from 'restaurantAdmin/reservations/service/sidePanel/waitList/apiHelpers';
import { useRestaurant } from '../../../../../context/useRestaurant';
import { type WaitListFormData } from '../WaitListForm';

export interface WaitListContextState {
  waitListEntries: WaitListEntry[];
  selectedWaitListEntry: WaitListEntry | undefined;
  fetchWaitListEntries: () => void;
  setSelectedWaitListEntryIndex: (index: number) => void;
  control: Control<WaitListFormData>;
  handleSubmit: UseFormHandleSubmit<WaitListFormData>;
  reset: UseFormReset<WaitListFormData>;
}

const WaitListContext = createContext<WaitListContextState | null>(null);

export const useWaitListContext = (): WaitListContextState =>
  useDefinedContext(WaitListContext);

const DEFAULT_GUEST_COUNT = 2;

export const WaitListContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { control, handleSubmit, reset } = useForm<WaitListFormData>({
    defaultValues: {
      guestCount: DEFAULT_GUEST_COUNT,
    },
  });

  const { id: restaurantId } = useRestaurant();

  const [selectedWaitListEntryIndex, setSelectedWaitListEntryIndex] =
    useState<number>(-1);

  const { data: waitListEntries = [], rerun: fetchWaitListEntries } =
    useAbortEffect(() => getWaitList({ restaurantId }), [restaurantId]);

  const selectedWaitListEntry = waitListEntries[selectedWaitListEntryIndex];

  const value = useMemo(
    () => ({
      waitListEntries,
      setSelectedWaitListEntryIndex,
      fetchWaitListEntries,
      selectedWaitListEntry,
      control,
      handleSubmit,
      reset,
    }),
    [waitListEntries, selectedWaitListEntryIndex],
  );

  return (
    <WaitListContext.Provider value={value}>
      {children}
    </WaitListContext.Provider>
  );
};
