import { differenceBy } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { reportAppError } from '@shared/reportAppError';
import { CENTS_IN_DOLLAR } from '@utils/currency';
import { useRestaurant } from '../../../context/useRestaurant';
import {
  editListing,
  type EditListingPayload,
  getListing,
} from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import type { ListingFormData, PricePointFormData } from '../types';
import { getDefaultFormValues } from '../utils/formHelpers';
import { getDestinationPath } from '../utils/listingUtils';

export const useEditListing = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { listingId } = useParams();
  const { id: restaurantId } = useRestaurant();
  const {
    floorPlans,
    refreshFloorPlans,
    refreshListings,
    setSelectedFloorPlanId,
    setSelectedListingId,
  } = useListingsContext();

  const { data: existingListing, isPending } = useAbortEffect(
    (signal) => getListing(listingId!, restaurantId, signal),
    [restaurantId, listingId],
  );

  const { control, handleSubmit, setValue, resetField } =
    useForm<ListingFormData>({
      values: getDefaultFormValues({
        floorPlan: floorPlans.find(
          (fp) => existingListing?.floorPlanId === fp.id,
        )!,
        isListingLoading: isPending,
        listing: existingListing || null,
      }),
    });

  const {
    isOpen: isErrorModalOpen,
    data: errorModalData,
    open: openErrorModal,
    close: closeErrorModal,
  } = useIsOpenWithData<{ message: string }>();
  const {
    isOpen: isWarningModalOpen,
    data: warningModalData,
    open: openWarningModal,
    close: closeWarningModal,
  } = useIsOpenWithData<{ warnings: string[] }>();

  const buildPayload = ({
    data,
    ignoreWarnings,
    skipStranding = false,
  }: {
    data: ListingFormData;
    ignoreWarnings: boolean;
    skipStranding?: boolean;
  }): EditListingPayload => {
    const newPricePoints = data.pricePoints
      .filter((pricePoint) => !pricePoint.id)
      .map((pricePoint) => ({
        activeDays: pricePoint.activeDays,
        endTime: pricePoint.endTime,
        price: Number(pricePoint.price) * CENTS_IN_DOLLAR,
        startTime: pricePoint.startTime,
      }));
    const editedPricePoints = data.pricePoints
      .filter(
        (price): price is PricePointFormData & { id: string } => !!price.id,
      )
      .map((pricePoint) => ({
        activeDays: pricePoint.activeDays,
        endTime: pricePoint.endTime,
        id: pricePoint.id,
        price: Number(pricePoint.price) * CENTS_IN_DOLLAR,
        startTime: pricePoint.startTime,
      }));

    const removedPricePoints = differenceBy(
      existingListing?.pricePoints,
      editedPricePoints,
      'id',
    ).map((pricePoint) => ({ id: pricePoint.id }));

    return {
      floorPlanId: data.floorPlanId,
      highlightedFloorPlanTableIds: data.highlightedTables.map(
        (table) => table.id,
      ),
      iconName: data.iconName,
      interval: data.interval,
      inventoryCount: Number(data.inventoryCount),
      isCommunal: data.isCommunal,
      maximumGuests: Number(data.maximumGuests),
      minimumGuests: Number(data.minimumGuests),
      name: data.name,
      price: Number(data.price) * CENTS_IN_DOLLAR,
      pricePoints: {
        added: newPricePoints,
        removed: removedPricePoints,
        edited: editedPricePoints,
      },
      publicName: data.publicName,
      turnTime: data.turnTime,
      shiftIds: data.shiftIds,
      skipStranding,
      ignoreWarnings,
    };
  };

  const handleOnClickSave = handleSubmit(async (data: ListingFormData) => {
    const isDraft = existingListing?.status === 'draft';

    try {
      const response = await editListing(
        restaurantId,
        listingId!,
        buildPayload({ data, ignoreWarnings: false }),
      );

      if (response.warnings.length) {
        openWarningModal({ warnings: response.warnings });
      } else {
        successToast({
          message: 'Listing successfully updated',
        });
        refreshListings();
        refreshFloorPlans();
        setSelectedFloorPlanId(data.floorPlanId);
        setSelectedListingId(listingId!);
        navigate(
          getDestinationPath(state.referrer, isDraft ? 'draft' : 'published'),
        );
      }
    } catch (e) {
      if (e instanceof ApiError) {
        openErrorModal({ message: e.message });
      }
      reportAppError(e);
    }
  });

  const confirmSubmit = async ({
    data,
    skipStranding,
  }: {
    data: ListingFormData;
    skipStranding: boolean;
  }) => {
    const isDraft = existingListing?.status === 'draft';

    try {
      await editListing(
        restaurantId,
        listingId!,
        buildPayload({ data, ignoreWarnings: true, skipStranding }),
      );

      successToast({
        message: 'Listing successfully updated',
      });
      refreshListings();
      refreshFloorPlans();
      setSelectedFloorPlanId(data.floorPlanId);
      setSelectedListingId(listingId!);
      navigate(
        getDestinationPath(state.referrer, isDraft ? 'draft' : 'published'),
      );
    } catch (e) {
      if (e instanceof ApiError) {
        openErrorModal({ message: e.message });
      }
      reportAppError(e);
    }
  };

  const confirmSubmitAndStrand = handleSubmit(async (data: ListingFormData) => {
    await confirmSubmit({ data, skipStranding: false });
  });

  const confirmSubmitWithoutStranding = handleSubmit(
    async (data: ListingFormData) => {
      await confirmSubmit({ data, skipStranding: true });
    },
  );

  return {
    closeErrorModal,
    closeWarningModal,
    control,
    errorModalData,
    floorPlans,
    handleOnClickSave,
    confirmSubmitAndStrand,
    confirmSubmitWithoutStranding,
    isErrorModalOpen,
    isWarningModalOpen,
    referrer: state.referrer,
    warningModalData,
    setValue,
    resetField,
  };
};
