// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RestaurantEventsPage__mainContent___QXB7H{max-width:47rem;display:flex;flex-direction:column;gap:16px}.RestaurantEventsPage__mainContent___QXB7H>p{color:var(--white70);margin:0 0 1.5em}.RestaurantEventsPage__errorStyle___D3byn{color:var(--primary400)}.RestaurantEventsPage__newEventButton___dU6et{align-items:center;border-radius:.25rem;border:1px dashed var(--white30);color:var(--white70);cursor:pointer;display:flex;justify-content:space-between;padding:1rem 1.5rem}.RestaurantEventsPage__newEventButton___dU6et>svg{min-width:1.5rem;width:1.5rem;stroke:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/events/RestaurantEventsPage.scss"],"names":[],"mappings":"AAAA,2CACE,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,6CACE,oBAAA,CACA,gBAAA,CAIJ,0CACE,uBAAA,CAGF,8CACE,kBAAA,CACA,oBAAA,CACA,gCAAA,CACA,oBAAA,CACA,cAAA,CACA,YAAA,CACA,6BAAA,CACA,mBAAA,CAEA,kDACE,gBAAA,CACA,YAAA,CACA,mBAAA","sourcesContent":[".mainContent {\n  max-width: 47rem;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  & > p {\n    color: var(--white70);\n    margin: 0 0 1.5em;\n  }\n}\n\n.errorStyle {\n  color: var(--primary400);\n}\n\n.newEventButton {\n  align-items: center;\n  border-radius: 0.25rem;\n  border: 1px dashed var(--white30);\n  color: var(--white70);\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n  padding: 1rem 1.5rem;\n\n  & > svg {\n    min-width: 1.5rem;\n    width: 1.5rem;\n    stroke: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": "RestaurantEventsPage__mainContent___QXB7H",
	"errorStyle": "RestaurantEventsPage__errorStyle___D3byn",
	"newEventButton": "RestaurantEventsPage__newEventButton___dU6et"
};
export default ___CSS_LOADER_EXPORT___;
