import cx from 'classnames';
import { type CSSProperties, forwardRef, type ReactNode } from 'react';
import {
  calculateContainerStyle,
  calculateIconStyle,
} from '@components/floorPlan/utils';
import { type AnyIcon, Icon, type IconProps } from '@components/icon/Icon';
import styles from './FloorPlanTable.scss';

interface FloorPlanTableComponentProps extends React.ComponentProps<'div'> {
  left: string;
  top: string;
  tableIconScale: number;
  children: ReactNode;
}

const FloorPlanTableComponent = forwardRef<
  HTMLDivElement,
  FloorPlanTableComponentProps
>(({ left, top, tableIconScale, children, className, style, ...rest }, ref) => (
  <div
    className={cx(styles.container, className)}
    style={{
      ...calculateContainerStyle({
        left,
        tableIconScale,
        top,
      }),
      ...style,
    }}
    ref={ref}
    {...rest}
  >
    {children}
  </div>
));
FloorPlanTableComponent.displayName = 'FloorPlanTable';

export type TableHighlightingMode = 'outline' | 'goldCircle' | 'pulse';

interface FloorPlanTableIconProps extends Omit<IconProps, 'name'> {
  hasError?: boolean;
  iconName: string;
  iconWidthScale: string;
  isHighlighted?: boolean;
  orientation: string;
  tableIconScale: number;
  tableHighlightingMode?: TableHighlightingMode;
}

const FloorPlanTableIcon = ({
  hasError = false,
  iconName,
  iconWidthScale,
  isHighlighted = false,
  orientation,
  style,
  tableIconScale,
  tableHighlightingMode = 'outline',
  ...rest
}: FloorPlanTableIconProps) => {
  const iconSuffix = () => {
    if (hasError) return 'Error';
    if (isHighlighted) return 'Selected';
    return '';
  };

  const stateAwareIconName = `${iconName}${iconSuffix()}` as AnyIcon;

  const iconStyle = calculateIconStyle({
    iconWidthScale,
    orientation,
    tableIconScale,
  });

  const icon = (
    <Icon
      name={stateAwareIconName}
      style={{
        ...iconStyle,
        ...style,
      }}
      testId={`icon-${stateAwareIconName}`}
      {...rest}
    />
  );

  if (isHighlighted && tableHighlightingMode !== 'outline') {
    const iconHighlightCircleStyle = iconStyle.width
      ? ({
          '--iconWidth': `${Number(iconStyle.width) * 1.15}px`,
        } as CSSProperties)
      : {};

    const iconWrapperClassName = cx({
      [styles.goldCircle]: true,
      [styles.pulse]: tableHighlightingMode === 'pulse',
    });

    return (
      <div
        data-testid="floor-plan-icon-wrapper"
        className={iconWrapperClassName}
        style={iconHighlightCircleStyle}
      >
        {icon}
      </div>
    );
  }

  return icon;
};

export default Object.assign(FloorPlanTableComponent, {
  Icon: FloorPlanTableIcon,
});
