// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardElement__cardElement___aD0ad{background-color:var(--white);border:2px solid var(--darkGrey300);border-radius:5px;height:48px;padding:14px 16px}.CardElement__cardElementFocused___IC1YQ{outline:var(--primary100) auto medium}", "",{"version":3,"sources":["webpack://./src/shared/components/cardElement/CardElement.scss"],"names":[],"mappings":"AAAA,kCACE,6BAAA,CACA,mCAAA,CACA,iBAAA,CACA,WAAA,CACA,iBAAA,CAGF,yCACE,qCAAA","sourcesContent":[".cardElement {\n  background-color: var(--white);\n  border: 2px solid var(--darkGrey300);\n  border-radius: 5px;\n  height: 48px;\n  padding: 14px 16px;\n}\n\n.cardElementFocused {\n  outline: var(--primary100) auto medium;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardElement": "CardElement__cardElement___aD0ad",
	"cardElementFocused": "CardElement__cardElementFocused___IC1YQ"
};
export default ___CSS_LOADER_EXPORT___;
