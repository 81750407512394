// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Error__container___lsH4Z{color:var(--pink);margin-top:.5rem}", "",{"version":3,"sources":["webpack://./src/shared/components/error/Error.scss"],"names":[],"mappings":"AAAA,0BACE,iBAAA,CACA,gBAAA","sourcesContent":[".container {\n  color: var(--pink);\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Error__container___lsH4Z"
};
export default ___CSS_LOADER_EXPORT___;
