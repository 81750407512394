import { ISOTimeToMinuteOfDay } from '@utils/time';
import type { ListingWithShifts } from '../apiHelpers';
import { isOverlappingInRange } from '../utils/listingUtils';

export const timeToRow = (time: string) =>
  Math.floor(ISOTimeToMinuteOfDay(time) / 15) + 2;

export const weekDayToCol = (weekDay: number) => weekDay + 2;

/**
 * Get the ids of listings that overlap with a given time block on a calendar
 * It will account for turn time after the end time as well as the rollover to the next day
 *
 * @param startTime - start time of the range: 'hh:mm:ss'
 * @param endTime - end time of the range: 'hh:mm:ss'
 * @param date - date string in ISO format: 'yyyy-mm-dd'
 * @param listings - array of listings
 * @returns array of listing ids
 */
export const getOverlappingListingIds = (
  startTime: string,
  endTime: string,
  date: string,
  listings: ListingWithShifts[],
) =>
  listings
    .filter(isOverlappingInRange(startTime, endTime, date))
    .map((listing) => listing.id);
