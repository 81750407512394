import { type AnyIcon } from '@components/icon/Icon';

export type ServiceStatus = keyof typeof SERVICE_STATUS_LABELS;

export const SERVICE_STATUS_LABELS = {
  appetizer: 'Appetizer',
  arrived: 'Arrived',
  booked: 'Booked',
  bottleService: 'Bottle Service',
  bussingNeeded: 'Bussing Needed',
  checkDropped: 'Check Dropped',
  cleared: 'Cleared',
  confirmed: 'Confirmed',
  dessert: 'Dessert',
  drinks: 'Drinks',
  entree: 'Entree',
  leftMessage: 'Left Message',
  noAnswer: 'No Answer',
  onTheWay: 'On The Way',
  paid: 'Paid',
  partiallyArrived: 'Partially Arrived',
  partiallySeated: 'Partially Seated',
  postMealDrink: 'Post Meal Drink',
  runningLate: 'Running Late',
  seated: 'Seated',
  tableKnock: 'Table Knock',
  tableReady: 'Table Ready',
  unconfirmed: 'Unconfirmed',
  wrongNumber: 'Wrong Number',
} as const satisfies Partial<Record<AnyIcon, string>>;

// these are in display order...India 👀
export const SERVICE_STATUS_SELECTION_LIST = [
  'unconfirmed',
  'booked',
  'leftMessage',
  'wrongNumber',
  'noAnswer',
  'confirmed',
  'onTheWay',
  'runningLate',
  'arrived',
  'partiallyArrived',
  'tableReady',
  'partiallySeated',
  'seated', // hidden from dropdown
  'drinks',
  'appetizer',
  'entree',
  'dessert',
  'postMealDrink',
  'bottleService',
  'checkDropped',
  'paid',
  'tableKnock',
  'bussingNeeded',
  'cleared',
] as const satisfies ServiceStatus[];
