import { Menu } from '@base-ui-components/react';
import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from '../state/ReservationServiceContext';
import styles from './ViewModeOptionsMenu.scss';

export const ViewModeOptionsMenu = () => {
  const {
    setSidePanelSheet,
    shouldShowServiceStatuses,
    shouldShowTimers,
    toggleShouldShowServiceStatuses,
    toggleShouldShowTimers,
  } = useReservationServiceContext();

  return (
    <Menu.Root>
      <Menu.Trigger className={cx(styles.button, typography.h8)}>
        Options
        <Icon name="options" />
      </Menu.Trigger>
      <Menu.Portal>
        <Menu.Positioner
          className={styles.positioner}
          side="bottom"
          sideOffset={12}
        >
          <Menu.Popup className={styles.popup}>
            <Menu.Arrow className={styles.arrow}>
              <Icon name="arrowPopover" />
            </Menu.Arrow>
            <Menu.Item
              className={styles.menuItem}
              onClick={toggleShouldShowTimers}
            >
              {shouldShowTimers ? 'Hide Timers' : 'Show Timers'}
            </Menu.Item>
            <Menu.Item
              className={styles.menuItem}
              onClick={toggleShouldShowServiceStatuses}
            >
              {shouldShowServiceStatuses
                ? 'Hide Service Statuses'
                : 'Show Service Statuses'}
            </Menu.Item>
            <Menu.Separator className={styles.separator} />
            <Menu.Item
              className={styles.menuItem}
              onClick={() =>
                setSidePanelSheet({
                  mode: SidePanelSheetMode.SplitMerge,
                })
              }
            >
              Split/Merge
            </Menu.Item>
          </Menu.Popup>
        </Menu.Positioner>
      </Menu.Portal>
    </Menu.Root>
  );
};
