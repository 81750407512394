// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DisplayIconRadioButton__displayIconButton___pV5BH{align-items:center;background-color:rgba(0,0,0,0);border-radius:4px;border:1px solid #404c60;display:flex;justify-content:center;padding:8px}.DisplayIconRadioButton__displayIconButton___pV5BH svg{height:48px;min-width:48px;width:48px}.DisplayIconRadioButton__buttonSelected___dCNAF{border-color:var(--gold)}.DisplayIconRadioButton__buttonError___zBJ2w{border-color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/formInputs/DisplayIconRadioButton.scss"],"names":[],"mappings":"AAAA,mDACE,kBAAA,CACA,8BAAA,CACA,iBAAA,CACA,wBAAA,CACA,YAAA,CACA,sBAAA,CACA,WAAA,CAEA,uDACE,WAAA,CACA,cAAA,CACA,UAAA,CAIJ,gDACE,wBAAA,CAGF,6CACE,wBAAA","sourcesContent":[".displayIconButton {\n  align-items: center;\n  background-color: transparent;\n  border-radius: 4px;\n  border: 1px solid #404c60;\n  display: flex;\n  justify-content: center;\n  padding: 8px;\n\n  svg {\n    height: 48px;\n    min-width: 48px;\n    width: 48px;\n  }\n}\n\n.buttonSelected {\n  border-color: var(--gold);\n}\n\n.buttonError {\n  border-color: var(--pink);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"displayIconButton": "DisplayIconRadioButton__displayIconButton___pV5BH",
	"buttonSelected": "DisplayIconRadioButton__buttonSelected___dCNAF",
	"buttonError": "DisplayIconRadioButton__buttonError___zBJ2w"
};
export default ___CSS_LOADER_EXPORT___;
