// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationsMetricsPopover__container___vbE3j{background-color:var(--primary500);border-radius:8px;position:absolute;z-index:var(--z_metricsPopover);text-align:right;row-gap:8px;display:grid;grid-template-columns:1fr;padding:16px}.ReservationsMetricsPopover__container___vbE3j button{display:grid;grid-template-columns:1fr 1fr 1fr;text-align:right}.ReservationsMetricsPopover__container___vbE3j button:first-child{margin-bottom:8px}.ReservationsMetricsPopover__container___vbE3j button div:first-child{text-align:left}.ReservationsMetricsPopover__container___vbE3j button:hover{background-color:var(--gold);color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/ReservationsMetricsPopover.scss"],"names":[],"mappings":"AAAA,+CACE,kCAAA,CACA,iBAAA,CACA,iBAAA,CACA,+BAAA,CACA,gBAAA,CACA,WAAA,CACA,YAAA,CACA,yBAAA,CACA,YAAA,CAEA,sDAKE,YAAA,CACA,iCAAA,CACA,gBAAA,CANA,kEACE,iBAAA,CAOF,sEACE,eAAA,CAGF,4DACE,4BAAA,CACA,kBAAA","sourcesContent":[".container {\n  background-color: var(--primary500);\n  border-radius: 8px;\n  position: absolute;\n  z-index: var(--z_metricsPopover);\n  text-align: right;\n  row-gap: 8px;\n  display: grid;\n  grid-template-columns: 1fr;\n  padding: 16px;\n\n  button {\n    &:first-child {\n      margin-bottom: 8px;\n    }\n\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    text-align: right;\n\n    div:first-child {\n      text-align: left;\n    }\n\n    &:hover {\n      background-color: var(--gold);\n      color: var(--white);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationsMetricsPopover__container___vbE3j"
};
export default ___CSS_LOADER_EXPORT___;
