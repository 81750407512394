// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingOverlapWarning__container___wsEpE{background-color:var(--lightGrey200);border-radius:.5rem;padding:.5rem;margin-top:1.5rem;margin-bottom:1.5rem}.ListingOverlapWarning__container___wsEpE .ListingOverlapWarning__heading___NiLDi{display:flex;align-items:center}.ListingOverlapWarning__container___wsEpE .ListingOverlapWarning__heading___NiLDi .ListingOverlapWarning__icon___fmgCG{height:16px;margin-right:.25rem;stroke-width:1px;stroke:var(--darkBlue);width:16px}.ListingOverlapWarning__container___wsEpE .ListingOverlapWarning__heading___NiLDi h2{color:var(--darkBlue)}.ListingOverlapWarning__container___wsEpE p{color:var(--darkBlue);margin:.25rem 0 0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/ListingOverlapWarning.scss"],"names":[],"mappings":"AAAA,0CACE,oCAAA,CACA,mBAAA,CACA,aAAA,CACA,iBAAA,CACA,oBAAA,CAEA,kFACE,YAAA,CACA,kBAAA,CAEA,uHACE,WAAA,CACA,mBAAA,CACA,gBAAA,CACA,sBAAA,CACA,UAAA,CAGF,qFACE,qBAAA,CAIJ,4CACE,qBAAA,CACA,iBAAA","sourcesContent":[".container {\n  background-color: var(--lightGrey200);\n  border-radius: 0.5rem;\n  padding: 0.5rem;\n  margin-top: 1.5rem;\n  margin-bottom: 1.5rem;\n\n  .heading {\n    display: flex;\n    align-items: center;\n\n    .icon {\n      height: 16px;\n      margin-right: 0.25rem;\n      stroke-width: 1px;\n      stroke: var(--darkBlue);\n      width: 16px;\n    }\n\n    h2 {\n      color: var(--darkBlue);\n    }\n  }\n\n  p {\n    color: var(--darkBlue);\n    margin: 0.25rem 0 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ListingOverlapWarning__container___wsEpE",
	"heading": "ListingOverlapWarning__heading___NiLDi",
	"icon": "ListingOverlapWarning__icon___fmgCG"
};
export default ___CSS_LOADER_EXPORT___;
