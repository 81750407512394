// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DraggingAvatar__draggingAvatar___ka7Y6{background-color:var(--secondary100);color:var(--darkGrey400)}.DraggingAvatar__isDroppable___bjZfT{background-color:var(--green200)}.DraggingAvatar__isNotDroppable___KWb3N{background-color:var(--red200);cursor:no-drop}.DraggingAvatar__draggingIndicator___USjpG{height:2.5rem;width:2.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/DraggingAvatar.scss"],"names":[],"mappings":"AAAA,wCACE,oCAAA,CACA,wBAAA,CAGF,qCACE,gCAAA,CAGF,wCACE,8BAAA,CACA,cAAA,CAGF,2CACE,aAAA,CACA,YAAA","sourcesContent":[".draggingAvatar {\n  background-color: var(--secondary100);\n  color: var(--darkGrey400);\n}\n\n.isDroppable {\n  background-color: var(--green200);\n}\n\n.isNotDroppable {\n  background-color: var(--red200);\n  cursor: no-drop;\n}\n\n.draggingIndicator {\n  height: 2.5rem;\n  width: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggingAvatar": "DraggingAvatar__draggingAvatar___ka7Y6",
	"isDroppable": "DraggingAvatar__isDroppable___bjZfT",
	"isNotDroppable": "DraggingAvatar__isNotDroppable___KWb3N",
	"draggingIndicator": "DraggingAvatar__draggingIndicator___USjpG"
};
export default ___CSS_LOADER_EXPORT___;
