// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OccupantMetrics__container___SveGf{display:flex;flex-direction:row;gap:.25rem;padding:0}.OccupantMetrics__container___SveGf button:focus:focus-visible{outline:1px solid var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/OccupantMetrics.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,kBAAA,CACA,UAAA,CACA,SAAA,CAGE,+DACE,6BAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  gap: 0.25rem;\n  padding: 0;\n\n  button {\n    &:focus:focus-visible {\n      outline: 1px solid var(--gold);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OccupantMetrics__container___SveGf"
};
export default ___CSS_LOADER_EXPORT___;
