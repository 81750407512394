// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContactInfo__container___DFmR4{display:flex;flex-direction:column;gap:.5rem;background-color:var(--panelBlue);border-radius:8px;padding:1rem;width:100%}.ContactInfo__contactInfo___M7jcT{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/contactInfo/ContactInfo.scss"],"names":[],"mappings":"AAAA,gCACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,iCAAA,CACA,iBAAA,CACA,YAAA,CACA,UAAA,CAGF,kCACE,oBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  background-color: var(--panelBlue);\n  border-radius: 8px;\n  padding: 1rem;\n  width: 100%;\n}\n\n.contactInfo {\n  color: var(--white70);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ContactInfo__container___DFmR4",
	"contactInfo": "ContactInfo__contactInfo___M7jcT"
};
export default ___CSS_LOADER_EXPORT___;
