// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SeatedFloorPlanTable__button___hZRKm{background-color:rgba(0,0,0,0);padding:0;border:none}.SeatedFloorPlanTable__serviceStatusIcon___AOt7T{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:var(--z_serviceStatusIcon)}.SeatedFloorPlanTable__serviceStatusIcon___AOt7T.SeatedFloorPlanTable__isHighlighted___uWbF9{border:2px solid var(--white);border-radius:50%}.SeatedFloorPlanTable__serviceStatusIcon___AOt7T.SeatedFloorPlanTable__isDimmed___jWRrt{opacity:.75}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/SeatedFloorPlanTable.scss"],"names":[],"mappings":"AAAA,sCACE,8BAAA,CACA,SAAA,CACA,WAAA,CAGF,iDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,kCAAA,CAEA,6FACE,6BAAA,CACA,iBAAA,CAGF,wFACE,WAAA","sourcesContent":[".button {\n  background-color: transparent;\n  padding: 0;\n  border: none;\n}\n\n.serviceStatusIcon {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: var(--z_serviceStatusIcon);\n\n  &.isHighlighted {\n    border: 2px solid var(--white);\n    border-radius: 50%;\n  }\n\n  &.isDimmed {\n    opacity: 0.75;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "SeatedFloorPlanTable__button___hZRKm",
	"serviceStatusIcon": "SeatedFloorPlanTable__serviceStatusIcon___AOt7T",
	"isHighlighted": "SeatedFloorPlanTable__isHighlighted___uWbF9",
	"isDimmed": "SeatedFloorPlanTable__isDimmed___jWRrt"
};
export default ___CSS_LOADER_EXPORT___;
