import { useEffect, useState } from 'react';
import { getFloorPlanSnapshotForReservationId } from '@components/floorPlan/apiHelpers';
import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { reportAppError } from '@shared/reportAppError';
import type { FloorPlanSnapshotData } from '@shared/types/floorPlans';
import { type TableHighlightingMode } from './FloorPlanTable';

interface SnapshotFloorPlanProps {
  disableControls?: boolean;
  reservationId?: string;
  tableHighlightingMode?: TableHighlightingMode;
}

export const SnapshotFloorPlan = ({
  disableControls = false,
  reservationId,
  tableHighlightingMode = 'outline',
}: SnapshotFloorPlanProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [floorPlanSnapshot, setFloorPlanSnapshot] =
    useState<FloorPlanSnapshotData>();

  useEffect(() => {
    if (!reservationId) return;
    const fetchFloorPlanSnapshot = async (): Promise<void> => {
      try {
        const response =
          await getFloorPlanSnapshotForReservationId(reservationId);
        setFloorPlanSnapshot(response);
      } catch (error) {
        reportAppError(error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchFloorPlanSnapshot();
  }, [reservationId]);

  if (isLoading && !floorPlanSnapshot) {
    return null;
  }

  const floorPlanTablesRenderer: FloorPlanTablesRenderer =
    floorPlanTablesRendererFactory({
      calculateIsHighlighted: (floorPlanTable) => floorPlanTable.isHighlighted,
      tables: floorPlanSnapshot?.floorPlanTables || [],
      tableHighlightingMode,
    });

  return (
    <FloorPlan
      backgroundSrc={floorPlanSnapshot?.backgroundSrc}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
      disableControls={disableControls}
    />
  );
};
