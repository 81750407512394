// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServerBadge__container___Ku9QA{background-color:var(--panelBlue);color:var(--white);text-align:center;text-transform:uppercase}.ServerBadge__container___Ku9QA.ServerBadge__card___N8tjA{border-radius:6px;padding:.25rem;width:40px}.ServerBadge__container___Ku9QA.ServerBadge__floorPlan___lOiEm{border-radius:4px;border:2px solid var(--primary400);padding:.125rem;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);height:20px;width:28px;z-index:var(--z_serverBadge)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/servers/ServerBadge.scss"],"names":[],"mappings":"AAAA,gCACE,iCAAA,CACA,kBAAA,CACA,iBAAA,CACA,wBAAA,CAEA,0DACE,iBAAA,CACA,cAAA,CACA,UAAA,CAGF,+DACE,iBAAA,CACA,kCAAA,CACA,eAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,WAAA,CACA,UAAA,CACA,4BAAA","sourcesContent":[".container {\n  background-color: var(--panelBlue);\n  color: var(--white);\n  text-align: center;\n  text-transform: uppercase;\n\n  &.card {\n    border-radius: 6px;\n    padding: 0.25rem;\n    width: 40px;\n  }\n\n  &.floorPlan {\n    border-radius: 4px;\n    border: 2px solid var(--primary400);\n    padding: 0.125rem;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    height: 20px;\n    width: 28px;\n    z-index: var(--z_serverBadge);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ServerBadge__container___Ku9QA",
	"card": "ServerBadge__card___N8tjA",
	"floorPlan": "ServerBadge__floorPlan___lOiEm"
};
export default ___CSS_LOADER_EXPORT___;
