// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DayNoteModal__container___vGGG6 form label{display:flex;flex-direction:column;gap:1rem}.DayNoteModal__container___vGGG6 form label span{left:-3000em;position:absolute;top:-2000em}.DayNoteModal__container___vGGG6 form label textarea{font-family:sans-serif;height:12rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/pageHeader/DayNoteModal.scss"],"names":[],"mappings":"AAEI,4CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,iDAEE,YAAA,CACA,iBAAA,CACA,WAAA,CAGF,qDACE,sBAAA,CACA,YAAA","sourcesContent":[".container {\n  form {\n    label {\n      display: flex;\n      flex-direction: column;\n      gap: 1rem;\n\n      span {\n        // for screen readers\n        left: -3000em;\n        position: absolute;\n        top: -2000em;\n      }\n\n      textarea {\n        font-family: sans-serif;\n        height: 12rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DayNoteModal__container___vGGG6"
};
export default ___CSS_LOADER_EXPORT___;
