import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';

interface DeleteShiftConfirmationModalProps {
  closeModal: () => void;
  warnings: string[] | null;
  handleOnConfirm: (ignoreWarnings: boolean) => void;
  ignoreWarnings: boolean;
  isOpen: boolean;
}

export const DeleteShiftConfirmationModal = ({
  closeModal,
  warnings,
  handleOnConfirm,
  ignoreWarnings,
  isOpen,
}: DeleteShiftConfirmationModalProps) => (
  <Modal isOpen={isOpen} onClose={closeModal} title="Delete Shift">
    {warnings && warnings.length > 0 && (
      <p
        className={typography.c2}
      >{`Warning: ${warnings.join(', ')}. Delete anyway?`}</p>
    )}
    <ModalActions>
      <Button
        label="Cancel"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Delete"
        onClick={() => handleOnConfirm(ignoreWarnings)}
        variant={ButtonVariants.Primary}
      />
    </ModalActions>
  </Modal>
);
