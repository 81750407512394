// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AssignServerModeFloorPlan__actions___uXzuY{display:flex;align-items:center;justify-content:space-between;margin-bottom:2rem}.AssignServerModeFloorPlan__instructions___rxLm2{background-color:var(--primary500);border-radius:100px;margin:0;padding:.5rem .75rem}.AssignServerModeFloorPlan__button___SatyP{background-color:rgba(0,0,0,0);padding:0;justify-content:center;align-items:center;border:none;display:inline-flex;cursor:pointer}.AssignServerModeFloorPlan__button___SatyP:disabled{cursor:not-allowed}.AssignServerModeFloorPlan__serviceStatusIcon___I82L4{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);z-index:var(--z_serviceStatusIcon)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/floorPlan/AssignServerModeFloorPlan.scss"],"names":[],"mappings":"AAAA,4CACE,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAGF,iDACE,kCAAA,CACA,mBAAA,CACA,QAAA,CACA,oBAAA,CAGF,2CACE,8BAAA,CACA,SAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,mBAAA,CACA,cAAA,CAEA,oDACE,kBAAA,CAIJ,sDACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,kCAAA","sourcesContent":[".actions {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 2rem;\n}\n\n.instructions {\n  background-color: var(--primary500);\n  border-radius: 100px;\n  margin: 0;\n  padding: 0.5rem 0.75rem;\n}\n\n.button {\n  background-color: transparent;\n  padding: 0;\n  justify-content: center;\n  align-items: center;\n  border: none;\n  display: inline-flex;\n  cursor: pointer;\n\n  &:disabled {\n    cursor: not-allowed;\n  }\n}\n\n.serviceStatusIcon {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: var(--z_serviceStatusIcon);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions": "AssignServerModeFloorPlan__actions___uXzuY",
	"instructions": "AssignServerModeFloorPlan__instructions___rxLm2",
	"button": "AssignServerModeFloorPlan__button___SatyP",
	"serviceStatusIcon": "AssignServerModeFloorPlan__serviceStatusIcon___I82L4"
};
export default ___CSS_LOADER_EXPORT___;
