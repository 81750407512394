import { useNavigate } from 'react-router-dom';
import { Kebab } from '@components/kebab/Kebab';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { useAuth } from 'restaurantAdmin/context/AuthContext';
import { SETTINGS_CHANGE_TEAM_MEMBER_ROLE_PATH } from 'restaurantAdmin/paths';
import { useRestaurant } from '../../context/useRestaurant';
import { type Admin, generateInvitationLink, resendInvite } from './apiHelpers';
import { RemoveAdminConfirmationModal } from './RemoveAdminConfirmationModal';

export interface TeamKebabProps {
  admin: Admin;
  refetchRestaurantAdmins: () => void;
}

export const TeamKebab = ({
  admin,
  refetchRestaurantAdmins,
}: TeamKebabProps) => {
  const { adminId: authenticatedAdminId } = useAuth();
  const restaurant = useRestaurant();
  const navigate = useNavigate();
  const { isOpen, open, close } = useIsOpen();

  const handleResendInvite = async () => {
    try {
      await resendInvite(restaurant.id, admin.email);

      successToast({ message: 'The invite was re-sent successfully' });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message:
            'An error occurred while resending the invite. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  const handleCopyInvitationLink = async () => {
    try {
      const { invitationLink } = await generateInvitationLink(
        restaurant.id,
        admin.email,
      );
      await navigator.clipboard.writeText(invitationLink);
      successToast({
        message:
          'The invitation link was copied to your clipboard! It expires in 24 hours.',
      });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message:
            'An error occurred while generating the invitation link. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  const navigateToChangeRolePage = () => {
    navigate(`${SETTINGS_CHANGE_TEAM_MEMBER_ROLE_PATH}/${admin.id}`);
  };

  const options = [
    {
      disabled: authenticatedAdminId === admin.id,
      label: 'Change Role',
      onClick: navigateToChangeRolePage,
    },
    {
      disabled: false,
      label: 'Remove from Restaurant',
      onClick: open,
    },
    {
      disabled: admin.status !== 'invited',
      label: 'Resend Invite',
      onClick: handleResendInvite,
    },
    {
      disabled: admin.status !== 'invited',
      label: 'Copy Invite Link',
      onClick: handleCopyInvitationLink,
    },
  ];

  return (
    <>
      <Kebab options={options} />
      <RemoveAdminConfirmationModal
        admin={admin}
        closeModal={close}
        isOpen={isOpen}
        refetchRestaurantAdmins={refetchRestaurantAdmins}
      />
    </>
  );
};
