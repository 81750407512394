// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableTimeButton__container___gOZd4{align-items:center;background-color:var(--darkBlue);border:1px solid var(--hairline);border-radius:var(--radius_small);color:var(--white);cursor:pointer;display:inline-flex;flex-direction:column;white-space:nowrap;width:100%;padding:.25rem}.AvailableTimeButton__container___gOZd4:hover{border:1px solid var(--darkGrey100)}.AvailableTimeButton__container___gOZd4:focus{border:1px dashed var(--darkGrey200)}.AvailableTimeButton__container___gOZd4 span{color:var(--gold)}", "",{"version":3,"sources":["webpack://./src/shared/availability/AvailableTimeButton.scss"],"names":[],"mappings":"AAAA,wCACE,kBAAA,CACA,gCAAA,CACA,gCAAA,CACA,iCAAA,CACA,kBAAA,CACA,cAAA,CACA,mBAAA,CACA,qBAAA,CACA,kBAAA,CACA,UAAA,CACA,cAAA,CAEA,8CACE,mCAAA,CAGF,8CACE,oCAAA,CAGF,6CACE,iBAAA","sourcesContent":[".container {\n  align-items: center;\n  background-color: var(--darkBlue);\n  border: 1px solid var(--hairline);\n  border-radius: var(--radius_small);\n  color: var(--white);\n  cursor: pointer;\n  display: inline-flex;\n  flex-direction: column;\n  white-space: nowrap;\n  width: 100%;\n  padding: 0.25rem;\n\n  &:hover {\n    border: 1px solid var(--darkGrey100);\n  }\n\n  &:focus {\n    border: 1px dashed var(--darkGrey200);\n  }\n\n  span {\n    color: var(--gold);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AvailableTimeButton__container___gOZd4"
};
export default ___CSS_LOADER_EXPORT___;
