// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "nav.AdminHeader__header___oWEJn{align-items:center;display:flex;height:var(--headerHeight);justify-content:space-between;column-gap:.5rem}.AdminHeader__logo___u83lK{margin-right:.5rem;min-width:1.5rem;stroke:var(--accent300);width:1.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/AdminHeader.scss"],"names":[],"mappings":"AAEA,gCACE,kBAAA,CACA,YAAA,CACA,0BAAA,CACA,6BAAA,CACA,gBAAA,CAGF,2BACE,kBAAA,CACA,gBAAA,CACA,uBAAA,CACA,YAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\nnav.header {\n  align-items: center;\n  display: flex;\n  height: var(--headerHeight);\n  justify-content: space-between;\n  column-gap: 0.5rem;\n}\n\n.logo {\n  margin-right: 0.5rem;\n  min-width: 1.5rem;\n  stroke: var(--accent300);\n  width: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "AdminHeader__header___oWEJn",
	"logo": "AdminHeader__logo___u83lK"
};
export default ___CSS_LOADER_EXPORT___;
