// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServerForm__container___VzOeB{padding:1.5rem}.ServerForm__header___o80kk{display:flex;align-items:center;justify-content:space-between}.ServerForm__closeButton___xW6k9{background-color:rgba(0,0,0,0);border:none;cursor:pointer}.ServerForm__closeButton___xW6k9 svg{stroke:var(--white);width:24px}.ServerForm__form___cnths{display:flex;flex-direction:column;gap:1.5rem;margin-top:1.5rem}.ServerForm__actions___QrvOE{display:flex;flex-direction:column;gap:.5rem;margin-top:1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/servers/ServerForm.scss"],"names":[],"mappings":"AAAA,+BACE,cAAA,CAGF,4BACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,iCACE,8BAAA,CACA,WAAA,CACA,cAAA,CAEA,qCACE,mBAAA,CACA,UAAA,CAIJ,0BACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,iBAAA,CAGF,6BACE,YAAA,CACA,qBAAA,CACA,SAAA,CACA,eAAA","sourcesContent":[".container {\n  padding: 1.5rem;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.closeButton {\n  background-color: transparent;\n  border: none;\n  cursor: pointer;\n\n  svg {\n    stroke: var(--white);\n    width: 24px;\n  }\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 1.5rem;\n  margin-top: 1.5rem;\n}\n\n.actions {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ServerForm__container___VzOeB",
	"header": "ServerForm__header___o80kk",
	"closeButton": "ServerForm__closeButton___xW6k9",
	"form": "ServerForm__form___cnths",
	"actions": "ServerForm__actions___QrvOE"
};
export default ___CSS_LOADER_EXPORT___;
