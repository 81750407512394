// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClearCalendarSelectionButton__clearCalendarSelectionButton___oUvZm{align-items:center;backdrop-filter:blur(10px);background:var(--greyPanel);border-radius:1.5rem;border:2px solid rgba(0,0,0,0);bottom:2rem;color:var(--white);cursor:pointer;display:flex;gap:.5rem;left:50%;padding:.75rem 1rem;position:absolute;transform:translate(-50%);white-space:nowrap}.ClearCalendarSelectionButton__clearCalendarSelectionButton___oUvZm:active,.ClearCalendarSelectionButton__clearCalendarSelectionButton___oUvZm:focus,.ClearCalendarSelectionButton__clearCalendarSelectionButton___oUvZm:hover{background:var(--panelBlue);border-color:var(--secondary300)}.ClearCalendarSelectionButton__clearCalendarSelectionButton___oUvZm svg{height:1.25rem;stroke:var(--white);width:1.25rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/calendar/ClearCalendarSelectionButton.scss"],"names":[],"mappings":"AAEA,oEACE,kBAAA,CACA,0BAAA,CACA,2BAAA,CACA,oBAAA,CACA,8BAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CACA,YAAA,CACA,SAAA,CACA,QAAA,CACA,mBAAA,CACA,iBAAA,CACA,yBAAA,CACA,kBAAA,CAEA,+NAGE,2BAAA,CACA,gCAAA,CAGF,wEACE,cAAA,CACA,mBAAA,CACA,aAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\n.clearCalendarSelectionButton {\n  align-items: center;\n  backdrop-filter: blur(10px);\n  background: var(--greyPanel);\n  border-radius: 1.5rem;\n  border: 2px solid transparent;\n  bottom: 2rem;\n  color: var(--white);\n  cursor: pointer;\n  display: flex;\n  gap: 0.5rem;\n  left: 50%;\n  padding: 0.75rem 1rem;\n  position: absolute;\n  transform: translate(-50%);\n  white-space: nowrap;\n\n  &:active,\n  &:focus,\n  &:hover {\n    background: var(--panelBlue);\n    border-color: var(--secondary300);\n  }\n\n  svg {\n    height: 1.25rem;\n    stroke: var(--white);\n    width: 1.25rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearCalendarSelectionButton": "ClearCalendarSelectionButton__clearCalendarSelectionButton___oUvZm"
};
export default ___CSS_LOADER_EXPORT___;
