import * as Sentry from '@sentry/react';
import type { AriaRole } from 'react';
import {
  type FloorPlanIconWithVariant,
  IconComponents,
} from './IconComponents';

export type AnyIcon = keyof typeof IconComponents | FloorPlanIconWithVariant;

const DEFAULT_STROKE_WIDTH = 1.5;

const reportMissingIconNameToSentry = (name: string) =>
  Sentry.captureException(
    new Error(`Cannot render Icon with missing name=${name}`),
  );

export interface IconProps {
  id?: string;
  ariaLabel?: string;
  className?: string;
  fill?: string;
  name: AnyIcon;
  role?: AriaRole;
  strokeWidth?: number;
  style?: React.CSSProperties;
  testId?: string;
  title?: string;
}

export const Icon = ({
  id,
  ariaLabel,
  className,
  fill = 'none',
  name,
  role = 'presentation',
  strokeWidth = DEFAULT_STROKE_WIDTH,
  style,
  testId,
  title,
}: IconProps) => {
  const IconComponent = IconComponents[name];
  const iconProps = {
    id,
    'aria-label': ariaLabel,
    className,
    'data-testid': testId,
    fill,
    role,
    strokeWidth,
    style,
    title,
  };

  if (!IconComponent) {
    reportMissingIconNameToSentry(name);
    return null;
  }

  return <IconComponent {...iconProps} />;
};
