import cx from 'classnames';
import { union } from 'lodash-es';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { interactiveFloorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { Icon } from '@components/icon/Icon';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { Select } from '@components/select/Select';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import {
  type FloorPlanData,
  type FloorPlanTableData,
} from '@shared/types/floorPlans';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import styles from './AddOrRemoveTablesModal.scss';
import {
  addOrRemoveTables,
  type ShiftWithHighlightedTableIds,
} from './apiHelpers';

interface AddOrRemoveTablesModalProps {
  isOpen: boolean;
  close: () => void;
  floorPlans: FloorPlanData[];
  shift: ShiftWithHighlightedTableIds;
  refetchShifts: () => void;
}

export const AddOrRemoveTablesModal = ({
  isOpen,
  close,
  floorPlans,
  shift,
  refetchShifts,
}: AddOrRemoveTablesModalProps) => {
  const { id: restaurantId } = useRestaurant();

  const [selectedFloorPlan, setSelectedFloorPlan] = useState(floorPlans[0]);
  const [highlightedTableIds, setHighlightedTableIds] = useState(
    shift.highlightedFloorPlanTableIds,
  );

  const handleFloorPlanTableOnClick = (floorPlanTable: FloorPlanTableData) => {
    // Add the table to highlightedTableIds if it's not already there
    if (!highlightedTableIds.some((id) => id === floorPlanTable.id)) {
      setHighlightedTableIds([...highlightedTableIds, floorPlanTable.id]);
    } else {
      // Otherwise, remove it
      setHighlightedTableIds(
        highlightedTableIds.filter((id) => id !== floorPlanTable.id),
      );
    }
  };

  const calculateIsHighlighted = (floorPlanTable: FloorPlanTableData) =>
    highlightedTableIds?.some(
      (highlightedTableId: string) => highlightedTableId === floorPlanTable.id,
    );

  const handleSave = async () => {
    try {
      await addOrRemoveTables(restaurantId, shift.id, highlightedTableIds);
      successToast({ message: 'Shift updated successfully!' });
      refetchShifts();
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: e.message });
      }
      reportAppError(e);
    } finally {
      close();
    }
  };

  const selectAll = () => {
    setHighlightedTableIds(
      union(
        highlightedTableIds,
        selectedFloorPlan.floorPlanTables.map((t) => t.id),
      ),
    );
  };

  const clearSelection = () => {
    setHighlightedTableIds(
      highlightedTableIds.filter(
        (id) => !selectedFloorPlan.floorPlanTables.some((t) => t.id === id),
      ),
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={close} ariaLabel="add or remove tables">
      <div className={styles.topBar}>
        <div>
          <span className={cx(typography.t1, styles.instructions)}>
            Select tables to add to shift
          </span>
          <button className={styles.selectAll} onClick={selectAll}>
            <span>Select All of These Tables</span>
          </button>
        </div>
        <button className={styles.clearSelection} onClick={clearSelection}>
          <Icon name="rotateCcw" ariaLabel="clear selection" />
          Clear selection
        </button>
      </div>
      <div className={styles.floorPlan}>
        {floorPlans.length > 1 && (
          <Select
            className={styles.floorPlanSelect}
            onChange={(_, value) =>
              value &&
              setSelectedFloorPlan(floorPlans.find((fp) => fp.id === value)!)
            }
            options={floorPlans.map((fp) => ({
              label: fp.name,
              value: fp.id,
            }))}
            value={selectedFloorPlan.id}
          />
        )}
        <FloorPlan
          backgroundSrc={selectedFloorPlan.backgroundSrc}
          floorPlanTablesRenderer={interactiveFloorPlanTablesRendererFactory({
            tables: selectedFloorPlan.floorPlanTables,
            calculateIsHighlighted,
            calculateIsSelectable: () => true,
            handleTableOnClick: handleFloorPlanTableOnClick,
          })}
        />
      </div>
      <ModalActions className={styles.modalActions}>
        <Button
          label="Cancel"
          onClick={close}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          label="Save"
          onClick={() => {
            void handleSave();
          }}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
