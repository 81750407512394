// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IconButton__button___g3Wpi{cursor:pointer;border:none;border-radius:50%;padding:4px;color:var(--white);background-color:var(--darkGrey300);display:flex;flex-direction:row;align-items:center;justify-content:center;column-gap:8px;font-size:14px;font-weight:600}.IconButton__button___g3Wpi svg{stroke:#fff;width:24px;height:24px}.IconButton__button___g3Wpi:active{background-color:var(--accent300)}.IconButton__button___g3Wpi:disabled{opacity:.5;cursor:not-allowed}", "",{"version":3,"sources":["webpack://./src/shared/components/iconButton/IconButton.scss"],"names":[],"mappings":"AAAA,4BACE,cAAA,CACA,WAAA,CACA,iBAAA,CACA,WAAA,CACA,kBAAA,CACA,mCAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,cAAA,CACA,eAAA,CAEA,gCACE,WAAA,CACA,UAAA,CACA,WAAA,CAGF,mCACE,iCAAA,CAGF,qCACE,UAAA,CACA,kBAAA","sourcesContent":[".button {\n  cursor: pointer;\n  border: none;\n  border-radius: 50%;\n  padding: 4px;\n  color: var(--white);\n  background-color: var(--darkGrey300);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  column-gap: 8px;\n  font-size: 14px;\n  font-weight: 600;\n\n  svg {\n    stroke: white;\n    width: 24px;\n    height: 24px;\n  }\n\n  &:active {\n    background-color: var(--accent300);\n  }\n\n  &:disabled {\n    opacity: 0.5;\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "IconButton__button___g3Wpi"
};
export default ___CSS_LOADER_EXPORT___;
