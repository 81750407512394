import { type Control, useWatch } from 'react-hook-form';
import { ControlledFormDateRange } from '@components/formInputs/ControlledFormDateRange';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormTimeRange } from '@components/formInputs/ControlledFormTimeRange';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import styles from './ShiftForm.scss';

export interface ShiftFormData {
  startDate: string;
  endDate: string | null;
  repeat: string[];
  name: string;
  startTime: string;
  endTime: string;
}

interface ShiftFormProps {
  control: Control<ShiftFormData>;
}

export const ShiftForm = ({ control }: ShiftFormProps) => {
  const startDateWatch = useWatch({ control, name: 'startDate' });
  const startTimeWatch = useWatch({ control, name: 'startTime' });

  return (
    <form className={styles.form}>
      <ControlledFormInput
        control={control}
        label="Name"
        name="name"
        rules={{
          required: true,
        }}
        type="text"
        variant={LayoutVariant.Vertical}
      />
      <ControlledFormDateRange
        control={control}
        fromProps={{
          label: 'Start Date',
          name: `startDate`,
          rules: { required: true },
        }}
        toProps={{
          label: 'End Date',
          name: `endDate`,
          rules: {
            min: {
              value: startDateWatch,
              message: 'End Date must be on or after the Start Date.',
            },
          },
        }}
        groupLabel="Start and End"
        variant={LayoutVariant.Vertical}
      />
      <ControlledFormTimeRange
        control={control}
        groupLabel="Time Frame"
        fromProps={{
          label: 'Start Time',
          name: `startTime`,
          rules: { required: true },
        }}
        toProps={{
          label: 'End Time',
          name: `endTime`,
          rules: {
            required: true,
            validate: {
              isGreaterThanOrEqualToStartTime: (
                endTime: string,
              ): string | boolean =>
                startTimeWatch && endTime >= startTimeWatch
                  ? true
                  : 'End Time must be on or after the Start Time.',
            },
          },
        }}
        variant={LayoutVariant.Vertical}
      />
      <ControlledFormDayRadio
        control={control}
        label="Repeats On"
        name="repeat"
        rules={{
          required: true,
        }}
      />
    </form>
  );
};
