import { useFeatureFlagContext } from '@shared/context/FeatureFlagContext';
import { useAuth } from 'restaurantAdmin/context/AuthContext';
import {
  GUEST_BOOK_ROOT_PATH,
  OPERATIONS_EVENTS_PATH,
  OPERATIONS_FLOOR_PLANS_PATH,
  OPERATIONS_LISTINGS_PATH,
  OPERATIONS_SHIFTS_PATH,
  RESERVATIONS_CONCIERGE_PATH,
  RESERVATIONS_OCCUPANTS_PATH,
  RESERVATIONS_SERVICE_PATH,
  RESERVATIONS_STRANDED_PATH,
  SETTINGS_FINANCES_PATH,
  SETTINGS_GENERAL_PATH,
  SETTINGS_TEAM_PATH,
} from '../paths';
import { NavigationMenuDropdown } from './NavigationMenuDropdown';
import { NavigationMenuLink } from './NavigationMenuLink';

export const PrimaryNavigationTabs = () => {
  const { isEnabled } = useFeatureFlagContext();
  const { adminRole } = useAuth();

  return (
    <>
      <NavigationMenuDropdown
        menuLabel="Reservations"
        icon="tent"
        menuItems={[
          {
            label: 'Service',
            path: RESERVATIONS_SERVICE_PATH,
          },
          {
            label: 'Concierge',
            path: RESERVATIONS_CONCIERGE_PATH,
          },
          {
            label: 'Stranded',
            path: RESERVATIONS_STRANDED_PATH,
          },
          {
            label: 'Occupants',
            path: RESERVATIONS_OCCUPANTS_PATH,
          },
        ]}
      />
      <NavigationMenuLink
        menuLabel="Guest Book"
        icon="couple"
        to={GUEST_BOOK_ROOT_PATH}
      />
      {adminRole === 'manager' && (
        <>
          <NavigationMenuDropdown
            menuLabel="Operations"
            icon="gear"
            menuItems={[
              {
                label: 'Listings',
                path: OPERATIONS_LISTINGS_PATH,
              },
              {
                label: 'Events',
                path: OPERATIONS_EVENTS_PATH,
              },
              {
                label: 'Floor Plans',
                path: OPERATIONS_FLOOR_PLANS_PATH,
              },
              ...(isEnabled('shiftsTimelinePageFlag')
                ? [
                    {
                      label: 'Shifts',
                      path: OPERATIONS_SHIFTS_PATH,
                    },
                  ]
                : []),
            ]}
          />
          <NavigationMenuDropdown
            menuLabel="Settings"
            icon="wrench"
            menuItems={[
              {
                label: 'General',
                path: SETTINGS_GENERAL_PATH,
              },
              {
                label: 'Team',
                path: SETTINGS_TEAM_PATH,
              },
              {
                label: 'Finances',
                path: SETTINGS_FINANCES_PATH,
              },
            ]}
          />
        </>
      )}
    </>
  );
};
