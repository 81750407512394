import { Icon } from '@components/icon/Icon';
import { type FloorPlanIconType } from '@components/icon/IconComponents';
import typography from '~styles/typography.scss';
import styles from './TableList.scss';

interface Table {
  id: string;
  iconName: FloorPlanIconType;
  name: string;
}

interface TableListProps {
  tables: Table[];
}

export const TableList = ({ tables }: TableListProps) => (
  <ul className={styles.tableList} data-testid="table-list">
    {tables.map((table) => (
      <li key={table.id}>
        <Icon name={table.iconName} />
        <div className={typography.t2}>{table.name}</div>
      </li>
    ))}
  </ul>
);
