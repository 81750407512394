// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NavigationMenuLink__menuLink___xvssr{align-items:center;background-color:rgba(0,0,0,0);border-radius:.5rem;border:2px solid rgba(0,0,0,0);color:var(--white70);display:flex;flex-direction:column;outline:none;padding:.25rem}.NavigationMenuLink__menuLink___xvssr>svg{height:20px;width:20px;stroke:var(--white70)}.NavigationMenuLink__menuLink___xvssr:active,.NavigationMenuLink__menuLink___xvssr:focus,.NavigationMenuLink__menuLink___xvssr:hover{border-color:var(--gold)}.NavigationMenuLink__activeMenuLink____TcTV{color:var(--gold)}.NavigationMenuLink__activeMenuLink____TcTV>svg{stroke:var(--gold)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/layout/NavigationMenuLink.scss"],"names":[],"mappings":"AAAA,sCACE,kBAAA,CACA,8BAAA,CACA,mBAAA,CACA,8BAAA,CACA,oBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,cAAA,CAEA,0CACE,WAAA,CACA,UAAA,CACA,qBAAA,CAGF,qIAGE,wBAAA,CAIJ,4CACE,iBAAA,CAEA,gDACE,kBAAA","sourcesContent":[".menuLink {\n  align-items: center;\n  background-color: transparent;\n  border-radius: 0.5rem;\n  border: 2px solid transparent;\n  color: var(--white70);\n  display: flex;\n  flex-direction: column;\n  outline: none;\n  padding: 0.25rem;\n\n  & > svg {\n    height: 20px;\n    width: 20px;\n    stroke: var(--white70);\n  }\n\n  &:active,\n  &:focus,\n  &:hover {\n    border-color: var(--gold);\n  }\n}\n\n.activeMenuLink {\n  color: var(--gold);\n\n  & > svg {\n    stroke: var(--gold);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuLink": "NavigationMenuLink__menuLink___xvssr",
	"activeMenuLink": "NavigationMenuLink__activeMenuLink____TcTV"
};
export default ___CSS_LOADER_EXPORT___;
