import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { updateShifts } from './apiHelpers';
import styles from './CreateShiftModal.scss';
import { ShiftForm, type ShiftFormData } from './ShiftForm';

export interface CreateShiftModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleOnCreateShift: (createdShiftIds: string[]) => void;
}

export const CreateShiftModal = ({
  isOpen,
  closeModal,
  handleOnCreateShift,
}: CreateShiftModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const { control, handleSubmit } = useForm<ShiftFormData>({
    defaultValues: {
      startDate: toISODateFormat(new Date()),
      repeat: [],
    },
  });

  const handleOnClickConfirm = handleSubmit(async (data: ShiftFormData) => {
    try {
      const { createdShiftIds } = await updateShifts(restaurantId, {
        ignoreWarnings: true,
        createdShifts: [
          {
            startDate: data.startDate,
            endDate: data.endDate || null,
            repeat: data.repeat,
            name: data.name,
            startTime: data.startTime,
            endTime: data.endTime,
          },
        ],
        updatedShifts: [],
        deletedShiftIds: [],
      });
      handleOnCreateShift(createdShiftIds);
      successToast({ message: 'Shifts successfully created' });
      closeModal();
    } catch (error) {
      errorToast({ message: 'Failed to create Shifts' });
      reportAppError(error);
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Create Shift"
      subtitle="Shifts determine when tables can be reserved."
    >
      <div className={styles.container}>
        <ShiftForm control={control} />
        <ModalActions>
          <Button
            type="button"
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            type="button"
            label="Create"
            onClick={handleOnClickConfirm}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </div>
    </Modal>
  );
};
