import cx from 'classnames';
import { parseToShortDateRange } from '@utils/dateFormatters';
import { ISOTimeTo12HourTime } from '@utils/time';
import { formatWeekDays } from '@utils/weekDayFormatters';
import typography from '~styles/typography.scss';
import { RestaurantSettingsSection } from '../shared/RestaurantSettingsSection';
import { ShiftsForm } from './ShiftsForm';
import styles from './ShiftsSetting.scss';

export interface ShiftsSettingProps {
  shifts: {
    id: string;
    startDate: string;
    endDate: string | null;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
  refreshRestaurant: () => Promise<void>;
  restaurantId: string;
}

export const ShiftsSetting = ({
  shifts,
  refreshRestaurant,
  restaurantId,
}: ShiftsSettingProps) => (
  <RestaurantSettingsSection
    heading="Shifts"
    isEditable
    renderForm={(exitEditMode) => (
      <ShiftsForm
        shifts={shifts}
        exitEditMode={exitEditMode}
        refreshRestaurant={refreshRestaurant}
        restaurantId={restaurantId}
      />
    )}
  >
    <p className={typography.t2}>
      Create shifts for your restaurant. This setting will help define the
      reservable days and times of your listings.
    </p>
    {shifts.length ? (
      <ul className={styles.list}>
        {shifts.map(
          ({ id, name, startDate, endDate, startTime, endTime, repeat }) => (
            <li key={id}>
              <span className={cx(typography.h5, styles.name)}>{name}</span>
              <span
                className={cx(typography.t1, styles.time)}
              >{`${ISOTimeTo12HourTime(startTime)}–${ISOTimeTo12HourTime(endTime)}`}</span>
              <span className={cx(typography.t1, styles.date)}>
                {parseToShortDateRange(startDate, endDate)}
              </span>
              <span className={cx(typography.t1, styles.days)}>
                {formatWeekDays(repeat)}
              </span>
            </li>
          ),
        )}
      </ul>
    ) : (
      <h3 className={typography.h6}>No shifts created</h3>
    )}
  </RestaurantSettingsSection>
);
