import cx from 'classnames';
import type { KeyboardEvent, MouseEvent } from 'react';
import type { AnyIcon } from '@components/icon/Icon';
import { Icon } from '@components/icon/Icon';
import { type FloorPlanIconType } from '@components/icon/IconComponents';
import { HUMAN_READABLE_ICON_NAMES } from '@shared/types/floorPlans';
import styles from './DisplayIconRadioButton.scss';

const FIRST_RADIO_BUTTON = 0;
const FOCUSABLE_TAB_INDEX_IN_PAGE_TAB_SEQUENCE = 0;
const FOCUSABLE_TAB_INDEX_OUTSIDE_PAGE_TAB_SEQUENCE = -1;
const STEP = 1;

export interface DisplayIconRadioButtonProps {
  displayIconIsInvalid: boolean;
  displayIconName: FloorPlanIconType | undefined;
  handleDisplayIconClick: (
    e: MouseEvent | KeyboardEvent,
    iconName: FloorPlanIconType,
  ) => void;
  highlightedFloorPlanIcons: FloorPlanIconType[];
  iconName: FloorPlanIconType;
  index: number;
}

export const DisplayIconRadioButton = ({
  displayIconIsInvalid,
  displayIconName,
  handleDisplayIconClick,
  highlightedFloorPlanIcons,
  iconName,
  index,
}: DisplayIconRadioButtonProps) => {
  const isSelectedDisplayIcon = iconName === displayIconName;

  const iconSuffix = isSelectedDisplayIcon ? 'Selected' : '';
  const statefulIconName = (
    isSelectedDisplayIcon ? `${iconName}${iconSuffix}` : iconName
  ) as AnyIcon;
  const friendlyIconSuffix = iconSuffix ? `, ${iconSuffix.toLowerCase()}` : '';
  const friendlyStatefulIconName = `${HUMAN_READABLE_ICON_NAMES[iconName]}${friendlyIconSuffix}`;

  const includeInPageTabSequence =
    isSelectedDisplayIcon || (!displayIconName && index === FIRST_RADIO_BUTTON);

  const handleKeyDown = (e: KeyboardEvent) => {
    const indexOfSelectedRadioButton = highlightedFloorPlanIcons.reduce(
      (acc: number, currentIcon: FloorPlanIconType, i: number) => {
        if (currentIcon === displayIconName) return i;
        return acc;
      },
      FIRST_RADIO_BUTTON,
    );
    const nextIndex =
      (indexOfSelectedRadioButton + STEP) % highlightedFloorPlanIcons.length;
    const previousIndex =
      indexOfSelectedRadioButton === FIRST_RADIO_BUTTON
        ? highlightedFloorPlanIcons.length - STEP
        : indexOfSelectedRadioButton - STEP;

    if (e.key === ' ') {
      handleDisplayIconClick(e, iconName);
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      handleDisplayIconClick(e, highlightedFloorPlanIcons[nextIndex]);
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
      handleDisplayIconClick(e, highlightedFloorPlanIcons[previousIndex]);
    }
  };

  return (
    <div
      aria-checked={isSelectedDisplayIcon}
      aria-label={friendlyStatefulIconName}
      className={cx({
        [styles.displayIconButton]: true,
        [styles.buttonSelected]: isSelectedDisplayIcon,
        [styles.buttonError]: displayIconIsInvalid,
      })}
      key={iconName}
      onClick={(e: MouseEvent) => handleDisplayIconClick(e, iconName)}
      onKeyDown={handleKeyDown}
      role="radio"
      tabIndex={
        includeInPageTabSequence
          ? FOCUSABLE_TAB_INDEX_IN_PAGE_TAB_SEQUENCE
          : FOCUSABLE_TAB_INDEX_OUTSIDE_PAGE_TAB_SEQUENCE
      }
      title={friendlyStatefulIconName}
    >
      <Icon name={statefulIconName} />
    </div>
  );
};
