import 'react-phone-number-input/style.css';

import cx from 'classnames';
import { Controller } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { ControlledFormInput } from '@components/formInputs/ControlledFormInput';
import { ControlledFormPhoneInput } from '@components/formInputs/ControlledFormPhoneInput';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { GuestCountPicker } from '@components/guestCountPicker/GuestCountPicker';
import { IconButton } from '@components/iconButton/IconButton';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { DESKTOP1280 } from '@shared/styles/breakpoints';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../../context/useRestaurant';
import { createWaitListEntry } from './apiHelpers';
import { useWaitListContext } from './state/WaitListContext';
import styles from './WaitListForm.scss';

export interface WaitListFormData {
  estimatedWait: string;
  firstName: string;
  guestCount: number;
  guestPhone: string;
  lastName: string;
}

export interface WaitListFormProps {
  className?: string;
  onClose: () => void;
}

export const WaitListForm = ({ className, onClose }: WaitListFormProps) => {
  const { fetchWaitListEntries, control, handleSubmit, reset } =
    useWaitListContext();
  const { id: restaurantId, maxReservationGuests } = useRestaurant();

  const onSubmit = async (data: WaitListFormData) => {
    try {
      await createWaitListEntry({
        estimatedWait: parseInt(data.estimatedWait, 10),
        firstName: data.firstName || null,
        guestCount: data.guestCount,
        lastName: data.lastName || null,
        phone: data.guestPhone,
        restaurantId,
      });
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: e.message });
      }
      reportAppError(e);
      return;
    }
    successToast({ message: 'Successfully added guest to wait list' });
    fetchWaitListEntries();
    handleOnClose();
  };

  const layoutVariant = useMediaQuery({ minWidth: DESKTOP1280 })
    ? LayoutVariant.Horizontal
    : LayoutVariant.Vertical;

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <Card bordered className={cx(styles.container, className)}>
      <div className={styles.header}>
        <h2 className={typography.h4}>Add to Waitlist</h2>
        <IconButton
          ariaLabel="close form"
          onClick={handleOnClose}
          iconName="close"
          className={styles.closeButton}
          iconClassName={styles.icon}
        />
      </div>
      <form
        aria-label="Wait List Form"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.inputContainer}>
          <ControlledFormPhoneInput
            className={styles.waitListInput}
            control={control}
            label="Guest Phone"
            name="guestPhone"
            rules={{ required: true }}
            variant={layoutVariant}
          />
          <ControlledFormInput
            className={styles.waitListInput}
            control={control}
            disabled={false}
            label="First Name (optional)"
            name="firstName"
            type="text"
            variant={layoutVariant}
          />
          <ControlledFormInput
            className={styles.waitListInput}
            control={control}
            disabled={false}
            label="Last Name (optional)"
            name="lastName"
            type="text"
            variant={layoutVariant}
          />
          <ControlledFormInput
            className={styles.waitListInput}
            control={control}
            disabled={false}
            label="Wait (in minutes)"
            name="estimatedWait"
            rules={{
              required: true,
              min: {
                value: 1,
                message: 'Wait time must be greater than 0',
              },
              max: {
                value: 599,
                message: 'Wait time must be less than 600',
              },
            }}
            type="number"
            variant={layoutVariant}
          />
        </div>
        <Controller
          control={control}
          name="guestCount"
          render={({ field: { onChange, value } }) => (
            <GuestCountPicker
              guestCount={value}
              max={maxReservationGuests}
              onChange={onChange}
            />
          )}
        />
        <div className={styles.actionContainer}>
          <Button
            label="Add to Waitlist"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <Button
            label="Cancel"
            onClick={handleOnClose}
            variant={ButtonVariants.Tertiary}
          />
        </div>
      </form>
    </Card>
  );
};
