import {
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from 'react-router-dom';
import { sentryCreateBrowserRouter } from '@utils/sentry';
import { PublishedListingsContextProvider } from './context/PublishedListingsContext';
import { ServicePageModalContextProvider } from './context/ServicePageModalContext';
import { CreateAccountPage } from './auth/CreateAccountPage';
import { ForgotPasswordPage } from './auth/ForgotPasswordPage';
import { InviteExpiredPage } from './auth/InviteExpiredPage';
import { IsAuthenticated } from './auth/IsAuthenticated';
import { IsManager } from './auth/IsManager';
import { LoginPage } from './auth/LoginPage';
import { ResetPasswordPage } from './auth/ResetPasswordPage';
import { ErrorHandler } from './errors/ErrorHandler';
import { NotFoundHero } from './errors/NotFoundHero';
import { GuestBookPage } from './guestBook/GuestBookPage';
import { Layout } from './layout/Layout';
import { RestaurantEventForm } from './operations/events/RestaurantEventForm';
import { RestaurantEventsPage } from './operations/events/RestaurantEventsPage';
import { FloorPlansPage } from './operations/floorPlans/FloorPlansPage';
import { CreateListingPage } from './operations/listings/create/CreateListingPage';
import { EditListingPage } from './operations/listings/edit/EditListingPage';
import { ListingsContextProvider } from './operations/listings/ListingsContext';
import { ListingsCalendarPage } from './operations/listings/listingsPage/ListingsCalendarPage';
import { ListingsFloorPlanPage } from './operations/listings/listingsPage/ListingsFloorPlanPage';
import { ShiftsPage } from './operations/shifts/ShiftsPage';
import {
  CREATE_ACCOUNT_PATH,
  FORGOT_PASSWORD_ROOT_PATH,
  GUEST_BOOK_ROOT_PATH,
  INVITE_EXPIRED_PATH,
  OPERATIONS_EVENTS_CREATE_TERMINAL_PATH,
  OPERATIONS_EVENTS_TERMINAL_PATH,
  OPERATIONS_EVENTS_UPDATE_TERMINAL_PATH,
  OPERATIONS_FLOOR_PLANS_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CALENDAR_DRAFT_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CALENDAR_INACTIVE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CALENDAR_TERMINAL_PATH,
  OPERATIONS_LISTINGS_CREATE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_EDIT_TERMINAL_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_TERMINAL_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_TERMINAL_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_TERMINAL_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_TERMINAL_PATH,
  OPERATIONS_LISTINGS_TERMINAL_PATH,
  OPERATIONS_ROOT_PATH,
  OPERATIONS_SHIFTS_TERMINAL_PATH,
  RESERVATIONS_CONCIERGE_TERMINAL_PATH,
  RESERVATIONS_COVERS_REPORT_PATH,
  RESERVATIONS_OCCUPANTS_TERMINAL_PATH,
  RESERVATIONS_RESERVATIONS_REPORT_PATH,
  RESERVATIONS_ROOT_PATH,
  RESERVATIONS_SERVICE_TERMINAL_PATH,
  RESERVATIONS_STRANDED_TERMINAL_PATH,
  RESET_PASSWORD_ROOT_PATH,
  SETTINGS_CHANGE_TEAM_MEMBER_ROLE_TERMINAL_PATH,
  SETTINGS_FINANCES_TERMINAL_PATH,
  SETTINGS_GENERAL_TERMINAL_PATH,
  SETTINGS_INVITE_MEMBER_TERMINAL_PATH,
  SETTINGS_ROOT_PATH,
  SETTINGS_TEAM_TERMINAL_PATH,
  SUPPORT_GUEST_IMPORT_TERMINAL_PATH,
  SUPPORT_ROOT_PATH,
} from './paths';
import { ConciergeAvailabilityContextProvider } from './reservations/concierge/ConciergeAvailabilityContext';
import { ConciergePage } from './reservations/concierge/ConciergePage';
import { AvailabilityDrawerContextProvider } from './reservations/concierge/state/AvailabilityDrawerContext';
import { OccupantsPage } from './reservations/occupants/OccupantsPage';
import { OccupantContextProvider } from './reservations/occupants/state/OccupantContext';
import { DailyCoversReportPage } from './reservations/report/covers/DailyCoversReportPage';
import { DailyReservationsReportPage } from './reservations/report/reservations/DailyReservationsReportPage';
import { ServicePage } from './reservations/service/ServicePage';
import { ServerFormContextProvider } from './reservations/service/sidePanel/servers/ServerFormContext';
import { ServersContextProvider } from './reservations/service/sidePanel/servers/ServersContext';
import { WaitListContextProvider } from './reservations/service/sidePanel/waitList/state/WaitListContext';
import { ReservationServiceContextProvider } from './reservations/service/state/ReservationServiceContext';
import { WalkInFormContextProvider } from './reservations/service/walkIns/WalkInFormContext';
import { SplitMergeTablesProvider } from './reservations/splitMergeTables/state/SplitMergeTablesContext';
import { StrandedPage } from './reservations/stranded/StrandedPage';
import { Root } from './Root';
import { FinancesPage } from './settings/finances/FinancesPage';
import { GeneralPage } from './settings/general/GeneralPage';
import { ChangeTeamMemberRolePage } from './settings/team/ChangeTeamMemberRolePage';
import { InviteTeamMemberPage } from './settings/team/InviteTeamMemberPage';
import { TeamPage } from './settings/team/TeamPage';
import { GuestImport } from './support/GuestImport';

const servicePageWithContexts = (
  <ServicePageModalContextProvider>
    <ReservationServiceContextProvider>
      <SplitMergeTablesProvider>
        <WaitListContextProvider>
          <PublishedListingsContextProvider>
            <ServersContextProvider>
              <ServerFormContextProvider>
                <WalkInFormContextProvider>
                  <ServicePage />
                </WalkInFormContextProvider>
              </ServerFormContextProvider>
            </ServersContextProvider>
          </PublishedListingsContextProvider>
        </WaitListContextProvider>
      </SplitMergeTablesProvider>
    </ReservationServiceContextProvider>
  </ServicePageModalContextProvider>
);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<Root />} errorElement={<ErrorHandler />}>
      <Route element={<LoginPage />} index />
      <Route
        element={<ForgotPasswordPage />}
        path={FORGOT_PASSWORD_ROOT_PATH}
      />
      <Route element={<ResetPasswordPage />} path={RESET_PASSWORD_ROOT_PATH} />
      <Route element={<CreateAccountPage />} path={CREATE_ACCOUNT_PATH} />
      <Route element={<InviteExpiredPage />} path={INVITE_EXPIRED_PATH} />
      <Route element={<IsAuthenticated />}>
        <Route element={<Layout />}>
          <Route errorElement={<ErrorHandler />}>
            <Route path={`${RESERVATIONS_ROOT_PATH}/*`}>
              <Route element={servicePageWithContexts} index />
              <Route
                element={servicePageWithContexts}
                path={RESERVATIONS_SERVICE_TERMINAL_PATH}
              />
              <Route
                element={
                  <ConciergeAvailabilityContextProvider>
                    <AvailabilityDrawerContextProvider>
                      <ConciergePage />
                    </AvailabilityDrawerContextProvider>
                  </ConciergeAvailabilityContextProvider>
                }
                path={RESERVATIONS_CONCIERGE_TERMINAL_PATH}
              />
              <Route
                element={<StrandedPage />}
                path={RESERVATIONS_STRANDED_TERMINAL_PATH}
              />
              <Route
                element={
                  <OccupantContextProvider>
                    <OccupantsPage />
                  </OccupantContextProvider>
                }
                path={RESERVATIONS_OCCUPANTS_TERMINAL_PATH}
              />
            </Route>
            <Route element={<GuestBookPage />} path={GUEST_BOOK_ROOT_PATH} />
            <Route element={<IsManager />}>
              <Route path={`${OPERATIONS_ROOT_PATH}/*`}>
                <Route
                  element={
                    <ListingsContextProvider>
                      <Outlet />
                    </ListingsContextProvider>
                  }
                  path={OPERATIONS_LISTINGS_TERMINAL_PATH}
                >
                  <Route
                    element={
                      <Navigate
                        to={OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH}
                      />
                    }
                    index
                  />
                  <Route path={OPERATIONS_LISTINGS_FLOOR_PLAN_TERMINAL_PATH}>
                    <Route
                      element={
                        <Navigate
                          to={OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH}
                        />
                      }
                      index
                    />
                    <Route
                      element={<ListingsFloorPlanPage />}
                      path={
                        OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_TERMINAL_PATH
                      }
                    />
                    <Route
                      element={<ListingsFloorPlanPage />}
                      path={OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_TERMINAL_PATH}
                    />
                    <Route
                      element={<ListingsFloorPlanPage />}
                      path={
                        OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_TERMINAL_PATH
                      }
                    />
                  </Route>
                  <Route path={OPERATIONS_LISTINGS_CALENDAR_TERMINAL_PATH}>
                    <Route
                      element={
                        <Navigate
                          to={OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_PATH}
                        />
                      }
                      index
                    />
                    <Route
                      element={<ListingsCalendarPage />}
                      path={
                        OPERATIONS_LISTINGS_CALENDAR_PUBLISHED_TERMINAL_PATH
                      }
                    />
                    <Route
                      element={<ListingsCalendarPage />}
                      path={OPERATIONS_LISTINGS_CALENDAR_DRAFT_TERMINAL_PATH}
                    />
                    <Route
                      element={<ListingsCalendarPage />}
                      path={OPERATIONS_LISTINGS_CALENDAR_INACTIVE_TERMINAL_PATH}
                    />
                  </Route>
                  <Route
                    element={<CreateListingPage />}
                    path={OPERATIONS_LISTINGS_CREATE_TERMINAL_PATH}
                  />
                  <Route
                    element={<EditListingPage />}
                    path={`${OPERATIONS_LISTINGS_EDIT_TERMINAL_PATH}/:listingId`}
                  />
                </Route>
                <Route path={OPERATIONS_EVENTS_TERMINAL_PATH}>
                  <Route element={<RestaurantEventsPage />} index />
                  <Route
                    element={<RestaurantEventForm />}
                    path={OPERATIONS_EVENTS_CREATE_TERMINAL_PATH}
                  />
                  <Route
                    element={<RestaurantEventForm />}
                    path={OPERATIONS_EVENTS_UPDATE_TERMINAL_PATH}
                  />
                </Route>
                <Route
                  path={OPERATIONS_FLOOR_PLANS_TERMINAL_PATH}
                  index
                  element={<FloorPlansPage />}
                />
                <Route
                  path={OPERATIONS_SHIFTS_TERMINAL_PATH}
                  index
                  element={<ShiftsPage />}
                />
              </Route>
              <Route path={`${SETTINGS_ROOT_PATH}/*`}>
                <Route element={<GeneralPage />} index />
                <Route
                  element={<GeneralPage />}
                  path={SETTINGS_GENERAL_TERMINAL_PATH}
                />
                <Route
                  element={<TeamPage />}
                  path={SETTINGS_TEAM_TERMINAL_PATH}
                />
                <Route
                  element={<ChangeTeamMemberRolePage />}
                  path={`${SETTINGS_CHANGE_TEAM_MEMBER_ROLE_TERMINAL_PATH}/:adminId`}
                />
                <Route
                  element={<InviteTeamMemberPage />}
                  path={SETTINGS_INVITE_MEMBER_TERMINAL_PATH}
                />
                <Route
                  element={<FinancesPage />}
                  path={SETTINGS_FINANCES_TERMINAL_PATH}
                />
              </Route>
              <Route path={`${SUPPORT_ROOT_PATH}/*`}>
                <Route
                  element={<GuestImport />}
                  path={SUPPORT_GUEST_IMPORT_TERMINAL_PATH}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<NotFoundHero />} path="*" />
        </Route>
        <Route
          element={<DailyReservationsReportPage />}
          path={RESERVATIONS_RESERVATIONS_REPORT_PATH}
        />
        <Route
          element={<DailyCoversReportPage />}
          path={RESERVATIONS_COVERS_REPORT_PATH}
        />
      </Route>
    </Route>,
  ),
);
