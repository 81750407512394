import {
  addWeeks,
  hoursToMinutes,
  parseISO,
  startOfWeek,
  subDays,
} from 'date-fns';
import { compact, intersection, max, min } from 'lodash-es';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { parseDateToWeekDay } from '@shared/utils/weekDayFormatters';
import {
  ISOTimeAddMinutes,
  ISOTimeSaturatingAddMinutes,
  ISOTimeToMinuteOfDay,
} from '@utils/time';
import { type Shift } from 'restaurantAdmin/operations/shifts/apiHelpers';
import {
  OPERATIONS_LISTINGS_CALENDAR_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PATH,
} from '../../../paths';
import { type ListingWithShifts } from '../apiHelpers';
import {
  hasOverlappingDay,
  mergeOverlappingTimeRanges,
  type TimeRange,
  timeRangeOverlaps,
} from './timeRange';

const MINUTES_PER_DAY = hoursToMinutes(24);

/**
 * Applies the supplied mapper function to a range of integers
 * from 0 to N - 1 and returns the result as an array of length N.
 *
 * @param n - The number of elements to map.
 * @param mapper - The function that maps each element.
 * @returns An array of type `T` with `n` elements.
 *
 * @example
 * mapN(5, (n) => n * 2); // [0, 2, 4, 6, 8]
 */
export const mapN = <T>(n: number, mapper: (n: number) => T): T[] =>
  Array.from({ length: n }, (_, i) => mapper(i));

export const mapHalfHourIncrements = <T>(mapper: (n: number) => T): T[] =>
  mapN(24 * 2, (i) => mapper(i));

/**
 * Converts a boolean value indicating whether a seating arrangement
 * is communal to a corresponding seating type string.
 * @param isCommunal - A boolean value indicating whether the
 * seating arrangement is communal.
 * @returns The seating type string, either 'Communal' or 'Table'.
 */
export const seatingTypeFromIsCommunal = (isCommunal: boolean) =>
  isCommunal ? 'Communal' : 'Table';

/** Returns true if the listing has availability on the given date */
export const shiftIsOnDate = (shift: Shift, date: string) =>
  shift.startDate <= date &&
  (!shift.endDate || shift.endDate >= date) &&
  shift.repeat.includes(parseDateToWeekDay(date).toString());

/** Returns true if the turn time goes past midnight */
export const shiftHasRollover = (shift: Shift, turnTime: number) =>
  ISOTimeToMinuteOfDay(shift.endTime) + turnTime > MINUTES_PER_DAY;

/**
 * Calculates a list of time ranges which the given listing occupies for a given
 * date. There may be up to two time ranges since there may be overflow from the
 * previous day. Turn time is included in the time ranges.
 */
export const listingTimeRangesOnDate = (
  listing: ListingWithShifts,
  date: string,
): TimeRange[] => {
  const { turnTime, shifts } = listing;

  return shifts.reduce<TimeRange[]>((ranges, shift) => {
    const shiftHasRolloverFromPreviousDay =
      shiftHasRollover(shift, turnTime) &&
      shiftIsOnDate(shift, toISODateFormat(subDays(parseISO(date), 1)));

    if (shiftHasRolloverFromPreviousDay) {
      ranges.push({
        startTime: '00:00:00',
        endTime: ISOTimeAddMinutes(shift.endTime, turnTime),
      });
    }
    if (shiftIsOnDate(shift, date)) {
      ranges.push({
        startTime: shift.startTime,
        endTime: ISOTimeSaturatingAddMinutes(shift.endTime, turnTime),
      });
    }

    return mergeOverlappingTimeRanges(ranges);
  }, []);
};

export const hasOverlappingShift = (
  listingA: ListingWithShifts,
  listingB: ListingWithShifts,
): boolean =>
  listingA.shifts.some((shiftA) =>
    listingB.shifts.some(
      (shiftB) =>
        hasTableHighlightOverlap(listingA, listingB) &&
        hasDateOverlap(shiftA, shiftB) &&
        hasTimeOverlapWithRollover(
          shiftA,
          listingA.turnTime,
          shiftB,
          listingB.turnTime,
        ),
    ),
  );

export const hasTableHighlightOverlap = (
  listingA: ListingWithShifts,
  listingB: ListingWithShifts,
) =>
  intersection(
    listingA.highlightedFloorPlanTableIds,
    listingB.highlightedFloorPlanTableIds,
  ).length > 0;

export const hasDateOverlap = (shiftA: Shift, shiftB: Shift) => {
  const startDate = max([shiftA.startDate, shiftB.startDate])!;
  const endDate = min(compact([shiftA.endDate, shiftB.endDate]));

  if (!endDate) return true;

  return endDate >= startDate;
};

export const hasTimeOverlapWithRollover = (
  shiftA: Shift,
  turnTimeA: number,
  shiftB: Shift,
  turnTimeB: number,
) => {
  if (hasOverlappingDay(shiftA.repeat, shiftB.repeat)) {
    const timeRangeA = {
      startTime: shiftA.startTime,
      endTime: ISOTimeSaturatingAddMinutes(shiftA.endTime, turnTimeA),
    };
    const timeRangeB = {
      startTime: shiftB.startTime,
      endTime: ISOTimeSaturatingAddMinutes(shiftB.endTime, turnTimeB),
    };
    return timeRangeOverlaps(timeRangeA, timeRangeB);
  }
  if (
    shiftHasRollover(shiftA, turnTimeA) &&
    hasAdjacentRepeat(shiftA, shiftB)
  ) {
    return timeRangeOverlaps(
      {
        startTime: '00:00:00',
        endTime: ISOTimeAddMinutes(shiftB.endTime, turnTimeB),
      },
      shiftB,
    );
  }
  if (
    shiftHasRollover(shiftB, turnTimeB) &&
    hasAdjacentRepeat(shiftB, shiftA)
  ) {
    return timeRangeOverlaps(
      {
        startTime: '00:00:00',
        endTime: ISOTimeAddMinutes(shiftA.endTime, turnTimeA),
      },
      shiftA,
    );
  }

  return false;
};

export const hasAdjacentRepeat = (shiftA: Shift, shiftB: Shift) =>
  intersection(
    shiftA.repeat.map((r) => String(Number(r) + 1)),
    shiftB.repeat,
  ).length > 0;

/**
 * Returns true if a given listing overlaps with a given time block on a calendar
 * It will account for turn time after the end time as well as the rollover to the next day
 *
 * @param startTime - start time of the range
 * @param endTime - end time of the range
 * @param date - date string in ISO format
 * @returns a filter function that takes in listing as a parameter
 */
export const isOverlappingInRange =
  (startTime: string, endTime: string, date: string) =>
  (listing: ListingWithShifts) =>
    listingTimeRangesOnDate(listing, date).some((timeRange) =>
      timeRangeOverlaps(timeRange, { startTime, endTime }),
    );

export const getListingsForWeekByDate = (
  listings: ListingWithShifts[],
  selectedDate: string,
): ListingWithShifts[] => {
  const weekStart = startOfWeek(parseISO(selectedDate));
  const weekEnd = addWeeks(weekStart, 1);

  return listings
    .map((listing) => ({
      ...listing,
      shifts: listing.shifts.filter(
        (shift) =>
          parseISO(shift.startDate) < weekEnd &&
          (!shift.endDate || parseISO(shift.endDate) >= weekStart),
      ),
    }))
    .filter((listing) => listing.shifts.length);
};

export const getDestinationPath = (
  referrer: string,
  tab: 'published' | 'draft' | 'inactive',
) => {
  const isFromFloorPlan = referrer.includes('floor-plan');

  return `${isFromFloorPlan ? OPERATIONS_LISTINGS_FLOOR_PLAN_PATH : OPERATIONS_LISTINGS_CALENDAR_PATH}/${tab}`;
};
