import cx from 'classnames';
import { useWatch } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import FloorPlanTable from '@components/floorPlan/FloorPlanTable';
import { type HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import typography from '~styles/typography.scss';
import { ServiceStatusIcon } from '../serviceStatus/ServiceStatusIcon';
import { ServerBadge } from '../sidePanel/servers/ServerBadge';
import { useServerFormContext } from '../sidePanel/servers/ServerFormContext';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import styles from './AssignServerModeFloorPlan.scss';

interface AssignServerModeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  tables: HostFloorPlanTable[];
}

export const AssignServerModeFloorPlan = ({
  backgroundSrc,
  className,
  tables,
}: AssignServerModeFloorPlanProps) => {
  const { shouldShowServiceStatuses } = useReservationServiceContext();
  const {
    form: { control, setValue },
  } = useServerFormContext();

  const formFloorPlanTableIds = useWatch({
    control,
    name: 'floorPlanTableIds',
  });

  const formServerName = useWatch({
    control,
    name: 'name',
  });

  const formServerColor = useWatch({
    control,
    name: 'hexColor',
  });

  const formServerId = useWatch({
    control,
    name: 'id',
  });

  const clearSelectedFloorPlanTables = (): void => {
    setValue('floorPlanTableIds', []);
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) =>
    tables.map((floorPlanTable) => {
      const {
        id,
        iconName,
        iconWidthScale,
        left,
        name,
        orientation,
        top,
        assignedServer,
        seatedOccupant,
      } = floorPlanTable;

      const isSeated = seatedOccupant !== null;
      const isSelected = formFloorPlanTableIds.includes(id);

      const toggleTableSelection = (): void => {
        if (isSelected) {
          setValue(
            'floorPlanTableIds',
            formFloorPlanTableIds.filter((tableId: string) => tableId !== id),
          );
        } else {
          setValue('floorPlanTableIds', [...formFloorPlanTableIds, id]);
        }
      };

      return (
        <FloorPlanTable
          key={id}
          left={left}
          top={top}
          tableIconScale={tableIconScale}
        >
          <button
            aria-checked={isSelected}
            aria-label={isSelected ? `Deselect ${name}` : `Select ${name}`}
            className={styles.button}
            data-tooltip-id={name}
            name={name}
            onClick={toggleTableSelection}
            role="checkbox"
            type="button"
          >
            {isSeated && shouldShowServiceStatuses && (
              <ServiceStatusIcon
                serviceStatus={seatedOccupant.serviceStatus}
                className={styles.serviceStatusIcon}
                scale={tableIconScale * Number(iconWidthScale)}
              />
            )}
            {assignedServer && assignedServer.id !== formServerId && (
              <ServerBadge
                displayMode="floorPlan"
                serverName={assignedServer.name}
                badgeColor={assignedServer.hexColor}
              />
            )}
            {isSelected && (
              <ServerBadge
                displayMode="floorPlan"
                serverName={formServerName}
                badgeColor={formServerColor}
              />
            )}
            <FloorPlanTable.Icon
              iconName={iconName}
              iconWidthScale={iconWidthScale}
              orientation={orientation}
              tableIconScale={tableIconScale}
            />
          </button>
        </FloorPlanTable>
      );
    });

  return (
    <div data-testid="assign-server-floor-plan">
      <div className={styles.actions}>
        <p className={cx(typography.t1, styles.instructions)}>
          Select tables for this server.
        </p>
        <Button
          variant={ButtonVariants.Tertiary}
          label="Clear Assignment"
          onClick={clearSelectedFloorPlanTables}
        />
      </div>
      <FloorPlan
        backgroundSrc={backgroundSrc}
        className={className}
        floorPlanTablesRenderer={floorPlanTablesRenderer}
      />
    </div>
  );
};
