// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoTables__container___KzM60{text-align:center;color:var(--white);margin-top:2.5rem;margin-bottom:3rem;align-self:center}.NoTables__noTablesHeader___nVhtX{font-family:\"PP Woodland\",sans-serif}.NoTables__noTablesMessage___BtHkr{font-size:1rem;color:var(--secondary200);line-height:1.5rem;letter-spacing:.03em}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/concierge/NoTables.scss"],"names":[],"mappings":"AAAA,6BACE,iBAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,kCACE,oCAAA,CAGF,mCACE,cAAA,CACA,yBAAA,CACA,kBAAA,CACA,oBAAA","sourcesContent":[".container {\n  text-align: center;\n  color: var(--white);\n  margin-top: 2.5rem;\n  margin-bottom: 3rem;\n  align-self: center;\n}\n\n.noTablesHeader {\n  font-family: 'PP Woodland', sans-serif;\n}\n\n.noTablesMessage {\n  font-size: 1rem;\n  color: var(--secondary200);\n  line-height: 1.5rem;\n  letter-spacing: 0.03em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NoTables__container___KzM60",
	"noTablesHeader": "NoTables__noTablesHeader___nVhtX",
	"noTablesMessage": "NoTables__noTablesMessage___BtHkr"
};
export default ___CSS_LOADER_EXPORT___;
