import { tz, TZDate } from '@date-fns/tz';
import { format, formatISO, isSameDay, isToday, parseISO } from 'date-fns';

/**
 * @example '2022-04-10'
 */
export const toISODateFormat = (date: Date): string =>
  formatISO(date, { representation: 'date' });

/**
 * Converts an ISO timestamp to a short date string (e.g. "Fri, Jan 26").
 * Returns "Today" if the given timestamp is today. A timezone may optionally
 * be provided for determining today.
 */
export const parseToDayMonthFormat = (
  isoDate: string,
  timezone?: string,
): string => {
  const date = parseISO(isoDate, { in: timezone ? tz(timezone) : undefined });
  const now = timezone ? TZDate.tz(timezone) : new Date();

  if (isSameDay(date, now, { in: timezone ? tz(timezone) : undefined })) {
    return 'Today';
  }
  return format(date, 'EEE, MMM d');
};

/**
 * @param date Date object – e.g., parseISO('2024-10-25')
 * @returns 'Fri'
 */
export const toShortDayFormat = (date: Date): string => format(date, 'EEE');

export const todayInTimezone = (timezone: string): string =>
  toISODateFormat(TZDate.tz(timezone));

/**
 * @example 'January 1, 2012'
 */
export const toMonthDateAndYear = (date: Date): string =>
  format(date, 'LLLL d, yyyy');

/**
 * @param date ISO date string – e.g., '2012-01-01T12:00:00'
 *
 * @example 'Jan 1, 2012'
 * @example 'Today'
 */
export const parseToShortDateFormat = (
  date: string,
  formatToday = true,
): string => {
  const parsedDate = parseISO(date);
  return toShortDateFormat(parsedDate, formatToday);
};

/**
 * @param date Date object – e.g., new Date()
 *
 * @example 'Jan 1, 2012'
 * @example 'Today'
 */
export const toShortDateFormat = (date: Date, formatToday = true): string => {
  if (formatToday) return isToday(date) ? 'Today' : format(date, 'MMM d, yyyy');

  return format(date, 'MMM d, yyyy');
};

/**
 * @param startDate e.g., '2012-01-01'
 * @param endDate e.g., '2012-01-02' or null
 *
 * @example 'Jan 1, 2012 – Jan 2, 2012'
 * @example 'Jan 1, 2012 – Endless'
 */
export const parseToShortDateRange = (
  startDate: string,
  endDate: string | null,
): string =>
  `${parseToShortDateFormat(startDate)} – ${endDate ? parseToShortDateFormat(endDate) : 'Endless'}`;

/**
 * @example 'Jan 1, 2012, 2:00 PM'
 */
export const toShortDateTime = (date: Date): string => format(date, 'PPp');

/**
 * @example 'Sun, Jan 1'
 * @example 'Today'
 */
export const toShortWeekDateFormat = (date: Date): string =>
  isToday(date) ? 'Today' : format(date, 'EEE, MMM d');

/**
 * @example 'Sun, Jan 1, 2025'
 * @example 'Today'
 */
export const toShortWeekdayMonthDateAndYearFormat = (date: Date): string =>
  isToday(date) ? 'Today' : format(date, 'EEE, MMM d, yyyy');

/**
 * @example 'Sun, January 1, 2012'
 */
export const toWeekdayMonthDateAndYear = (date: Date): string =>
  format(date, 'EEE, LLLL d, yyyy');
