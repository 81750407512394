import { api } from 'restaurantAdmin/api';

interface PacingControls {
  pacingLimit: number;
  pacingWindow: number;
}

export interface RestaurantMetadata {
  id: string;
  name: string;
  postalCode: string;
  timezone: string;
  maxReservationGuests: number;
  coverLimit: number | null;
  pacingControls: PacingControls | null;
  locationSlug: string;
  nameSlug: string;
}

export type GuestTagCategory =
  | 'allergies'
  | 'custom'
  | 'dietary restrictions'
  | 'guest attributes'
  | 'other';

export interface GuestTag {
  category: GuestTagCategory;
  id: string;
  title: string;
  createdBy: string;
}

export const getAdminRestaurants = async (
  signal?: AbortSignal,
): Promise<RestaurantMetadata[]> => api.get('/admin/restaurants', { signal });

export const getRestaurantTags = async (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<GuestTag[]> =>
  api.get(`/restaurants/${restaurantId}/tags`, { signal });
