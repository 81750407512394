/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createContext, type ReactNode, useMemo, useState } from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { useOverlay } from '@shared/hooks/useOverlay';
import { SeatOutsideListingConfirmationModal } from 'restaurantAdmin/reservations/service/SeatOutsideListingConfirmationModal';
import { WalkInSeatingConflictModal } from 'restaurantAdmin/reservations/service/walkIns/WalkInSeatingConflictModal';
import { WalkInTurnTimeInformationModal } from 'restaurantAdmin/reservations/service/walkIns/WalkInTurnTimeInformationModal';

export interface ServicePageModalContext {
  closeModal: () => void;
  currentModal: ServicePageModalType | null;
  openModal: (modalType: ServicePageModalType, props?: object) => void;
}

export const ServicePageModalContext =
  createContext<ServicePageModalContext | null>(null);
ServicePageModalContext.displayName = 'Modal (Admin)';

export const useServicePageModalContext = () =>
  useDefinedContext(ServicePageModalContext);

export enum ServicePageModalType {
  SeatOutsideListingConfirmation = 'seatOutsideListingConfirmation',
  WalkInSeatingConflict = 'walkInSeatingConflict',
  WalkInTurnTimeInformation = 'walkInTurnTimeInformation',
}

export const ServicePageModalContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [currentModal, setCurrentModal] = useState<ServicePageModalType | null>(
    null,
  );
  const [modalProps, setModalProps] = useState<any>({});

  useOverlay(!!currentModal);

  const closeModal = () => setCurrentModal(null);
  const openModal = (modalType: ServicePageModalType, props: object = {}) => {
    setModalProps(props);
    setCurrentModal(modalType);
  };

  const value = useMemo<ServicePageModalContext>(
    () => ({
      currentModal,
      closeModal,
      openModal,
    }),
    [currentModal],
  );

  if (!currentModal)
    return (
      <ServicePageModalContext.Provider value={value}>
        {children}
      </ServicePageModalContext.Provider>
    );

  return (
    <ServicePageModalContext.Provider value={value}>
      {children}
      <WalkInSeatingConflictModal
        closeModal={closeModal}
        isOpen={currentModal === ServicePageModalType.WalkInSeatingConflict}
        {...modalProps}
      />
      <WalkInTurnTimeInformationModal
        closeModal={closeModal}
        isOpen={currentModal === ServicePageModalType.WalkInTurnTimeInformation}
        {...modalProps}
      />
      <SeatOutsideListingConfirmationModal
        closeModal={closeModal}
        isOpen={
          currentModal === ServicePageModalType.SeatOutsideListingConfirmation
        }
        {...modalProps}
      />
    </ServicePageModalContext.Provider>
  );
};
