// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BasePriceFieldSet__container___NwGWa{border-radius:8px;display:flex;flex-direction:column;row-gap:1rem;padding:12px;width:100%;background-color:var(--panelBlue)}.BasePriceFieldSet__container___NwGWa p{color:var(--white70);margin:0}.BasePriceFieldSet__container___NwGWa .BasePriceFieldSet__actions___qifmK{display:flex;gap:.5rem}.BasePriceFieldSet__container___NwGWa .BasePriceFieldSet__actions___qifmK button{flex:1 1 0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/BasePriceFieldSet.scss"],"names":[],"mappings":"AAAA,sCACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,YAAA,CACA,YAAA,CACA,UAAA,CACA,iCAAA,CAEA,wCACE,oBAAA,CACA,QAAA,CAGF,0EACE,YAAA,CACA,SAAA,CAEA,iFACE,UAAA","sourcesContent":[".container {\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 1rem;\n  padding: 12px;\n  width: 100%;\n  background-color: var(--panelBlue);\n\n  p {\n    color: var(--white70);\n    margin: 0;\n  }\n\n  .actions {\n    display: flex;\n    gap: 0.5rem;\n\n    button {\n      flex: 1 1 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BasePriceFieldSet__container___NwGWa",
	"actions": "BasePriceFieldSet__actions___qifmK"
};
export default ___CSS_LOADER_EXPORT___;
