import cx from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { Card } from '@components/card/Card';
import { ApiUnauthorizedError } from '@shared/api/errors';
import typography from '~styles/typography.scss';
import { useAuth } from '../context/AuthContext';
import { FORGOT_PASSWORD_ROOT_PATH } from '../paths';
import styles from './styles.scss';

export const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const { loginAndSetRestaurants } = useAuth();

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await loginAndSetRestaurants(email, password);
    } catch (e) {
      if (e instanceof ApiUnauthorizedError) {
        setAuthError(e.message);
      } else {
        throw e;
      }
    }
  };

  return (
    <main className={styles.pageContainer}>
      <Card className={styles.card}>
        <form className={styles.formContainer} onSubmit={handleOnSubmit}>
          <h1 className={cx(typography.h4, styles.formHeader)}>Log In</h1>
          <label className={cx(typography.c3, styles.formLabel)}>
            Email
            <input
              className={cx(styles.input, typography.c2)}
              data-testid="email-input"
              name="email"
              onChange={(event) => {
                setAuthError('');
                setEmail(event.target.value);
              }}
              type="email"
            />
          </label>
          <label className={cx(typography.c3, styles.formLabel)}>
            Password
            <input
              className={cx(styles.input, typography.c2)}
              data-testid="password-input"
              name="password"
              onChange={(event) => {
                setAuthError('');
                setPassword(event.target.value);
              }}
              type="password"
            />
          </label>
          {authError && <p className={styles.error}>{authError}</p>}
          <Button
            className={cx(styles.submitButton, typography.c2)}
            isDisabled={!email || !password}
            label="Submit"
            type="submit"
            variant={ButtonVariants.Primary}
          />
          <Link
            className={styles.resetPasswordLink}
            to={FORGOT_PASSWORD_ROOT_PATH}
          >
            Forgot Password
          </Link>
        </form>
      </Card>
    </main>
  );
};
