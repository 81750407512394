import type { CancellationPolicy } from '@shared/types/reservations';
import { type RestaurantStatus } from '@shared/types/restaurants';
import { type AdminRole } from 'restaurantAdmin/auth/apiHelpers';
import { api } from '../../api';

export interface AdminRestaurant {
  address: string;
  cancellationPolicy: CancellationPolicy | null;
  city: string;
  coverLimit: number | null;
  description: string;
  id: string;
  locationSlug: string;
  maxReservationGuests: number;
  name: string;
  nameSlug: string;
  pacingControls: {
    pacingLimit: number;
    pacingWindow: number;
  } | null;
  phone: string;
  postalCode: string;
  schedule: number;
  shifts: {
    id: string;
    startDate: string;
    endDate: string;
    repeat: string[];
    name: string;
    startTime: string;
    endTime: string;
  }[];
  state: string;
  status: RestaurantStatus;
  street: string;
  timezone: string;
  website: string;
}

export type RestaurantAdminStatus = 'active' | 'inactive' | 'invited';

export interface Admin {
  email: string;
  fullName: string;
  role: AdminRole;
  id: string;
  status: RestaurantAdminStatus;
}

export const removeAdmin = async (
  restaurantId: string,
  adminId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/admins/${adminId}/remove`);

export const getAdminRestaurant = async (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<AdminRestaurant> =>
  api.get(`/restaurants/${restaurantId}`, { signal });

export const getAdmins = async (restaurantId: string): Promise<Admin[]> =>
  api.get(`/restaurants/${restaurantId}/admins`);

export const getAdminById = async (
  restaurantId: string,
  adminId: string,
): Promise<Admin> => api.get(`/restaurants/${restaurantId}/admins/${adminId}`);

export const updateAdminById = async (
  restaurantId: string,
  adminId: string,
  role: AdminRole,
): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/admins/${adminId}`, { role });

export const inviteTeamMember = async (
  restaurantId: string,
  emails: string[],
  role: AdminRole,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/admins`, {
    emails,
    role,
  });

export const resendInvite = async (
  restaurantId: string,
  email: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/resend-invite`, {
    email,
  });

export const generateInvitationLink = async (
  restaurantId: string,
  email: string,
): Promise<{ invitationLink: string }> =>
  api.post(`/restaurants/${restaurantId}/invitation-link`, {
    email,
  });
