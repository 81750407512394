import cx from 'classnames';
import { Avatar } from '@components/avatar/Avatar';
import { Icon } from '@components/icon/Icon';
import { type ReservationGuest } from '@shared/types/reservations';
import { getFullName } from '@utils/formatName';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import { isReservationFinished } from 'restaurantAdmin/reservations/reservationUtils';
import typography from '~styles/typography.scss';
import type { OccupantsReservation } from '../apiHelpers';
import styles from './ReservationGuestSheetHeader.scss';

export interface ReservationGuestSheetHeaderProps {
  guest: ReservationGuest;
  reservation: OccupantsReservation;
}

export const ReservationGuestSheetHeader = ({
  guest: { firstName, lastName },
  reservation: {
    guestCount,
    listingName,
    seatedTableName,
    status,
    time,
    transactionDetails: {
      createdBy: { fullName: conciergeName },
    },
  },
}: ReservationGuestSheetHeaderProps) => {
  const guestFullName = getFullName(firstName, lastName);
  const guestCountText = appendGuestCountLabel(guestCount);

  const isFinished = isReservationFinished(status);

  return (
    <header className={styles.container}>
      <div>
        <Avatar firstName={firstName} lastName={lastName} />
      </div>
      <div>
        <div className={styles.heading}>
          <h2 className={typography.h5}>{guestFullName}</h2>
          {!isFinished && seatedTableName && (
            <p className={cx(typography.t1, styles.status)}>Seated</p>
          )}
          {isFinished && (
            <p className={cx(typography.t1, styles.status)}>Finished</p>
          )}
        </div>
        <div className={styles.subheading}>
          <p className={typography.h6}>{ISOTimeTo12HourTime(time)}</p>
          <p className={typography.t1}>{guestCountText}</p>
        </div>
        <div className={cx(typography.t1, styles.seatedTableName)}>
          <span>{listingName}</span>
          {seatedTableName && <span>{seatedTableName}</span>}
          {conciergeName && (
            <div className={styles.concierge}>
              <Icon name="roomService" />
              <span>Booked by {conciergeName}</span>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};
