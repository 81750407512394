import { type CalendarApi } from '@fullcalendar/core';
import cx from 'classnames';
import { Calendar } from '@components/calendar/Calendar';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { toShortWeekdayMonthDateAndYearFormat } from '@shared/utils/dateFormatters';
import typography from '~styles/typography.scss';
import styles from './TimelineToolbar.scss';

interface TimelineToolbarProps {
  calendarApi: CalendarApi | null;
  selectedDate: Date;
}

export const TimelineToolbar = ({
  calendarApi,
  selectedDate,
}: TimelineToolbarProps) => {
  const {
    isOpen: isDatePickerOpen,
    close: closeDatePicker,
    open: openDatePicker,
  } = useIsOpen();

  const { ref: popoverRef } = useHandleClickOutside(closeDatePicker);

  return (
    <>
      {isDatePickerOpen && (
        <div className={styles.datePickerPopover} ref={popoverRef}>
          <Calendar
            value={selectedDate}
            onDateChange={(value) => {
              if (value && calendarApi) {
                calendarApi.gotoDate(value);
                closeDatePicker();
              }
            }}
          />
        </div>
      )}
      <div className={styles.timelineToolbar}>
        <button
          className={cx(typography.c3, styles.nowButton)}
          onClick={() => {
            if (calendarApi) {
              calendarApi.today();
            }
          }}
        >
          <Icon name="currentTime" />
          Now
        </button>
        <div className={styles.dateSelector}>
          <button
            aria-label="Previous"
            className={styles.arrowLeft}
            onClick={() => {
              if (calendarApi) {
                calendarApi.prev();
              }
            }}
          >
            <Icon name="arrowLeft" />
          </button>
          <button
            aria-label="Choose date"
            className={cx(typography.h6m_20, styles.selectedDate)}
            onClick={openDatePicker}
          >
            {toShortWeekdayMonthDateAndYearFormat(selectedDate)}
          </button>
          <button
            aria-label="Next"
            className={styles.arrowRight}
            onClick={() => {
              if (calendarApi) {
                calendarApi.next();
              }
            }}
          >
            <Icon name="arrowLeft" />
          </button>
        </div>
      </div>
    </>
  );
};
