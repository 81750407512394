// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SplitMergeTablesSheet__container___u3ej9{padding:1.5rem}.SplitMergeTablesSheet__container___u3ej9 ul{list-style-type:disc;padding:1.5rem}.SplitMergeTablesSheet__container___u3ej9 button{margin-top:.5rem;width:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/splitMergeTables/SplitMergeTablesSheet.scss"],"names":[],"mappings":"AAAA,0CACE,cAAA,CAEA,6CACE,oBAAA,CACA,cAAA,CAGF,iDACE,gBAAA,CACA,UAAA","sourcesContent":[".container {\n  padding: 1.5rem;\n\n  ul {\n    list-style-type: disc;\n    padding: 1.5rem;\n  }\n\n  button {\n    margin-top: 0.5rem;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SplitMergeTablesSheet__container___u3ej9"
};
export default ___CSS_LOADER_EXPORT___;
