import { api } from '../api';

export type AdminRole = 'manager' | 'host';

export interface LoginResponse {
  fullName: string;
  id: string;
  role: AdminRole;
}

export interface CheckAuthResponse {
  fullName: string;
  id: string;
  isAuthenticated: boolean;
  role?: AdminRole;
}

export interface RegisterAdminPayload {
  email: string;
  fullName: string;
  isTosAccepted: boolean;
  password: string;
  token: string;
}

export const checkAuth = (signal?: AbortSignal): Promise<CheckAuthResponse> =>
  api.get('/check-auth-admin', { redirectUnauthorized: false, signal });

export const login = (
  email: string,
  password: string,
): Promise<LoginResponse> =>
  api.post('/admin-login', {
    email,
    password,
  });

export const requestPasswordReset = (email: string): Promise<void> =>
  api.post('/admin/forgot-password', { email });

export const signOut = (): Promise<void> => api.post('/admin-sign-out');

export const updateAdminPassword = (
  password: string,
  token: string,
): Promise<void> => api.post(`/admin/reset-password`, { password, token });

export const registerAdmin = (payload: RegisterAdminPayload): Promise<void> =>
  api.post('/restaurants/admins/complete-registration', payload);
