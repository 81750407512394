import { type SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { successToast } from '@components/toasts/Toasts';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { type AdminRole } from 'restaurantAdmin/auth/apiHelpers';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import { PageHeader } from 'restaurantAdmin/layout/PageHeader';
import typography from '~styles/typography.scss';
import { getAdminById, updateAdminById } from './apiHelpers';
import styles from './ChangeTeamMemberRolePage.scss';

interface ChangeTeamMemberRoleFormData {
  role: AdminRole;
}

export const ChangeTeamMemberRolePage = () => {
  const { adminId } = useParams();
  const navigate = useNavigate();
  const { id: restaurantId } = useRestaurant();
  const { data: admin, isPending } = useAbortEffect(
    {
      effect: async () => getAdminById(restaurantId, adminId!),
    },
    [adminId],
  );
  const { control, handleSubmit } = useForm<ChangeTeamMemberRoleFormData>({});

  if (isPending || !adminId) {
    return null;
  }

  const goBack = () => navigate(-1);

  const onSubmit: SubmitHandler<ChangeTeamMemberRoleFormData> = async (
    data,
  ) => {
    const { role } = data;
    await updateAdminById(restaurantId, adminId, role);
    successToast({ message: 'Role successfully updated!' });
    goBack();
  };

  const ROLE_OPTIONS = [
    {
      label: 'Host',
      value: 'host',
    },
    {
      label: 'Manager',
      value: 'manager',
    },
  ];

  return (
    <>
      <PageHeader title="Change Team Member Role" />
      <PageContent>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <section>
              <div className={styles.labelContainer}>
                <h2 className={typography.h5}>Member Info</h2>
              </div>
              <div className={styles.fieldContainer}>
                <div className={styles.memberInfoBox}>
                  <h6 className={typography.c1}>{admin!.fullName}</h6>
                  <p className={typography.c2_20}>{admin!.email}</p>
                </div>
              </div>
            </section>
            <section>
              <div className={styles.labelContainer}>
                <h2 className={typography.h5}>Role</h2>
                <p className={typography.t2}>
                  Hosts can only access the pages under Reservations and Guest
                  Book.
                </p>
                <p className={typography.t2}>
                  Managers can access all pages in Peak.
                </p>
              </div>
              <div className={styles.fieldContainer}>
                <ControlledFormSelect
                  control={control}
                  defaultValue={admin!.role}
                  options={ROLE_OPTIONS}
                  label="Role"
                  name="role"
                />
              </div>
            </section>
            <section>
              <div className={styles.actions}>
                <Button
                  variant={ButtonVariants.Tertiary}
                  label="Cancel"
                  onClick={goBack}
                />
                <Button
                  variant={ButtonVariants.Primary}
                  label="Save Role"
                  type="submit"
                />
              </div>
            </section>
          </fieldset>
        </form>
      </PageContent>
    </>
  );
};
