import {
  type ReservationGuest,
  type ReservationStatus,
  type TransactionDetails,
} from '@shared/types/reservations';
import { api } from 'restaurantAdmin/api';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';
import { type WalkInStatus } from '../apiHelpers';
import { type ServiceStatus } from './serviceStatus/ServiceStatus';

export interface Server {
  hexColor: string;
  id: string;
  name: string;
}

export interface ServiceWalkIn {
  type: 'walkIn';
  date: string;
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
  seatedFloorPlanId: string;
  seatedTableId: string;
  seatedTableName: string;
  server: Server | null;
  serviceStatus: ServiceStatus;
  status: WalkInStatus;
  time: string;
}

export interface ServiceReservation {
  type: 'reservation';
  date: string;
  guest: ReservationGuest;
  guestCount: number;
  hasDietaryRestrictionOrAllergy: boolean;
  hasGuestNote: boolean;
  id: string;
  isStranded: boolean;
  isVIP: boolean;
  listingFloorPlanId: string;
  listingId: string;
  listingName: string;
  note: string | null;
  seatedTableName: string | null;
  server: Server | null;
  serviceStatus: ServiceStatus;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
}

export type ServiceOccupant = ServiceReservation | ServiceWalkIn;

export enum OccupantType {
  Reservation = 'reservation',
  WalkIn = 'walk-in',
}

export const isReservation = (
  occupant: ServiceOccupant | undefined,
): occupant is ServiceReservation => occupant?.type === 'reservation';

export const isWalkIn = (
  occupant: ServiceOccupant | undefined,
): occupant is ServiceWalkIn => occupant?.type === 'walkIn';

export const getInfiniteOccupants = async (
  restaurantId: string,
  limit: number,
  offset: number,
  guestTagFilters: GuestTag[],
  guestSearchQuery?: string,
  signal?: AbortSignal,
): Promise<ServiceOccupant[]> => {
  const guestTagFilterQueryParam = guestTagFilters.length
    ? `&guestTagFilters=${guestTagFilters.map((tag) => tag.title).join(',')}`
    : '';

  const guestSearchQueryParam = guestSearchQuery
    ? `&guestSearchQuery=${encodeURIComponent(guestSearchQuery)}`
    : '';

  return api.get(
    `/restaurants/${restaurantId}/service/occupants?limit=${limit}&offset=${offset}${guestTagFilterQueryParam}${guestSearchQueryParam}`,
    {
      signal,
    },
  );
};

export const getServiceReservationDetails = async (
  restaurantId: string,
  reservationId: string,
): Promise<ServiceReservation> =>
  api.get(`/restaurants/${restaurantId}/service/reservations/${reservationId}`);

export const getServiceWalkInDetails = async (
  restaurantId: string,
  walkInId: string,
): Promise<ServiceWalkIn> =>
  api.get(`/restaurants/${restaurantId}/service/walk-ins/${walkInId}`);

export const createReservationServiceStatus = async (
  restaurantId: string,
  reservationId: string,
  serviceStatus: ServiceStatus,
): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/service/reservations/${reservationId}/service-statuses`,
    { serviceStatus },
  );

export const createWalkInServiceStatus = async (
  restaurantId: string,
  walkInId: string,
  serviceStatus: ServiceStatus,
): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/service/walk-ins/${walkInId}/service-statuses`,
    { serviceStatus },
  );

export const updateOccupantServer = async ({
  occupantId,
  occupantType,
  restaurantId,
  serverId,
}: {
  occupantId: string;
  occupantType: OccupantType;
  restaurantId: string;
  serverId: string | null;
}): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/occupant/update-server`, {
    occupantId,
    occupantType,
    serverId,
  });
