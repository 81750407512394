// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ControlledFormCheckbox__container___BIuv6 input{margin:0}.ControlledFormCheckbox__container___BIuv6>div{display:flex;gap:1rem}.ControlledFormCheckbox__container___BIuv6>div label{display:flex;flex-direction:row-reverse;gap:1rem}.ControlledFormCheckbox__container___BIuv6>div label.ControlledFormCheckbox__labelError___KZZLk{color:var(--pink)}", "",{"version":3,"sources":["webpack://./src/shared/components/formInputs/ControlledFormCheckbox.scss"],"names":[],"mappings":"AACE,iDACE,QAAA,CAGF,+CACE,YAAA,CACA,QAAA,CAEA,qDACE,YAAA,CACA,0BAAA,CACA,QAAA,CAEA,gGACE,iBAAA","sourcesContent":[".container {\n  input {\n    margin: 0;\n  }\n\n  & > div {\n    display: flex;\n    gap: 1rem;\n\n    label {\n      display: flex;\n      flex-direction: row-reverse;\n      gap: 1rem;\n\n      &.labelError {\n        color: var(--pink);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ControlledFormCheckbox__container___BIuv6",
	"labelError": "ControlledFormCheckbox__labelError___KZZLk"
};
export default ___CSS_LOADER_EXPORT___;
