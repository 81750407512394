// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailabilityListHeading__container___Sk0UR{border-bottom:1px solid var(--hairline);display:grid;grid-template-columns:repeat(3, 1fr);padding:1rem 0}.AvailabilityListHeading__container___Sk0UR h3{margin:0}.AvailabilityListHeading__backButton___FCkGF{align-items:center;background-color:inherit;border:0;cursor:pointer;display:inline-flex;outline:0;text-transform:uppercase}.AvailabilityListHeading__backButton___FCkGF svg{margin-right:.25rem;stroke:var(--white);width:.875rem}.AvailabilityListHeading__selectedTime___kiloh{border:1px solid var(--gold);border-radius:var(--radius_small);padding:.5rem;text-align:center}", "",{"version":3,"sources":["webpack://./src/shared/availability/AvailabilityListHeading.scss"],"names":[],"mappings":"AAAA,4CACE,uCAAA,CACA,YAAA,CACA,oCAAA,CACA,cAAA,CAEA,+CACE,QAAA,CAIJ,6CACE,kBAAA,CACA,wBAAA,CACA,QAAA,CACA,cAAA,CACA,mBAAA,CACA,SAAA,CACA,wBAAA,CAEA,iDACE,mBAAA,CACA,mBAAA,CACA,aAAA,CAIJ,+CACE,4BAAA,CACA,iCAAA,CACA,aAAA,CACA,iBAAA","sourcesContent":[".container {\n  border-bottom: 1px solid var(--hairline);\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  padding: 1rem 0;\n\n  h3 {\n    margin: 0;\n  }\n}\n\n.backButton {\n  align-items: center;\n  background-color: inherit;\n  border: 0;\n  cursor: pointer;\n  display: inline-flex;\n  outline: 0;\n  text-transform: uppercase;\n\n  svg {\n    margin-right: 0.25rem;\n    stroke: var(--white);\n    width: 0.875rem;\n  }\n}\n\n.selectedTime {\n  border: 1px solid var(--gold);\n  border-radius: var(--radius_small);\n  padding: 0.5rem;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AvailabilityListHeading__container___Sk0UR",
	"backButton": "AvailabilityListHeading__backButton___FCkGF",
	"selectedTime": "AvailabilityListHeading__selectedTime___kiloh"
};
export default ___CSS_LOADER_EXPORT___;
