// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheetStatuses__container___WCAMX{gap:8px;display:flex;width:100%}.ReservationGuestSheetStatuses__container___WCAMX .ReservationGuestSheetStatuses__undoNoShow___Tkhjh{border:0;color:var(--red200);cursor:pointer;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/reservationGuestSheetStatuses/ReservationGuestSheetStatuses.scss"],"names":[],"mappings":"AAAA,kDACE,OAAA,CACA,YAAA,CACA,UAAA,CAEA,qGACE,QAAA,CACA,mBAAA,CACA,cAAA,CACA,yBAAA","sourcesContent":[".container {\n  gap: 8px;\n  display: flex;\n  width: 100%;\n\n  .undoNoShow {\n    border: 0;\n    color: var(--red200);\n    cursor: pointer;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationGuestSheetStatuses__container___WCAMX",
	"undoNoShow": "ReservationGuestSheetStatuses__undoNoShow___Tkhjh"
};
export default ___CSS_LOADER_EXPORT___;
