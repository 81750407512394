import FloorPlanTable, { type TableHighlightingMode } from './FloorPlanTable';
import type { TableIconCommonProps } from './InteractiveFloorPlanTable';

export interface NonInteractiveFloorPlanTableProps
  extends TableIconCommonProps {
  isHighlighted: boolean;
  tableHighlightingMode?: TableHighlightingMode;
}

export const NonInteractiveFloorPlanTable = ({
  iconName,
  iconWidthScale,
  isHighlighted,
  left,
  orientation,
  tableHighlightingMode = 'outline',
  tableIconScale,
  testId,
  top,
}: NonInteractiveFloorPlanTableProps) => (
  <FloorPlanTable
    data-testid={testId}
    left={left}
    tableIconScale={tableIconScale}
    top={top}
  >
    <FloorPlanTable.Icon
      iconName={iconName}
      iconWidthScale={iconWidthScale}
      isHighlighted={isHighlighted}
      orientation={orientation}
      tableIconScale={tableIconScale}
      tableHighlightingMode={tableHighlightingMode}
    />
  </FloorPlanTable>
);
