// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReservationGuestSheet__container___HUhVr{display:flex;flex-direction:column;gap:16px}.ReservationGuestSheet__container___HUhVr section{background-color:var(--panelBlue);border-radius:8px;padding:1rem}.ReservationGuestSheet__floorplan___ChLr0{display:flex;flex-direction:column;height:320px;width:100%;background-color:var(--panelBlue);border-radius:8px;padding:1rem}.ReservationGuestSheet__floorplan___ChLr0 .ReservationGuestSheet__expandButton___30Jbk{height:36px;width:36px;border-radius:var(--radius_small);border:1px solid var(--hairline);background:var(--primary400);align-self:flex-end}.ReservationGuestSheet__actions___oM8fY{display:flex;row-gap:8px;align-items:stretch;flex-direction:column}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/service/sidePanel/reservationGuestSheet/ReservationGuestSheet.scss"],"names":[],"mappings":"AAAA,0CACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,kDACE,iCAAA,CACA,iBAAA,CACA,YAAA,CAIJ,0CACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,UAAA,CACA,iCAAA,CACA,iBAAA,CACA,YAAA,CAEA,uFACE,WAAA,CACA,UAAA,CACA,iCAAA,CACA,gCAAA,CACA,4BAAA,CACA,mBAAA,CAIJ,wCACE,YAAA,CACA,WAAA,CACA,mBAAA,CACA,qBAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n\n  section {\n    background-color: var(--panelBlue);\n    border-radius: 8px;\n    padding: 1rem;\n  }\n}\n\n.floorplan {\n  display: flex;\n  flex-direction: column;\n  height: 320px;\n  width: 100%;\n  background-color: var(--panelBlue);\n  border-radius: 8px;\n  padding: 1rem;\n\n  .expandButton {\n    height: 36px;\n    width: 36px;\n    border-radius: var(--radius_small);\n    border: 1px solid var(--hairline);\n    background: var(--primary400);\n    align-self: flex-end;\n  }\n}\n\n.actions {\n  display: flex;\n  row-gap: 8px;\n  align-items: stretch;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ReservationGuestSheet__container___HUhVr",
	"floorplan": "ReservationGuestSheet__floorplan___ChLr0",
	"expandButton": "ReservationGuestSheet__expandButton___30Jbk",
	"actions": "ReservationGuestSheet__actions___oM8fY"
};
export default ___CSS_LOADER_EXPORT___;
