// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Avatar__initials___jERnB{align-items:center;background-color:var(--darkGrey300);border-radius:50%;color:var(--white);display:flex;height:2.5rem;justify-content:center;text-transform:uppercase;width:2.5rem;min-width:2.5rem}", "",{"version":3,"sources":["webpack://./src/shared/components/avatar/Avatar.scss"],"names":[],"mappings":"AAAA,0BACE,kBAAA,CACA,mCAAA,CACA,iBAAA,CACA,kBAAA,CACA,YAAA,CACA,aAAA,CACA,sBAAA,CACA,wBAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".initials {\n  align-items: center;\n  background-color: var(--darkGrey300);\n  border-radius: 50%;\n  color: var(--white);\n  display: flex;\n  height: 2.5rem;\n  justify-content: center;\n  text-transform: uppercase;\n  width: 2.5rem;\n  min-width: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"initials": "Avatar__initials___jERnB"
};
export default ___CSS_LOADER_EXPORT___;
