// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CancellationFee__container___jhgSK{display:flex;flex-direction:column;gap:1rem}.CancellationFee__container___jhgSK header{align-items:flex-start;display:flex;gap:.5rem}.CancellationFee__container___jhgSK header p{margin:0}.CancellationFee__container___jhgSK>div{display:flex;flex-direction:column;gap:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/sidePanel/CancellationFee.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,2CACE,sBAAA,CACA,YAAA,CACA,SAAA,CAEA,6CACE,QAAA,CAIJ,wCACE,YAAA,CACA,qBAAA,CACA,SAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n\n  header {\n    align-items: flex-start;\n    display: flex;\n    gap: 0.5rem;\n\n    p {\n      margin: 0;\n    }\n  }\n\n  > div {\n    display: flex;\n    flex-direction: column;\n    gap: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CancellationFee__container___jhgSK"
};
export default ___CSS_LOADER_EXPORT___;
