// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.ListingsList__main___iGSMN{flex:1 1 0;overflow-y:auto;position:relative}.ListingsList__tabSpinnerContainer___dhw_o{align-content:center;flex:1}.ListingsList__tabSpinner___GwFVx{height:4rem;width:4rem;margin:auto}.ListingsList__tabNoMatchingListings___DuRfX{align-items:center;color:var(--primary200);display:flex;flex:1;justify-content:center}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/ListingsList.scss"],"names":[],"mappings":"AAAA,8BACE,UAAA,CACA,eAAA,CACA,iBAAA,CAGF,2CACE,oBAAA,CACA,MAAA,CAGF,kCACE,WAAA,CACA,UAAA,CACA,WAAA,CAGF,6CACE,kBAAA,CACA,uBAAA,CACA,YAAA,CACA,MAAA,CACA,sBAAA","sourcesContent":["ul.main {\n  flex: 1 1 0;\n  overflow-y: auto;\n  position: relative;\n}\n\n.tabSpinnerContainer {\n  align-content: center;\n  flex: 1;\n}\n\n.tabSpinner {\n  height: 4rem;\n  width: 4rem;\n  margin: auto;\n}\n\n.tabNoMatchingListings {\n  align-items: center;\n  color: var(--primary200);\n  display: flex;\n  flex: 1;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ListingsList__main___iGSMN",
	"tabSpinnerContainer": "ListingsList__tabSpinnerContainer___dhw_o",
	"tabSpinner": "ListingsList__tabSpinner___GwFVx",
	"tabNoMatchingListings": "ListingsList__tabNoMatchingListings___DuRfX"
};
export default ___CSS_LOADER_EXPORT___;
