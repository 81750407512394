// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CancellationFeeStatusTag__cancellationFeeContainer___xDOG3{display:flex;flex-direction:row;gap:.5rem;text-transform:uppercase}.CancellationFeeStatusTag__cancellationFee___LD5YU{color:var(--white70)}.CancellationFeeStatusTag__notYetCollectedTag___R3N2a{color:var(--yellow300)}.CancellationFeeStatusTag__collectedTag___CWRYR{color:var(--green300)}.CancellationFeeStatusTag__collectionFailedTag___zjmdS{color:var(--pink)}.CancellationFeeStatusTag__waivedTag___zMkhz{color:var(--white)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/sidePanel/CancellationFeeStatusTag.scss"],"names":[],"mappings":"AAAA,4DACE,YAAA,CACA,kBAAA,CACA,SAAA,CACA,wBAAA,CAGF,mDACE,oBAAA,CAGF,sDACE,sBAAA,CAGF,gDACE,qBAAA,CAGF,uDACE,iBAAA,CAGF,6CACE,kBAAA","sourcesContent":[".cancellationFeeContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  text-transform: uppercase;\n}\n\n.cancellationFee {\n  color: var(--white70);\n}\n\n.notYetCollectedTag {\n  color: var(--yellow300);\n}\n\n.collectedTag {\n  color: var(--green300);\n}\n\n.collectionFailedTag {\n  color: var(--pink);\n}\n\n.waivedTag {\n  color: var(--white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancellationFeeContainer": "CancellationFeeStatusTag__cancellationFeeContainer___xDOG3",
	"cancellationFee": "CancellationFeeStatusTag__cancellationFee___LD5YU",
	"notYetCollectedTag": "CancellationFeeStatusTag__notYetCollectedTag___R3N2a",
	"collectedTag": "CancellationFeeStatusTag__collectedTag___CWRYR",
	"collectionFailedTag": "CancellationFeeStatusTag__collectionFailedTag___zjmdS",
	"waivedTag": "CancellationFeeStatusTag__waivedTag___zMkhz"
};
export default ___CSS_LOADER_EXPORT___;
