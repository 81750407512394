// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotesToRestaurant__container___LfrrE header{display:flex;align-items:center}.NotesToRestaurant__container___LfrrE .NotesToRestaurant__messageCircle___ZBWMO{fill:var(--white);width:16px;height:17px;margin-right:4px}.NotesToRestaurant__container___LfrrE .NotesToRestaurant__noteText___KV7ki{color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/notesToRestaurant/NotesToRestaurant.scss"],"names":[],"mappings":"AACE,6CACE,YAAA,CACA,kBAAA,CAGF,gFACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,2EACE,oBAAA","sourcesContent":[".container {\n  header {\n    display: flex;\n    align-items: center;\n  }\n\n  .messageCircle {\n    fill: var(--white);\n    width: 16px;\n    height: 17px;\n    margin-right: 4px;\n  }\n\n  .noteText {\n    color: var(--white70);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "NotesToRestaurant__container___LfrrE",
	"messageCircle": "NotesToRestaurant__messageCircle___ZBWMO",
	"noteText": "NotesToRestaurant__noteText___KV7ki"
};
export default ___CSS_LOADER_EXPORT___;
