import {
  type CancellationPolicy,
  type ReservationGuest,
  type ReservationStatus,
  type TransactionDetails,
} from '@shared/types/reservations';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';
import { api } from '../../api';

interface Server {
  hexColor: string;
  id: string;
  name: string;
}

export interface OccupantsReservation {
  cancellationFeeStatus: 'collected' | 'waived' | null;
  cancellationPolicy?: CancellationPolicy;
  date: string;
  guest: ReservationGuest;
  guestCount: number;
  hasDietaryRestrictionOrAllergy: boolean;
  hasGuestNote: boolean;
  id: string;
  isEligibleForCancellationFee: boolean;
  isStranded: boolean;
  isVIP: boolean;
  listingId: string;
  listingName: string;
  seatedTableName: string | null;
  server?: Server;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
  type: 'reservation';
}

export type WalkInStatus = 'active' | 'finished' | 'autoResolved';

export interface OccupantsWalkIn {
  date: string;
  firstName?: string;
  guestCount: number;
  id: string;
  lastName?: string;
  seatedTableName: string;
  server?: Server;
  status: WalkInStatus;
  time: string;
  type: 'walkIn';
}

// ...as opposed to ServiceOccupant
export type OccupantsOccupant = OccupantsReservation | OccupantsWalkIn;

export const isReservation = (
  occupant: OccupantsOccupant | undefined,
): occupant is OccupantsReservation => occupant?.type === 'reservation';

export const isWalkIn = (
  occupant: OccupantsOccupant | undefined,
): occupant is OccupantsWalkIn => occupant?.type === 'walkIn';

export const getOccupants = async (
  restaurantId: string,
  date: string,
  guestTagFilters: GuestTag[],
  signal?: AbortSignal,
): Promise<OccupantsOccupant[]> => {
  const guestTagFilterQueryParam = guestTagFilters.length
    ? `?guestTagFilters=${guestTagFilters.map((tag) => tag.title).join(',')}`
    : '';

  return api.get(
    `/restaurants/${restaurantId}/occupants/${date}${guestTagFilterQueryParam}`,
    {
      signal,
    },
  );
};

export const closeRestaurant = async (
  restaurantId: string,
  date: string,
  lastReservationTime?: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/closures`, {
    date,
    ...(lastReservationTime && { lastReservationTime }),
  });

export const openRestaurant = (
  restaurantId: string,
  date: string,
): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/closures?date=${date}`);

export interface Closure {
  date: string;
  id: string;
  restaurantId: string;
}

export const getClosedDates = async (
  restaurantId: string,
  startDate: string,
  endDate: string,
): Promise<Closure[]> =>
  api.get(
    `/restaurants/${restaurantId}/closures?startDate=${startDate}&endDate=${endDate}`,
  );

export interface RestaurantDayNote {
  date: string;
  note: string;
  restaurantId: string;
}

export const getRestaurantNote = async ({
  restaurantId,
  date,
}: {
  restaurantId: string;
  date: string;
}): Promise<RestaurantDayNote | null> =>
  api.get(`/restaurants/${restaurantId}/day-notes/${date}`);

export const saveRestaurantNote = async ({
  date,
  note,
  restaurantId,
}: RestaurantDayNote): Promise<RestaurantDayNote> =>
  api.post(`/restaurants/${restaurantId}/day-notes/${date}`, {
    note,
  });

export const deleteRestaurantNote = async ({
  date,
  restaurantId,
}: {
  date: string;
  restaurantId: string;
}): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/day-notes/${date}`);
