import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import { type ShiftWithHighlightedTableIds, updateShifts } from './apiHelpers';
import styles from './CreateShiftModal.scss';
import { ShiftForm, type ShiftFormData } from './ShiftForm';

export interface UpdateShiftModalProps {
  isOpen: boolean;
  closeModal: () => void;
  shift: ShiftWithHighlightedTableIds;
  handleSuccess: () => void;
}

export const UpdateShiftModal = ({
  isOpen,
  closeModal,
  shift,
  handleSuccess,
}: UpdateShiftModalProps) => {
  const { id: restaurantId } = useRestaurant();
  const [warningMessages, setWarningMessages] = useState<string[]>([]);
  const { control, handleSubmit, reset } = useForm<ShiftFormData>({
    defaultValues: {
      startDate: shift.startDate,
      endDate: shift.endDate,
      name: shift.name,
      startTime: shift.startTime,
      endTime: shift.endTime,
      repeat: shift.repeat,
    },
  });

  useEffect(() => {
    reset({
      startDate: shift.startDate,
      endDate: shift.endDate,
      name: shift.name,
      startTime: shift.startTime,
      endTime: shift.endTime,
      repeat: shift.repeat,
    });
  }, [shift]);

  const handleClose = () => {
    closeModal();
    reset();
    setWarningMessages([]);
  };

  const handleOnClickConfirm = (): void => {
    void handleSubmit(async (data: ShiftFormData) => {
      try {
        const { warnings } = await updateShifts(restaurantId, {
          ignoreWarnings: !!warningMessages.length,
          createdShifts: [],
          updatedShifts: [
            {
              id: shift.id,
              name: data.name,
              startDate: data.startDate,
              endDate: data.endDate,
              repeat: data.repeat,
              startTime: data.startTime,
              endTime: data.endTime,
            },
          ],
          deletedShiftIds: [],
        });

        if (warnings.length) {
          setWarningMessages(warnings);
        } else {
          successToast({ message: 'Shift successfully updated' });
          handleClose();
          handleSuccess();
        }
      } catch (error) {
        errorToast({ message: 'Failed to update Shifts' });
        reportAppError(error);
      }
    })();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Edit Shift">
      <div className={styles.container}>
        <ShiftForm control={control} />
        {warningMessages.length > 0 && (
          <div className={cx(typography.c2, styles.warnings)}>
            <p>Warning: {warningMessages.join(', ')}.</p>
            <p>Are you sure you want to proceed?</p>
          </div>
        )}
        <ModalActions>
          <Button
            type="button"
            label="Cancel"
            onClick={handleClose}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            type="button"
            label="Save"
            onClick={handleOnClickConfirm}
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </div>
    </Modal>
  );
};
