import { Menu } from '@base-ui-components/react';
import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { reportAppError } from '@shared/reportAppError';
import typography from '~styles/typography.scss';
import { type ShiftWithHighlightedTableIds, updateShifts } from './apiHelpers';
import { DeleteShiftConfirmationModal } from './DeleteShiftConfirmationModal';
import styles from './ShiftMenu.scss';
import { UpdateShiftModal } from './UpdateShiftModal';

interface ShiftMenuProps {
  restaurantId: string;
  shift: ShiftWithHighlightedTableIds;
  refetchShifts: () => void;
  closeDrawer: () => void;
}

export const ShiftMenu = ({
  restaurantId,
  shift,
  refetchShifts,
  closeDrawer,
}: ShiftMenuProps) => {
  const {
    isOpen: isUpdateModalOpen,
    open: openUpdateModal,
    close: closeUpdateModal,
  } = useIsOpen();
  const {
    isOpen: isDeleteConfirmationModalOpen,
    open: openDeleteConfirmationModal,
    close: closeDeleteConfirmationModal,
    data: deleteWarnings,
  } = useIsOpenWithData<string[] | null>();

  const handleOnConfirmDelete = (ignoreWarnings: boolean) => {
    void (async () => {
      try {
        const { warnings } = await updateShifts(restaurantId, {
          ignoreWarnings,
          createdShifts: [],
          updatedShifts: [],
          deletedShiftIds: [shift.id],
        });

        if (warnings.length) {
          openDeleteConfirmationModal(warnings);
          return;
        }

        closeDeleteConfirmationModal();
        refetchShifts();
        closeDrawer();
        successToast({ message: 'Shift deleted' });
      } catch (e) {
        if (e instanceof ApiError) {
          errorToast({ message: e.message });
        } else {
          errorToast({ message: 'Failed to delete shift. Please try again.' });
        }
        reportAppError(e);
      }
    })();
  };

  return (
    <>
      <Menu.Root>
        <Menu.Trigger className={cx(styles.button, typography.h8)}>
          Options
        </Menu.Trigger>
        <Menu.Portal>
          <Menu.Positioner side="bottom" sideOffset={12}>
            <Menu.Popup className={styles.popup}>
              <Menu.Arrow className={styles.arrow}>
                <Icon name="arrowPopover" />
              </Menu.Arrow>
              <Menu.Item className={styles.menuItem} onClick={openUpdateModal}>
                Edit Shift
              </Menu.Item>
              <Menu.Separator className={styles.separator} />
              <Menu.Item
                className={cx(styles.menuItem, styles.delete)}
                onClick={() => openDeleteConfirmationModal(null)}
              >
                Delete Shift
              </Menu.Item>
            </Menu.Popup>
          </Menu.Positioner>
        </Menu.Portal>
      </Menu.Root>
      <UpdateShiftModal
        isOpen={isUpdateModalOpen}
        closeModal={closeUpdateModal}
        shift={shift}
        handleSuccess={refetchShifts}
      />
      <DeleteShiftConfirmationModal
        closeModal={closeDeleteConfirmationModal}
        warnings={deleteWarnings || null}
        ignoreWarnings={!!deleteWarnings && deleteWarnings.length > 0}
        handleOnConfirm={handleOnConfirmDelete}
        isOpen={isDeleteConfirmationModalOpen}
      />
    </>
  );
};
