// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RefundConfirmationModal__message___jYhQ_{color:var(--white70);max-width:30em}.RefundConfirmationModal__link___hijt7{color:inherit;font-size:1rem;font-weight:600;text-decoration:underline}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/refundButton/RefundConfirmationModal.scss"],"names":[],"mappings":"AAAA,0CACE,oBAAA,CACA,cAAA,CAGF,uCACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,yBAAA","sourcesContent":[".message {\n  color: var(--white70);\n  max-width: 30em;\n}\n\n.link {\n  color: inherit;\n  font-size: 1rem;\n  font-weight: 600;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "RefundConfirmationModal__message___jYhQ_",
	"link": "RefundConfirmationModal__link___hijt7"
};
export default ___CSS_LOADER_EXPORT___;
