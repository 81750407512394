// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OccupantsPage__pageContent___JQcGL{display:flex;flex-direction:column}.OccupantsPage__occupants___u4VeW{flex:1 0 0;margin:0 auto;min-height:0;padding-top:1.5rem;width:67%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/occupants/OccupantsPage.scss"],"names":[],"mappings":"AAEA,oCACE,YAAA,CACA,qBAAA,CAGF,kCACE,UAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA","sourcesContent":["@use '~styles/breakpoints' as *;\n\n.pageContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.occupants {\n  flex: 1 0 0;\n  margin: 0 auto;\n  min-height: 0;\n  padding-top: 1.5rem;\n  width: 67%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "OccupantsPage__pageContent___JQcGL",
	"occupants": "OccupantsPage__occupants___u4VeW"
};
export default ___CSS_LOADER_EXPORT___;
