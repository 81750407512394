import { api } from 'restaurantAdmin/api';

interface NewShift {
  startDate: string;
  endDate: string | null;
  repeat: string[];
  name: string;
  startTime: string;
  endTime: string;
}

export interface UpdatedShift extends NewShift {
  id: string;
}

interface ShiftUpdateWarnings {
  warnings: string[];
  createdShiftIds: string[];
}

export interface Shift {
  id: string;
  name: string;
  startDate: string;
  endDate: string | null;
  repeat: string[];
  startTime: string;
  endTime: string;
}

export interface ShiftWithHighlightedTableIds extends Shift {
  highlightedFloorPlanTableIds: string[];
}

export const getShifts = (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<ShiftWithHighlightedTableIds[]> =>
  api.get<ShiftWithHighlightedTableIds[]>(
    `/restaurants/${restaurantId}/shifts`,
    {
      signal,
    },
  );

export const updateShifts = async (
  restaurantId: string,
  payload: {
    ignoreWarnings: boolean;
    createdShifts: NewShift[];
    updatedShifts: UpdatedShift[];
    deletedShiftIds: string[];
  },
): Promise<ShiftUpdateWarnings> =>
  api.post(`/restaurants/${restaurantId}/shifts`, payload);

export const addOrRemoveTables = async (
  restaurantId: string,
  shiftId: string,
  highlightedFloorPlanTableIds: string[],
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/shifts/${shiftId}/tables`, {
    highlightedFloorPlanTableIds,
  });
