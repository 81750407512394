import type { FloorPlanData } from '@shared/types/floorPlans';
import { CENTS_IN_DOLLAR } from '@shared/utils/currency';
import type { ListingWithShifts } from '../apiHelpers';
import type { ListingFormData, PricePointFormData } from '../types';
import { hasOverlappingDay, timeRangeOverlaps } from './timeRange';

export const getDefaultFormValues = ({
  floorPlan,
  listing,
  isListingLoading,
  isDuplicating = false,
}: {
  floorPlan: FloorPlanData;
  listing: ListingWithShifts | null;
  isListingLoading: boolean;
  isDuplicating?: boolean;
}): ListingFormData => {
  if (!listing) {
    // Don't default the floorPlanId until the listing has finished loading.
    const floorPlanId = isListingLoading ? undefined : floorPlan.id;

    return {
      floorPlanId,
      highlightedTables: [],
      isCommunal: false,
      interval: 15,
      price: '0',
      pricePoints: [],
      shiftIds: [],
    } as unknown as ListingFormData;
  }

  return {
    floorPlanId: listing.floorPlanId,
    highlightedTables: floorPlan.floorPlanTables.filter((table) =>
      listing.highlightedFloorPlanTableIds.includes(table.id),
    ),
    iconName: listing.iconName,
    interval: listing.interval,
    inventoryCount: listing.inventoryCount.toString(),
    isCommunal: listing.isCommunal,
    maximumGuests: listing.maximumGuests.toString(),
    minimumGuests: listing.minimumGuests.toString(),
    name: listing.name,
    publicName: `${listing.publicName}${isDuplicating ? ' - Copy' : ''}`,
    turnTime: listing.turnTime,
    price: (listing.price / CENTS_IN_DOLLAR).toString(),
    pricePoints: listing.pricePoints.map((pricePoint) => ({
      activeDays: pricePoint.activeDays,
      endTime: pricePoint.endTime,
      id: pricePoint.id,
      price: (pricePoint.price / CENTS_IN_DOLLAR).toString(),
      startTime: pricePoint.startTime,
    })),
    shiftIds: listing.shifts.map((window) => window.id),
  };
};

export const findOverlappingPricePointIndexes = (
  pricePoints: PricePointFormData[],
) =>
  pricePoints.reduce<number[]>(
    (errorIndexes, pricePointA, pricePointAIndex) => {
      const hasOverlap = pricePoints.some(
        (pricePointB, pricePointBIndex) =>
          pricePointAIndex !== pricePointBIndex &&
          isOverlappingPricePoints(pricePointB, pricePointA),
      );
      if (hasOverlap) errorIndexes.push(pricePointAIndex);
      return errorIndexes;
    },
    [],
  );

export const isOverlappingPricePoints = (
  pricePointA: PricePointFormData,
  pricePointB: PricePointFormData,
): boolean =>
  hasOverlappingDay(pricePointA.activeDays, pricePointB.activeDays) &&
  timeRangeOverlaps(
    {
      startTime: pricePointA.startTime,
      endTime: pricePointA.endTime,
    },
    {
      startTime: pricePointB.startTime,
      endTime: pricePointB.endTime,
    },
  );
