// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateShiftModal__container___IVBfQ{border:1px solid var(--white70);border-radius:var(--radius_small);padding:1rem}.CreateShiftModal__container___IVBfQ>div{margin-top:2rem}.CreateShiftModal__container___IVBfQ .CreateShiftModal__warnings___pdufi{margin-top:2rem;max-width:30em}.CreateShiftModal__container___IVBfQ .CreateShiftModal__warnings___pdufi p{color:var(--white70);margin:0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/shifts/CreateShiftModal.scss"],"names":[],"mappings":"AAAA,qCACE,+BAAA,CACA,iCAAA,CACA,YAAA,CAGA,yCACE,eAAA,CAGF,yEACE,eAAA,CACA,cAAA,CAEA,2EACE,oBAAA,CACA,QAAA","sourcesContent":[".container {\n  border: 1px solid var(--white70);\n  border-radius: var(--radius_small);\n  padding: 1rem;\n\n  // Override the huge margin coming from ModalActions.scss\n  > div {\n    margin-top: 2rem;\n  }\n\n  .warnings {\n    margin-top: 2rem;\n    max-width: 30em;\n\n    p {\n      color: var(--white70);\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateShiftModal__container___IVBfQ",
	"warnings": "CreateShiftModal__warnings___pdufi"
};
export default ___CSS_LOADER_EXPORT___;
