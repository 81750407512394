import { Separator } from '@base-ui-components/react';
import cx from 'classnames';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { Modal } from '@components/modal/Modal';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { type FloorPlanData } from '@shared/types/floorPlans';
import { parseToShortDateRange } from '@shared/utils/dateFormatters';
import typography from '~styles/typography.scss';
import { AddOrRemoveTablesModal } from './AddOrRemoveTablesModal';
import { type ShiftWithHighlightedTableIds } from './apiHelpers';
import { ShiftMenu } from './ShiftMenu';
import styles from './ShiftsPage.scss';

interface ShiftPanelProps {
  shift: ShiftWithHighlightedTableIds;
  closeDrawer: () => void;
  restaurantId: string;
  floorPlans: FloorPlanData[];
  refetchShifts: () => void;
}

export const ShiftPanel = ({
  shift,
  closeDrawer,
  restaurantId,
  floorPlans,
  refetchShifts,
}: ShiftPanelProps) => {
  const {
    isOpen: isExpandFloorPlanModalOpen,
    open: openExpandFloorPlanModal,
    close: closeExpandFloorPlanModal,
    data: selectedFloorPlan,
  } = useIsOpenWithData<FloorPlanData>();

  const {
    isOpen: isAddOrRemoveTablesModalOpen,
    open: openAddOrRemoveTablesModal,
    close: closeAddOrRemoveTablesModal,
  } = useIsOpen();

  return (
    <>
      <div className={styles.panel}>
        <IconButton
          ariaLabel="close shift details"
          onClick={() => closeDrawer()}
          iconName="smallArrowLeft"
          className={styles.closeButton}
        />
        <div className={styles.shiftCard}>
          <div className={styles.row}>
            <div className={typography.h5}>{shift.name}</div>
            <ShiftMenu
              restaurantId={restaurantId}
              shift={shift}
              refetchShifts={refetchShifts}
              closeDrawer={closeDrawer}
            />
          </div>
          <div className={cx(typography.t1, styles.dateRange)}>
            {parseToShortDateRange(shift.startDate, shift.endDate)}
          </div>
        </div>
        <Separator className={styles.separator} />
        {floorPlans.map((fp) => (
          <div key={fp.id} className={styles.shiftCard}>
            <FloorPlan
              backgroundSrc={fp.backgroundSrc}
              disableControls
              floorPlanTablesRenderer={floorPlanTablesRendererFactory({
                tables: fp.floorPlanTables,
                calculateIsHighlighted: (table) =>
                  shift.highlightedFloorPlanTableIds.includes(table.id),
              })}
            />
            <IconButton
              iconName="resizeUp"
              className={styles.expandButton}
              ariaLabel="expand floor plan"
              onClick={() => {
                openExpandFloorPlanModal(fp);
              }}
            />
          </div>
        ))}
        <button
          className={styles.addButton}
          type="button"
          onClick={openAddOrRemoveTablesModal}
        >
          <span className={typography.t1}>Add / Remove Tables</span>
          <Icon name="plus" />
        </button>
      </div>
      <Modal
        isOpen={isExpandFloorPlanModalOpen}
        onClose={closeExpandFloorPlanModal}
        ariaLabel="floor plan"
      >
        <div className={styles.floorPlanModal}>
          {selectedFloorPlan && (
            <FloorPlan
              className={styles.floorPlan}
              backgroundSrc={selectedFloorPlan.backgroundSrc}
              floorPlanTablesRenderer={floorPlanTablesRendererFactory({
                tables: selectedFloorPlan.floorPlanTables,
                calculateIsHighlighted: (table) =>
                  shift.highlightedFloorPlanTableIds.includes(table.id),
              })}
            />
          )}
        </div>
      </Modal>
      <AddOrRemoveTablesModal
        isOpen={isAddOrRemoveTablesModalOpen}
        close={closeAddOrRemoveTablesModal}
        floorPlans={floorPlans}
        shift={shift}
        refetchShifts={refetchShifts}
      />
    </>
  );
};
