// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShiftsSetting__list___oJQcW{display:flex;flex-direction:column;row-gap:24px}.ShiftsSetting__list___oJQcW>li{display:grid;grid-template-columns:1fr 1fr;row-gap:4px;align-items:center}.ShiftsSetting__list___oJQcW>li .ShiftsSetting__name___lNzEb{color:var(--white)}.ShiftsSetting__list___oJQcW>li .ShiftsSetting__time___AdFWJ{justify-self:flex-end;color:var(--white)}.ShiftsSetting__list___oJQcW>li .ShiftsSetting__date___svDwI{color:var(--white70)}.ShiftsSetting__list___oJQcW>li .ShiftsSetting__days___TEm9h{justify-self:flex-end;color:var(--white70)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/shifts/ShiftsSetting.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,YAAA,CAEA,gCACE,YAAA,CACA,6BAAA,CACA,WAAA,CACA,kBAAA,CAEA,6DACE,kBAAA,CAGF,6DACE,qBAAA,CACA,kBAAA,CAGF,6DACE,oBAAA,CAGF,6DACE,qBAAA,CACA,oBAAA","sourcesContent":[".list {\n  display: flex;\n  flex-direction: column;\n  row-gap: 24px;\n\n  > li {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    row-gap: 4px;\n    align-items: center;\n\n    .name {\n      color: var(--white);\n    }\n\n    .time {\n      justify-self: flex-end;\n      color: var(--white);\n    }\n\n    .date {\n      color: var(--white70);\n    }\n\n    .days {\n      justify-self: flex-end;\n      color: var(--white70);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "ShiftsSetting__list___oJQcW",
	"name": "ShiftsSetting__name___lNzEb",
	"time": "ShiftsSetting__time___AdFWJ",
	"date": "ShiftsSetting__date___svDwI",
	"days": "ShiftsSetting__days___TEm9h"
};
export default ___CSS_LOADER_EXPORT___;
