// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AvailableTimes__container___SQzHH h2{border-bottom:2px solid var(--gold);margin:0;padding:.5rem 0;text-align:center;text-transform:uppercase}.AvailableTimes__container___SQzHH ol{display:grid;gap:.5rem;grid-template-columns:repeat(3, 1fr);list-style:none;margin:1rem 0;padding:0}.AvailableTimes__serviceWindowHeading___wekj_{display:grid;gap:.5rem;grid-template-columns:repeat(3, 1fr)}.AvailableTimes__serviceWindowTab___PtuB1{text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/shared/availability/AvailableTimes.scss"],"names":[],"mappings":"AACE,sCACE,mCAAA,CACA,QAAA,CACA,eAAA,CACA,iBAAA,CACA,wBAAA,CAGF,sCACE,YAAA,CACA,SAAA,CACA,oCAAA,CACA,eAAA,CACA,aAAA,CACA,SAAA,CAIJ,8CACE,YAAA,CACA,SAAA,CACA,oCAAA,CAGF,0CACE,wBAAA","sourcesContent":[".container {\n  h2 {\n    border-bottom: 2px solid var(--gold);\n    margin: 0;\n    padding: 0.5rem 0;\n    text-align: center;\n    text-transform: uppercase;\n  }\n\n  ol {\n    display: grid;\n    gap: 0.5rem;\n    grid-template-columns: repeat(3, 1fr);\n    list-style: none;\n    margin: 1rem 0;\n    padding: 0;\n  }\n}\n\n.serviceWindowHeading {\n  display: grid;\n  gap: 0.5rem;\n  grid-template-columns: repeat(3, 1fr);\n}\n\n.serviceWindowTab {\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AvailableTimes__container___SQzHH",
	"serviceWindowHeading": "AvailableTimes__serviceWindowHeading___wekj_",
	"serviceWindowTab": "AvailableTimes__serviceWindowTab___PtuB1"
};
export default ___CSS_LOADER_EXPORT___;
