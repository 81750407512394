import {
  type ReservationGuest,
  type ReservationStatus,
  type TransactionDetails,
} from '@shared/types/reservations';
import { api } from 'restaurantAdmin/api';

export interface StrandedReservation {
  date: string;
  guest: ReservationGuest;
  guestCount: number;
  id: string;
  status: ReservationStatus;
  time: string;
  transactionDetails: TransactionDetails;
}

interface RescueArgs {
  restaurantId: string;
  strandedReservationId: string;
  time: string;
  listingId: string;
  notifyGuest: boolean;
}

export const getAllUpcomingStrandedReservations = async (
  restaurantId: string,
): Promise<StrandedReservation[]> =>
  api.get(`/restaurants/${restaurantId}/reservations/stranded`);

export const rescue = async ({
  restaurantId,
  strandedReservationId,
  time,
  listingId,
  notifyGuest,
}: RescueArgs): Promise<void> =>
  api.put(
    `/restaurants/${restaurantId}/reservations/stranded/${strandedReservationId}`,
    {
      time,
      listingId,
      notifyGuest,
    },
  );
