// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GeneralPage__page___gSsrL{display:grid;grid-template-columns:1fr 1fr;gap:1rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/settings/general/GeneralPage.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,6BAAA,CACA,QAAA","sourcesContent":[".page {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "GeneralPage__page___gSsrL"
};
export default ___CSS_LOADER_EXPORT___;
