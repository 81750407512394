import cx from 'classnames';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { type ReservationStatus } from '@shared/types/reservations';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { reactivateReservation } from 'restaurantAdmin/reservations/apiHelpers';
import typography from '~styles/typography.scss';
import { Status, StatusPill } from '../statusPill/StatusPill';
import styles from './ReservationGuestSheetStatuses.scss';

export interface ReservationGuestSheetStatusesProps {
  isHostBooked?: boolean;
  isStranded?: boolean;
  refreshOccupants?: () => void;
  reservationId: string;
  reservationStatus?: ReservationStatus;
}

export const ReservationGuestSheetStatuses = ({
  isHostBooked = false,
  isStranded = false,
  refreshOccupants,
  reservationId,
  reservationStatus,
}: ReservationGuestSheetStatusesProps) => {
  const { id: restaurantId } = useRestaurant();

  const hasStatus =
    reservationStatus === 'canceled' ||
    reservationStatus === 'noShow' ||
    isHostBooked ||
    isStranded;

  const handleUndoNoShow = async () => {
    if (reservationStatus !== 'noShow') {
      return;
    }

    try {
      await reactivateReservation({ reservationId, restaurantId });
      successToast({ message: 'Reservation has been reactivated.' });
      refreshOccupants?.();
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({
          message: `Failed to activate reservation: ${error.message}`,
        });
      } else {
        errorToast({
          message: 'Failed to activate reservation. Please try again.',
        });
      }
    }
  };

  return hasStatus ? (
    <section aria-label="status" className={styles.container}>
      {reservationStatus === 'canceled' && (
        <StatusPill status={Status.Canceled} />
      )}
      {isHostBooked && <StatusPill status={Status.HostBooked} />}
      {isStranded && <StatusPill status={Status.Stranded} />}
      {reservationStatus === 'noShow' && (
        <>
          <StatusPill status={Status.NoShow} />
          <button
            className={cx(typography.t1, styles.undoNoShow)}
            onClick={handleUndoNoShow}
          >
            Undo No Show
          </button>
        </>
      )}
    </section>
  ) : null;
};
