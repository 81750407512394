// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListingsPageHeader__tabLink___uQSo4{color:var(--white);border-radius:100px;padding:6px 22px}.ListingsPageHeader__tabLink___uQSo4.ListingsPageHeader__isActive___jcLO_{background-color:var(--primary400)}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/listingsPage/ListingsPageHeader.scss"],"names":[],"mappings":"AAAA,qCACE,kBAAA,CACA,mBAAA,CACA,gBAAA,CAEA,0EACE,kCAAA","sourcesContent":[".tabLink {\n  color: var(--white);\n  border-radius: 100px;\n  padding: 6px 22px;\n\n  &.isActive {\n    background-color: var(--primary400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabLink": "ListingsPageHeader__tabLink___uQSo4",
	"isActive": "ListingsPageHeader__isActive___jcLO_"
};
export default ___CSS_LOADER_EXPORT___;
