import { debounce } from 'lodash-es';
import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { useError } from '@shared/hooks/useError';
import { useServerSentEvents } from '@shared/hooks/useServerSentEvents';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  getHostFloorPlansForRestaurantId,
  getTableTimers,
  type HostFloorPlanData,
  type HostFloorPlanTable,
} from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  getRestaurantTags,
  type GuestTag,
} from 'restaurantAdmin/restaurants/apiHelpers';
import {
  getInfiniteOccupants,
  isReservation,
  type ServiceOccupant,
} from '../apiHelpers';
import { useFetchOccupantDetails } from '../hooks/useFetchOccupantDetails';
import { type GetPage, useLoadInfinite } from '../hooks/useLoadInfinite';
import type { FloorPlanOccupant, OccupantType } from './types';

export const enum SidePanelSheetMode {
  None = 'none',
  AddToWaitList = 'addToWaitList',
  OccupantDetails = 'occupantDetails',
  SeatWalkIn = 'seatWalkIn',
  ServerForm = 'serverForm',
  SplitMerge = 'splitMerge',
  WaitListDetails = 'waitListDetails',
}

export type SidePanelSheetState =
  | { mode: SidePanelSheetMode.None }
  | { mode: SidePanelSheetMode.AddToWaitList }
  | { mode: SidePanelSheetMode.ServerForm }
  | { mode: SidePanelSheetMode.SeatWalkIn; state: HostFloorPlanTable }
  | {
      mode: SidePanelSheetMode.OccupantDetails;
      state: FloorPlanOccupant;
    }
  | { mode: SidePanelSheetMode.SplitMerge }
  | {
      mode: SidePanelSheetMode.WaitListDetails;
    };

export type TableTimerDictionary = Record<string, string>;

export interface OccupantPositionValues {
  count: number;
  position: number;
}

const EMPTY_TABLE_TIMERS: TableTimerDictionary = {};

export interface ReservationServiceContextState {
  allFloorPlans: HostFloorPlanData[];
  timersByFloorPlanTableId: TableTimerDictionary;
  fetchSubsequentOccupants: () => void;
  selectedFloorPlan: HostFloorPlanData | undefined;
  setSelectedFloorPlanId: Dispatch<SetStateAction<string | undefined>>;
  guestTagFilters: GuestTag[];
  handleOnClickNextOccupant: () => void;
  handleOnClickPreviousOccupant: () => void;
  handleOnSelectOccupant: (occupantId: string, type: OccupantType) => void;
  handleSelectGuestTagFilters: (filters: GuestTag[]) => void;
  handleSetGuestSearchQuery: (query: string) => void;
  handleSeatedTableOnClick: (occupant: FloorPlanOccupant) => void;
  hasInitialDataLoaded: boolean;
  hasMoreOccupants: boolean;
  isLoading: boolean;
  occupantPositionValues: OccupantPositionValues;
  occupants: ServiceOccupant[];
  refreshOccupants: () => void;
  closeSidePanelSheet: () => void;
  restaurantTags: GuestTag[];
  selectedOccupant: ServiceOccupant | undefined;
  setSidePanelSheet: (state: SidePanelSheetState) => void;
  shouldShowCarousel: boolean;
  shouldShowServerBadges: boolean;
  shouldShowServiceStatuses: boolean;
  shouldShowTimers: boolean;
  /** The action sheet that is displaying on top of the default view */
  sidePanelSheet: SidePanelSheetState;
  toggleShouldShowServerBadges: () => void;
  toggleShouldShowServiceStatuses: () => void;
  toggleShouldShowTimers: () => void;
}

const ReservationServiceContext =
  createContext<ReservationServiceContextState | null>(null);

export const useReservationServiceContext =
  (): ReservationServiceContextState =>
    useDefinedContext(ReservationServiceContext);

const INITIAL_OCCUPANT_LIMIT = 50;
const SUBSEQUENT_OCCUPANT_LIMIT = 25;

export const ReservationServiceContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { id: restaurantId } = useRestaurant();

  const getServiceOccupantsPage: GetPage<ServiceOccupant> = async (
    limit,
    offset,
  ) =>
    getInfiniteOccupants(
      restaurantId,
      limit,
      offset,
      guestTagFilters,
      guestSearchQuery,
    );

  const {
    hasMore: hasMoreOccupants,
    isLoading: isOccupantsLoading,
    items: occupants,
    loadFirstPage: loadOccupantsFirstPage,
    loadNextPage: fetchSubsequentOccupants,
  } = useLoadInfinite({
    firstPageSize: INITIAL_OCCUPANT_LIMIT,
    defaultPageSize: SUBSEQUENT_OCCUPANT_LIMIT,
    getPage: getServiceOccupantsPage,
  });
  const [sidePanelSheet, setSidePanelSheet] = useState<SidePanelSheetState>({
    mode: SidePanelSheetMode.None,
  });
  const [shouldShowTimers, setShouldShowTimers] = useState(false);
  const [shouldShowServerBadges, setShouldShowServerBadges] = useState(true);
  const [shouldShowServiceStatuses, setShouldShowServiceStatuses] =
    useState(true);
  const [allFloorPlans, setAllFloorPlans] = useState<HostFloorPlanData[]>([]);
  const [selectedFloorPlanId, setSelectedFloorPlanId] = useState<string>();
  const [tableTimersByFloorPlanTableId, setTableTimersByFloorPlanTableId] =
    useState<TableTimerDictionary>(EMPTY_TABLE_TIMERS);
  const clearTableTimersByFloorPlanTableId = () =>
    setTableTimersByFloorPlanTableId(EMPTY_TABLE_TIMERS);
  const [restaurantTags, setRestaurantTags] = useState<GuestTag[]>([]);
  const [guestSearchQuery, setGuestSearchQuery] = useState<string>();
  const [guestTagFilters, setGuestTagFilters] = useState<GuestTag[]>([]);

  const [isFloorPlanLoading, setIsFloorPlanLoading] = useState(true);
  const [numberOfTimesDataLoaded, setNumberOfTimesDataLoaded] = useState(0);

  const selectedOccupantIndex =
    sidePanelSheet.mode === SidePanelSheetMode.OccupantDetails
      ? occupants.findIndex((o) => o.id === sidePanelSheet.state.id)
      : -1;
  const selectedOccupantFromOccupants =
    selectedOccupantIndex === -1 ? undefined : occupants[selectedOccupantIndex];
  const setError = useError();

  const fetchTableTimers = async () => {
    try {
      const tableTimersResponse = await getTableTimers(restaurantId);

      const result = tableTimersResponse.reduce((dictionary, time) => {
        // eslint-disable-next-line no-param-reassign
        dictionary[time.floorPlanTableId] = time.timer;
        return dictionary;
      }, {} as TableTimerDictionary);
      setTableTimersByFloorPlanTableId(result);
    } catch (error) {
      setError(error);
    }
  };

  const fetchRestaurantGuestTags = async () => {
    setRestaurantTags(await getRestaurantTags(restaurantId));
  };

  const selectedOccupantIdToFetch = useMemo(() => {
    const shouldFetch =
      sidePanelSheet.mode === SidePanelSheetMode.OccupantDetails &&
      !isOccupantsLoading &&
      !selectedOccupantFromOccupants;
    return shouldFetch
      ? { id: sidePanelSheet.state.id, type: sidePanelSheet.state.type }
      : null;
  }, [sidePanelSheet, isOccupantsLoading, selectedOccupantFromOccupants]);

  const {
    occupant: selectedOccupantFetched,
    invalidate: refreshSelectedOccupant,
  } = useFetchOccupantDetails(selectedOccupantIdToFetch);

  const selectedOccupant =
    selectedOccupantFromOccupants || selectedOccupantFetched;

  const selectedFloorPlan = allFloorPlans.find(
    (fp) => fp.id === selectedFloorPlanId,
  );

  const closeSidePanelSheet = () => {
    setSidePanelSheet({ mode: SidePanelSheetMode.None });
  };

  const handleOnClickNextOccupant = () => {
    const nextIndex = Math.min(occupants.length - 1, selectedOccupantIndex + 1);
    const nextOccupant = occupants[nextIndex];
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: { id: nextOccupant.id, type: nextOccupant.type },
    });
    if (isReservation(nextOccupant)) {
      setSelectedFloorPlanId(nextOccupant.listingFloorPlanId);
    } else {
      setSelectedFloorPlanId(nextOccupant.seatedFloorPlanId);
    }
  };

  const handleOnClickPreviousOccupant = () => {
    const previousIndex = Math.max(0, selectedOccupantIndex - 1);
    const previousOccupant = occupants[previousIndex];
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: { id: previousOccupant.id, type: previousOccupant.type },
    });
    if (isReservation(previousOccupant)) {
      setSelectedFloorPlanId(previousOccupant.listingFloorPlanId);
    } else {
      setSelectedFloorPlanId(previousOccupant.seatedFloorPlanId);
    }
  };

  const handleOnSelectOccupant = (occupantId: string, type: OccupantType) => {
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: { id: occupantId, type },
    });
  };

  const handleSeatedTableOnClick = (occupant: FloorPlanOccupant) => {
    setSidePanelSheet({
      mode: SidePanelSheetMode.OccupantDetails,
      state: occupant,
    });
  };

  const handleSelectGuestTagFilters = (filters: GuestTag[]) => {
    setGuestTagFilters(filters);
    refreshOccupants();
  };

  const MINIMUM_SEARCH_CHARS = 3;
  const DEBOUNCE_SEARCH_MS = 1000;

  const debouncedRefreshOccupants = useMemo(
    () =>
      debounce(() => {
        refreshOccupants();
      }, DEBOUNCE_SEARCH_MS),
    [],
  );

  const handleSetGuestSearchQuery = (query: string) => {
    if (query.length < MINIMUM_SEARCH_CHARS) {
      setGuestSearchQuery(undefined);
    } else {
      setGuestSearchQuery(query);
    }

    void debouncedRefreshOccupants();
  };

  const toggleShouldShowTimers = () => {
    if (!shouldShowTimers) {
      setShouldShowTimers(true);
      void fetchTableTimers();
    } else {
      setShouldShowTimers(false);
      clearTableTimersByFloorPlanTableId();
    }
  };

  const toggleShouldShowServerBadges = () => {
    setShouldShowServerBadges(!shouldShowServerBadges);
  };

  const toggleShouldShowServiceStatuses = () => {
    setShouldShowServiceStatuses(!shouldShowServiceStatuses);
  };

  const refreshOccupants = () => {
    loadOccupantsFirstPage();
    refreshSelectedOccupant();
  };

  const fetchHostFloorPlans = () => {
    void (async () => {
      setIsFloorPlanLoading(true);
      try {
        const floorPlanResponse =
          await getHostFloorPlansForRestaurantId(restaurantId);
        setAllFloorPlans(floorPlanResponse);
        setSelectedFloorPlanId(floorPlanResponse[0].id);
        if (shouldShowTimers) await fetchTableTimers();
      } catch (error) {
        setError(error);
      } finally {
        setIsFloorPlanLoading(false);
      }
    })();
  };

  useEffect(() => {
    if (
      !isOccupantsLoading &&
      !isFloorPlanLoading &&
      numberOfTimesDataLoaded === 0
    ) {
      setNumberOfTimesDataLoaded(1);
    }
  }, [isOccupantsLoading, isFloorPlanLoading]);

  useEffect(() => {
    loadOccupantsFirstPage();
    fetchHostFloorPlans();
    void fetchRestaurantGuestTags();
  }, [restaurantId]);

  useServerSentEvents(restaurantId, () => {
    loadOccupantsFirstPage();
    fetchHostFloorPlans();
  });

  const value = useMemo<ReservationServiceContextState>(
    () => ({
      timersByFloorPlanTableId: tableTimersByFloorPlanTableId,
      allFloorPlans,
      closeSidePanelSheet,
      fetchSubsequentOccupants,
      restaurantTags,
      guestTagFilters,
      handleOnClickNextOccupant,
      handleOnClickPreviousOccupant,
      handleOnSelectOccupant,
      handleSelectGuestTagFilters,
      handleSetGuestSearchQuery,
      handleSeatedTableOnClick,
      hasMoreOccupants,
      hasInitialDataLoaded: numberOfTimesDataLoaded === 1,
      isLoading: isOccupantsLoading || isFloorPlanLoading,
      occupantPosition: `Reservation ${selectedOccupantIndex + 1} of ${occupants.length}`,
      occupants,
      occupantPositionValues: {
        count: occupants.length,
        position: selectedOccupantIndex + 1,
      },
      refreshOccupants,
      refreshSelectedOccupant,
      selectedOccupant,
      selectedFloorPlan,
      setSelectedFloorPlanId,
      setSidePanelSheet,
      shouldShowCarousel: !!selectedOccupantFromOccupants,
      shouldShowServerBadges,
      shouldShowServiceStatuses,
      shouldShowTimers,
      sidePanelSheet,
      toggleShouldShowServerBadges,
      toggleShouldShowServiceStatuses,
      toggleShouldShowTimers,
    }),
    [
      tableTimersByFloorPlanTableId,
      selectedFloorPlan,
      guestTagFilters,
      guestSearchQuery,
      hasMoreOccupants,
      numberOfTimesDataLoaded,
      occupants,
      selectedOccupant,
      shouldShowServerBadges,
      shouldShowServiceStatuses,
      shouldShowTimers,
      sidePanelSheet,
    ],
  );

  return (
    <ReservationServiceContext.Provider value={value}>
      {children}
    </ReservationServiceContext.Provider>
  );
};
