import { type CalendarApi, type EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import './FullCalendar.overrides.css';
// eslint-disable-next-line import/extensions
import { type ResourceInput } from '@fullcalendar/resource/index.js';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import cx from 'classnames';
import { min } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAdminFloorPlans } from 'restaurantAdmin/hooks/useAdminFloorPlans';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import { PageHeader } from 'restaurantAdmin/layout/PageHeader';
import typography from '~styles/typography.scss';
import { getShifts, type Shift } from './apiHelpers';
import { CreateShiftModal } from './CreateShiftModal';
import { ShiftPanel } from './ShiftPanel';
import styles from './ShiftsPage.scss';
import { TimelineToolbar } from './TimelineToolbar';

export const ShiftsPage = () => {
  const { id: restaurantId } = useRestaurant();
  const { floorPlans, isLoading: isFloorPlanFetchPending } =
    useAdminFloorPlans(false);
  const [calendarApi, setCalendarApi] = useState<CalendarApi | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedShiftId, setSelectedShiftId] = useState<Shift['id'] | null>(
    null,
  );

  const { isOpen, open, close } = useIsOpen();

  const calendarRefCallback = (calendarRef: FullCalendar | null) => {
    if (calendarRef) {
      setCalendarApi(calendarRef.getApi());
    }
  };

  const {
    data: shifts = [],
    isPending: isShiftFetchPending,
    rerun: refetchShifts,
  } = useAbortEffect(
    (signal) => getShifts(restaurantId, signal),
    [restaurantId],
  );

  const selectedShift = useMemo(
    () => shifts.find((shift) => shift.id === selectedShiftId),
    [selectedShiftId, shifts],
  );

  if (isShiftFetchPending || isFloorPlanFetchPending) return null;

  const topOfHour = (time: string) => `${time.slice(0, 2)}:00`;

  const earliestStartTime = min(shifts.map((sw) => sw.startTime))!;

  const renderResourceLabel = ({ resource }: { resource: ResourceInput }) => {
    const shift = shifts.find((sw) => sw.id === resource.id)!;
    const { name } = shift;

    return (
      <button
        className={styles.rowLabel}
        onClick={() => setSelectedShiftId(resource.id!)}
      >
        <div className={cx(typography.h7, styles.rowName)}>{name}</div>
      </button>
    );
  };

  const resources: ResourceInput[] = shifts.map((sw) => ({
    id: sw.id,
    title: sw.name,
  }));

  const events: EventInput[] = shifts.map((sw) => ({
    id: sw.id,
    resourceId: sw.id,
    daysOfWeek: sw.repeat,
    start: sw.startDate,
    startRecur: sw.startDate,
    startTime: sw.startTime,
    end: sw.endDate || undefined,
    endRecur: sw.endDate || undefined,
    endTime: sw.endTime,
    color: 'var(--hairline)',
  }));

  const closeDrawer = () => {
    setSelectedShiftId(null);
  };

  return (
    <>
      <PageHeader category="Operations" title="Shifts">
        <Button
          label="Create Shift"
          onClick={open}
          variant={ButtonVariants.Secondary}
        />
        <CreateShiftModal
          isOpen={isOpen}
          closeModal={close}
          handleOnCreateShift={refetchShifts}
        />
      </PageHeader>
      <PageContent className={styles.pageContent}>
        {selectedShift && (
          <ShiftPanel
            shift={selectedShift}
            closeDrawer={closeDrawer}
            restaurantId={restaurantId}
            floorPlans={floorPlans}
            refetchShifts={refetchShifts}
          />
        )}
        {shifts.length > 0 ? (
          <div className={styles.timeline}>
            <TimelineToolbar
              calendarApi={calendarApi}
              selectedDate={selectedDate}
            />
            <FullCalendar
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              headerToolbar={false}
              contentHeight="auto"
              initialView="resourceTimeline"
              resources={resources}
              plugins={[resourceTimelinePlugin]}
              ref={calendarRefCallback}
              resourceAreaWidth="240px"
              resourceAreaHeaderContent={
                <div
                  className={cx(
                    typography.t1,
                    styles.resourceAreaHeaderContent,
                  )}
                >
                  Shifts
                </div>
              }
              resourceLabelContent={renderResourceLabel}
              resourceOrder="title"
              slotMinTime="00:00"
              slotMaxTime="23:59"
              scrollTime={topOfHour(earliestStartTime)}
              scrollTimeReset={false}
              slotDuration="00:15"
              slotLabelClassNames={cx(typography.h7m, styles.timeLabel)}
              slotLabelInterval="01:00"
              slotLabelFormat={[
                {
                  weekday: 'short',
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                },
                {
                  hour: 'numeric',
                  minute: '2-digit',
                  omitZeroMinute: false,
                  meridiem: true,
                },
              ]}
              eventClassNames={styles.event}
              events={events}
              nowIndicator
              datesSet={(info) => {
                setSelectedDate(info.start);
              }}
            />
          </div>
        ) : (
          <p className={typography.c2}>No shifts found.</p>
        )}
      </PageContent>
    </>
  );
};
