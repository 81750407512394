// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RemediationForm__form___Pq1D6{display:flex;flex-direction:column}.RemediationForm__form___Pq1D6 button{margin-bottom:.5rem}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/remediationForm/RemediationForm.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,qBAAA,CAEA,sCACE,mBAAA","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n\n  button {\n    margin-bottom: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "RemediationForm__form___Pq1D6"
};
export default ___CSS_LOADER_EXPORT___;
