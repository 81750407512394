import cx from 'classnames';
import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import { getFullName } from '@shared/utils/formatName';
import { appendGuestCountLabel } from '@shared/utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import { AssignedServer } from 'restaurantAdmin/components/server/AssignedServer';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import { discardWalkIn, finishWalkIn } from '../../apiHelpers';
import { OccupantType, type ServiceWalkIn } from '../apiHelpers';
import { ServiceStatusSelect } from '../serviceStatus/ServiceStatusSelect';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import { WalkInSheetWarning } from '../walkIns/WalkInSheetWarning';
import { DiscardWalkInConfirmationModal } from './DiscardWalkInConfirmationModal';
import { FinishWalkInConfirmationModal } from './FinishWalkInConfirmationModal';
import styles from './SeatedWalkInSheet.scss';
import { useServersContext } from './servers/ServersContext';

export interface WalkInSheetProps {
  walkIn: ServiceWalkIn;
  onEnableSeatMode: () => void;
}

export const SeatedWalkInSheet = ({
  walkIn,
  onEnableSeatMode,
}: WalkInSheetProps) => {
  const restaurant = useRestaurant();
  const { closeSidePanelSheet } = useReservationServiceContext();

  const { seatedTableName } = walkIn;
  const walkInName =
    getFullName(walkIn.firstName, walkIn.lastName) || 'Walk-In';
  const {
    isOpen: isFinishWalkInModalOpen,
    open: openFinishWalkInModal,
    close: closeFinishWalkInModal,
  } = useIsOpen();
  const {
    isOpen: isDiscardWalkInModalOpen,
    open: openDiscardWalkInModal,
    close: closeDiscardWalkInModal,
  } = useIsOpen();
  const [isDiscardWalkInLoading, setIsDiscardWalkInLoading] = useState(false);
  const { hasServers } = useServersContext();

  const handleDiscardWalkIn = () => {
    setIsDiscardWalkInLoading(true);
    void (async () => {
      try {
        await discardWalkIn({
          restaurantId: restaurant.id,
          walkInId: walkIn.id,
        });
        closeDiscardWalkInModal();
        setIsDiscardWalkInLoading(false);
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({ message: error.message });
        } else {
          errorToast({
            message: 'Failed to discard walk-in. Please try again.',
          });
        }
        setIsDiscardWalkInLoading(false);
      }
    })();
    closeSidePanelSheet();
  };

  const handleFinishWalkIn = async () => {
    try {
      await finishWalkIn({
        restaurantId: restaurant.id,
        walkInId: walkIn.id,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({ message: 'Failed to finish walk-in. Please try again.' });
      }
      reportAppError(error);
    }
    closeSidePanelSheet();
  };

  return (
    <div className={styles.container}>
      <WalkInSheetWarning floorPlanTableId={walkIn.seatedTableId} />
      <header className={styles.header}>
        <ServiceStatusSelect
          occupantType="walkIn"
          occupantId={walkIn.id}
          showLabel
          value={walkIn.serviceStatus}
        />
        <div className={styles.guestInfo}>
          <div className={styles.infoRow}>
            <h2 className={cx(typography.h6, styles.guestName)}>
              {walkInName}
            </h2>
            <span className={cx(typography.h7_20, styles.time)}>
              {ISOTimeTo12HourTime(walkIn.time)}
            </span>
          </div>
          <div className={styles.infoRow}>
            <span className={cx(typography.t1, styles.guestCount)}>
              {appendGuestCountLabel(walkIn.guestCount)}
            </span>
            <div className={typography.h7}>table {seatedTableName}</div>
          </div>
        </div>
      </header>
      <Button
        label="Finish Walk-in"
        onClick={openFinishWalkInModal}
        variant={ButtonVariants.Primary}
      />
      <FinishWalkInConfirmationModal
        isOpen={isFinishWalkInModalOpen}
        handleFinishWalkIn={handleFinishWalkIn}
        closeModal={closeFinishWalkInModal}
      />
      <Button
        label="Change Seat"
        onClick={onEnableSeatMode}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Discard Walk-in"
        onClick={openDiscardWalkInModal}
        variant={ButtonVariants.Tertiary}
      />
      {hasServers && (
        <AssignedServer
          occupant={{
            occupantId: walkIn.id,
            occupantType: OccupantType.WalkIn,
          }}
          server={walkIn.server}
        />
      )}
      <DiscardWalkInConfirmationModal
        handleDiscardWalkIn={handleDiscardWalkIn}
        isLoading={isDiscardWalkInLoading}
        isOpen={isDiscardWalkInModalOpen}
        closeModal={closeDiscardWalkInModal}
      />
    </div>
  );
};
