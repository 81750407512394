import { api } from '../../../../api';

export interface CreateWaitListEntryParams {
  estimatedWait: number;
  firstName: string | null;
  guestCount: number;
  lastName: string | null;
  phone: string;
  restaurantId: string;
}

interface WaitListEntryEvent {
  type: string;
}

export interface WaitListEntry {
  estimatedWait: number;
  firstName: string;
  guestCount: number;
  id: string;
  lastName: string;
  phone: string;
  restaurantId: string;
  createdAt: string;
  events: WaitListEntryEvent[];
}

export const createWaitListEntry = ({
  estimatedWait,
  firstName,
  guestCount,
  lastName,
  phone,
  restaurantId,
}: CreateWaitListEntryParams): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/wait-list-entries`, {
    estimatedWait,
    firstName,
    guestCount,
    lastName,
    phone,
  });

export const getWaitList = ({
  restaurantId,
}: {
  restaurantId: string;
}): Promise<WaitListEntry[]> =>
  api.get(`/restaurants/${restaurantId}/wait-list-entries`);

export const sendTableReadyMessage = (
  restaurantId: string,
  waitListEntryId: string,
): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/wait-list-entries/${waitListEntryId}/notify`,
  );

export const cancelWaitListEntry = ({
  restaurantId,
  waitListEntryId,
}: {
  restaurantId: string;
  waitListEntryId: string;
}): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/wait-list-entries/${waitListEntryId}/cancel`,
  );
