import { Button, ButtonVariants } from '@components/button/Button';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';

interface UpdateShiftsWarningsModalProps {
  closeModal: () => void;
  handleOnConfirm: () => void;
  isOpen: boolean;
  warnings: string[];
}

export const UpdateShiftsWarningsModal = ({
  closeModal,
  handleOnConfirm,
  isOpen,
  warnings,
}: UpdateShiftsWarningsModalProps) => (
  <Modal isOpen={isOpen} onClose={closeModal} title="Update Shift">
    <p className={typography.c2}>{`Warning: ${warnings.join(', ')}.`}</p>
    <ModalActions>
      <Button
        label="Cancel"
        onClick={closeModal}
        variant={ButtonVariants.Tertiary}
      />
      <Button
        label="Confirm"
        onClick={handleOnConfirm}
        variant={ButtonVariants.Error}
      />
    </ModalActions>
  </Modal>
);
