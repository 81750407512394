import cx from 'classnames';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { ControlledFormTextareaAutosize } from '@components/formInputs/ControlledFormTextareaAutosize';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { isValidEmail } from '@shared/utils/emailValidator';
import { type AdminRole } from 'restaurantAdmin/auth/apiHelpers';
import { PREVIOUS_PATH, SETTINGS_TEAM_PATH } from 'restaurantAdmin/paths';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../context/useRestaurant';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { inviteTeamMember } from './apiHelpers';
import styles from './InviteTeamMemberPage.scss';

interface InviteTeamMemberFormData {
  emails: string;
  role: AdminRole;
}

export const InviteTeamMemberPage = () => {
  const { id: restaurantId } = useRestaurant();
  const {
    control,
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = useForm<InviteTeamMemberFormData>({});

  const navigate = useNavigate();

  const hasEmailError = !!errors?.emails;

  const onValidSubmit: SubmitHandler<InviteTeamMemberFormData> = async (
    data,
  ) => {
    const { emails, role } = data;

    const emailList = emails.split(',').map((email) => email.trim());

    try {
      await inviteTeamMember(restaurantId, emailList, role);
      navigate(SETTINGS_TEAM_PATH);
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({ message: 'An error occurred while inviting team member' });
      }
      reportAppError(error);
    }
  };

  const ROLE_OPTIONS = [
    {
      label: 'Host',
      value: 'host',
    },
    {
      label: 'Manager',
      value: 'manager',
    },
  ];

  return (
    <>
      <PageHeader title="Add Team Member" />
      <PageContent>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form className={styles.form} onSubmit={handleSubmit(onValidSubmit)}>
          <fieldset>
            <section>
              <div className={styles.labelContainer}>
                <h2
                  className={cx(typography.h5, {
                    [styles.error]: hasEmailError,
                  })}
                >
                  Member Info
                </h2>
              </div>
              <div
                className={cx(styles.fieldsContainer, {
                  [styles.errorBorder]: hasEmailError,
                })}
              >
                <ControlledFormTextareaAutosize
                  control={control}
                  disabled={false}
                  label="Email"
                  name="emails"
                  placeholder="email, comma separated"
                  rules={{
                    required: true,
                    validate: {
                      isValidEmail: (emails: string) => {
                        const isValid = emails
                          .split(',')
                          .every((email) => isValidEmail(email.trim()));
                        if (isValid) return true;
                        return 'Please enter valid email addresses';
                      },
                    },
                  }}
                />
              </div>
            </section>
            <section>
              <div className={styles.labelContainer}>
                <h2 className={typography.h5}>Role</h2>
                <p className={typography.t2}>
                  Hosts can only access the pages under Reservations and Guest
                  Book.
                </p>
                <p className={typography.t2}>
                  Managers can access all pages in Peak.
                </p>
              </div>
              <div className={styles.fieldsContainer}>
                <ControlledFormSelect
                  label="Role"
                  name="role"
                  options={ROLE_OPTIONS}
                  defaultValue="host"
                  control={control}
                />
              </div>
            </section>
            <section>
              <div className={styles.formActions}>
                <Button
                  label="Discard Team Members"
                  onClick={() => navigate(PREVIOUS_PATH)}
                  variant={ButtonVariants.Tertiary}
                />
                <Button
                  isDisabled={!isDirty || isSubmitting}
                  label="Invite Team Members"
                  type="submit"
                  variant={ButtonVariants.Primary}
                />
              </div>
            </section>
          </fieldset>
        </form>
      </PageContent>
    </>
  );
};
