import { api } from 'restaurantAdmin/api';

interface FloorPlanForAdminUpdate {
  id: string;
  name: string;
}

export const updateFloorPlans = (
  restaurantId: string,
  floorPlans: FloorPlanForAdminUpdate[],
) => api.put(`/restaurants/${restaurantId}/admin-floor-plans`, { floorPlans });
