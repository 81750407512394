import { createContext, type ReactNode, useEffect, useMemo } from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from '../state/ReservationServiceContext';

export interface WalkInFormContextState {
  form: Pick<
    UseFormReturn<WalkInFormData>,
    | 'control'
    | 'getValues'
    | 'setValue'
    | 'handleSubmit'
    | 'formState'
    | 'reset'
  >;
}

const DEFAULT_GUEST_COUNT = 2;

export interface WalkInFormData {
  guestCount: number;
  firstName: string;
  lastName: string;
  saveAsGuest: boolean;
  guestId?: string;
  serverId?: string;
}

const WalkInFormContext = createContext<WalkInFormContextState | null>(null);

export const useWalkInFormContext = () => useDefinedContext(WalkInFormContext);

export const WalkInFormContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { sidePanelSheet } = useReservationServiceContext();

  const { control, getValues, setValue, handleSubmit, formState, reset } =
    useForm<WalkInFormData>({
      defaultValues: {
        guestCount: DEFAULT_GUEST_COUNT,
        saveAsGuest: false,
      },
    });

  useEffect(() => {
    if (sidePanelSheet.mode === SidePanelSheetMode.SeatWalkIn) {
      setValue('serverId', sidePanelSheet.state.assignedServer?.id);
    }
  }, [sidePanelSheet]);

  const value = useMemo(
    () => ({
      form: {
        control,
        getValues,
        setValue,
        handleSubmit,
        formState,
        reset,
      },
    }),
    [sidePanelSheet, control],
  );

  return (
    <WalkInFormContext.Provider value={value}>
      {children}
    </WalkInFormContext.Provider>
  );
};
