import cx from 'classnames';
import { Fragment, type ReactElement } from 'react';
import { groupTimesByServiceWindow } from '@shared/availability/availabilityUtil';
import { ISOTimeTo12HourTime } from '@utils/time';
import styles from './TimeFilterPopover.scss';

interface TimeFilterPopoverProps {
  availableTimes: string[];
  className?: string;
  noTimes?: ReactElement;
  selectedTime?: string;
  setSelectedTime: (time: string) => void;
}

export const TimeFilterPopover = ({
  availableTimes,
  className,
  noTimes,
  selectedTime,
  setSelectedTime,
}: TimeFilterPopoverProps) => {
  if (!availableTimes.length) {
    return (
      <div className={styles.noTimesPopover}>
        {noTimes || 'No times are available.'}
      </div>
    );
  }

  const availableTimesByServiceWindow =
    groupTimesByServiceWindow(availableTimes);

  const renderAvailabilities = (availabilities: string[]) =>
    availabilities.map((time) => {
      const timeButtonClassNames = cx({
        [styles.isSelected]: time === selectedTime,
        [styles.timeButton]: true,
      });

      return (
        <li key={time}>
          <button
            className={timeButtonClassNames}
            onClick={() => setSelectedTime(time)}
          >
            {ISOTimeTo12HourTime(time)}
          </button>
        </li>
      );
    });

  return (
    <div
      data-testid="available-times"
      className={cx(styles.container, className)}
    >
      <div className={styles.serviceWindows}>
        {availableTimesByServiceWindow.map(
          ([serviceWindow, availabilities]) => {
            const serviceWindowSansSpace = serviceWindow.replace(' ', '');
            return (
              <Fragment key={serviceWindow}>
                <h2 id={serviceWindowSansSpace}>{serviceWindow}</h2>
                <ol
                  aria-labelledby={serviceWindowSansSpace}
                  className={styles.availabilities}
                >
                  {renderAvailabilities(availabilities)}
                </ol>
              </Fragment>
            );
          },
        )}
      </div>
    </div>
  );
};
