// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PricePointModal__form___bmfiR{display:flex;flex-direction:column;row-gap:16px;border:1px solid var(--white70);padding:16px;border-radius:8px}.PricePointModal__form___bmfiR .PricePointModal__iconButton___s7gPZ{background-color:unset;padding:0;align-self:flex-end}.PricePointModal__form___bmfiR .PricePointModal__iconButton___s7gPZ>svg{width:1rem;stroke:var(--white70)}.PricePointModal__form___bmfiR .PricePointModal__overlapMessage___spaqw>svg{width:1rem;stroke:var(--pink);margin-right:4px}.PricePointModal__form___bmfiR .PricePointModal__overlapMessage___spaqw>span{color:var(--pink)}.PricePointModal__form___bmfiR .PricePointModal__overlapMessage___spaqw>p{margin:4px 0 0}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/operations/listings/form/PricePointModal.scss"],"names":[],"mappings":"AAAA,+BACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,+BAAA,CACA,YAAA,CACA,iBAAA,CAEA,oEACE,sBAAA,CACA,SAAA,CACA,mBAAA,CAEA,wEACE,UAAA,CACA,qBAAA,CAKF,4EACE,UAAA,CACA,kBAAA,CACA,gBAAA,CAGF,6EACE,iBAAA,CAGF,0EACE,cAAA","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n  border: 1px solid var(--white70);\n  padding: 16px;\n  border-radius: 8px;\n\n  .iconButton {\n    background-color: unset;\n    padding: 0;\n    align-self: flex-end;\n\n    > svg {\n      width: 1rem;\n      stroke: var(--white70);\n    }\n  }\n\n  .overlapMessage {\n    > svg {\n      width: 1rem;\n      stroke: var(--pink);\n      margin-right: 4px;\n    }\n\n    > span {\n      color: var(--pink);\n    }\n\n    > p {\n      margin: 4px 0 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "PricePointModal__form___bmfiR",
	"iconButton": "PricePointModal__iconButton___s7gPZ",
	"overlapMessage": "PricePointModal__overlapMessage___spaqw"
};
export default ___CSS_LOADER_EXPORT___;
