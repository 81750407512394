import { addDays } from 'date-fns';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import type { ListingWithShifts } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import { listingTimeRangesOnDate } from '../utils/listingUtils';
import { mergeOverlappingTimeRanges } from '../utils/timeRange';
import { CalendarBlock } from './CalendarBlock';
import { SelectTimeButtons } from './SelectTimeButton';

interface CalendarColProps {
  highlightedListing?: ListingWithShifts;
  isInteractive: boolean;
  weekStart: Date;
  weekDay: number;
}

export const CalendarCol = ({
  highlightedListing,
  isInteractive,
  weekStart,
  weekDay,
}: CalendarColProps) => {
  const { listings } = useListingsContext();
  const date = toISODateFormat(addDays(weekStart, weekDay));
  const allTimeRanges = listings.flatMap((listing) =>
    listingTimeRangesOnDate(listing, date),
  );
  const timeRanges = mergeOverlappingTimeRanges(allTimeRanges);

  return !timeRanges.length && !highlightedListing && !isInteractive ? null : (
    <>
      {timeRanges.map(({ startTime, endTime }) => (
        <CalendarBlock
          key={startTime}
          date={date}
          startTime={startTime}
          endTime={endTime}
          isDimmed={Boolean(highlightedListing)}
          weekDay={weekDay}
        />
      ))}
      {highlightedListing &&
        listingTimeRangesOnDate(highlightedListing, date).map(
          ({ startTime, endTime }) => (
            <CalendarBlock
              key={startTime}
              isHighlighted
              date={date}
              startTime={startTime}
              endTime={endTime}
              weekDay={weekDay}
            />
          ),
        )}
      {isInteractive &&
        timeRanges.map(({ startTime, endTime }) => (
          <SelectTimeButtons
            key={startTime}
            date={date}
            startTime={startTime}
            endTime={endTime}
            weekDay={weekDay}
          />
        ))}
    </>
  );
};
