import { useState } from 'react';
import { Button, ButtonVariants } from '@components/button/Button';
import { Icon } from '@components/icon/Icon';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { successToast } from '@components/toasts/Toasts';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { reportAppError } from '@shared/reportAppError';
import type { CancellationPolicy } from '@shared/types/reservations';
import { deleteCancellationPolicy } from '../apiHelpers';
import styles from './CancellationPolicyFormActions.scss';

interface CancellationPolicyControlButtonsProps {
  cancellationPolicy: CancellationPolicy | null;
  refreshRestaurant: () => Promise<void>;
  exitEditMode: () => void;
  restaurantId: string;
  disabled: boolean;
}

export const CancellationPolicyFormActions = ({
  cancellationPolicy,
  refreshRestaurant,
  exitEditMode,
  restaurantId,
  disabled,
}: CancellationPolicyControlButtonsProps) => {
  const {
    isOpen: isModalOpen,
    open: openModal,
    close: closeModal,
  } = useIsOpen();
  const [hasDeletionError, setHasDeletionError] = useState<boolean>(false);
  const handleConfirmDelete = (): void => {
    void (async () => {
      closeModal();
      if (!cancellationPolicy || !cancellationPolicy.id) return;

      try {
        await deleteCancellationPolicy(restaurantId, cancellationPolicy.id);
        await refreshRestaurant();
        successToast({ message: 'Cancellation Policy successfully deleted' });
        exitEditMode();
      } catch (error) {
        setHasDeletionError(true);
        reportAppError(error);
      }
    })();
  };
  return (
    <div className={styles.container}>
      <Button
        label="Save Changes"
        type="submit"
        isDisabled={disabled}
        variant={ButtonVariants.Primary}
      />
      <Button
        label="Discard Edits"
        onClick={exitEditMode}
        variant={ButtonVariants.Tertiary}
      />
      {!!cancellationPolicy && (
        <>
          <button
            aria-label="Delete Cancellation Policy"
            className={styles.deleteButton}
            onClick={openModal}
            type="button"
          >
            <Icon ariaLabel="Delete Cancellation Policy" name="trash" />
            <span>Remove Policy</span>
          </button>
          <Modal
            isOpen={isModalOpen}
            onClose={closeModal}
            subtitle="Existing reservations with a cancellation policy are not
                  affected."
            title="Delete Cancellation Policy"
          >
            <ModalActions>
              <Button
                className={styles.button}
                label="Keep Policy"
                onClick={closeModal}
                variant={ButtonVariants.Tertiary}
              />
              <Button
                className={styles.button}
                label="Delete Policy"
                onClick={handleConfirmDelete}
                variant={ButtonVariants.Primary}
              />
            </ModalActions>
          </Modal>
          <Modal
            isOpen={hasDeletionError}
            onClose={() => {
              setHasDeletionError(false);
            }}
            subtitle="There was an error deleting the Cancellation Policy"
            title="Delete Cancellation Policy Error"
          >
            <ModalActions>
              <Button
                className={styles.button}
                label="Understood"
                onClick={() => setHasDeletionError(false)}
                variant={ButtonVariants.Tertiary}
              />
            </ModalActions>
          </Modal>
        </>
      )}
    </div>
  );
};
