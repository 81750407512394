// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestNotesShow__showForm___Rfbbu{background-color:var(--panelBlue);border-radius:8px;padding:1rem}.GuestNotesShow__showForm___Rfbbu header{display:flex;justify-content:space-between}.GuestNotesShow__showForm___Rfbbu button{align-items:center;background:none;border:none;color:var(--white);cursor:pointer}.GuestNotesShow__showForm___Rfbbu button svg{height:16px;margin-right:4px;stroke:var(--white);width:16px}.GuestNotesShow__showForm___Rfbbu p{color:var(--white70);white-space:pre-line}.GuestNotesShow__showForm___Rfbbu .GuestNotesShow__updatedBy___hjpg9{color:var(--white70);font-style:italic}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/components/guestNotes/GuestNotesShow/GuestNotesShow.scss"],"names":[],"mappings":"AAAA,kCACE,iCAAA,CACA,iBAAA,CACA,YAAA,CAEA,yCACE,YAAA,CACA,6BAAA,CAGF,yCACE,kBAAA,CACA,eAAA,CACA,WAAA,CACA,kBAAA,CACA,cAAA,CAEA,6CACE,WAAA,CACA,gBAAA,CACA,mBAAA,CACA,UAAA,CAIJ,oCACE,oBAAA,CACA,oBAAA,CAGF,qEACE,oBAAA,CACA,iBAAA","sourcesContent":[".showForm {\n  background-color: var(--panelBlue);\n  border-radius: 8px;\n  padding: 1rem;\n\n  header {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  button {\n    align-items: center;\n    background: none;\n    border: none;\n    color: var(--white);\n    cursor: pointer;\n\n    svg {\n      height: 16px;\n      margin-right: 4px;\n      stroke: var(--white);\n      width: 16px;\n    }\n  }\n\n  p {\n    color: var(--white70);\n    white-space: pre-line;\n  }\n\n  .updatedBy {\n    color: var(--white70);\n    font-style: italic;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showForm": "GuestNotesShow__showForm___Rfbbu",
	"updatedBy": "GuestNotesShow__updatedBy___hjpg9"
};
export default ___CSS_LOADER_EXPORT___;
