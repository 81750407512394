// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormListbox__listbox___qrFOa{background-color:var(--darkGrey100);border-radius:5px;color:var(--panelBlue);font-size:.875rem;max-height:20rem;overflow:auto;padding:.75rem 0}.FormListbox__listbox___qrFOa>li{cursor:pointer;font-size:inherit;padding:.75rem 1rem}.FormListbox__listbox___qrFOa>li:hover,.FormListbox__listbox___qrFOa>li.Mui-focused{background-color:var(--darkGrey200);color:var(--white)}.FormListbox__listbox___qrFOa>li[aria-selected=true]{background-color:var(--darkGrey300);color:var(--darkGrey100)}", "",{"version":3,"sources":["webpack://./src/shared/components/formListbox/FormListbox.scss"],"names":[],"mappings":"AAAA,8BACE,mCAAA,CACA,iBAAA,CACA,sBAAA,CACA,iBAAA,CACA,gBAAA,CACA,aAAA,CACA,gBAAA,CAEA,iCACE,cAAA,CACA,iBAAA,CACA,mBAAA,CAEA,oFAEE,mCAAA,CACA,kBAAA,CAGF,qDACE,mCAAA,CACA,wBAAA","sourcesContent":[".listbox {\n  background-color: var(--darkGrey100);\n  border-radius: 5px;\n  color: var(--panelBlue);\n  font-size: 0.875rem;\n  max-height: 20rem;\n  overflow: auto;\n  padding: 0.75rem 0;\n\n  > li {\n    cursor: pointer;\n    font-size: inherit;\n    padding: 0.75rem 1rem;\n\n    &:hover,\n    &:global(.Mui-focused) {\n      background-color: var(--darkGrey200);\n      color: var(--white);\n    }\n\n    &[aria-selected='true'] {\n      background-color: var(--darkGrey300);\n      color: var(--darkGrey100);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listbox": "FormListbox__listbox___qrFOa"
};
export default ___CSS_LOADER_EXPORT___;
